export const studienOrdnungBLAW = {
    creditsDe: null,
    creditsEn: null,
    creditsFulfilledDe: null,
    creditsFulfilledEn: null,
    isProcessing: false,
    "items": [
        {
            "items": [
                {
                    "items": [
                        {
                            "items": [
                                {
                                    "achievementLanguage": "DE",
                                    "gradeText": "",
                                    "hierarchy": "00100200300_0001",
                                    "hierarchyParent": "00100200300",
                                    "hierarchyLevel": 3,
                                    "isDetail": true,
                                    "isTitle": false,
                                    "mark": null,
                                    "maxCredits": "0.00",
                                    "minCredits": "0.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 322,
                                    "semester": null,
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Methods and Working Technics",
                                    "id": 4425,
                                    "shortName": "Methods and Working Technics"
                                },
                                {
                                    "achievementLanguage": "DE",
                                    "gradeText": "",
                                    "hierarchy": "00100200301_0001",
                                    "hierarchyParent": "00100200301",
                                    "hierarchyLevel": 3,
                                    "isDetail": true,
                                    "isTitle": false,
                                    "mark": null,
                                    "maxCredits": "0.00",
                                    "minCredits": "0.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 322,
                                    "semester": null,
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Civil Code",
                                    "id": 4399,
                                    "shortName": "Civil Code"
                                },
                                {
                                    "achievementLanguage": "DE",
                                    "gradeText": "",
                                    "hierarchy": "00100200302_0001",
                                    "hierarchyParent": "00100200302",
                                    "hierarchyLevel": 3,
                                    "isDetail": true,
                                    "isTitle": false,
                                    "mark": null,
                                    "maxCredits": "0.00",
                                    "minCredits": "0.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 322,
                                    "semester": null,
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Swiss Code of Obligations: GP and SP",
                                    "id": 4398,
                                    "shortName": "Swiss Code of Obligations: GP and SP"
                                },
                                {
                                    "achievementLanguage": "",
                                    "gradeText": "",
                                    "hierarchy": "00100200303",
                                    "hierarchyParent": "00100200",
                                    "hierarchyLevel": 3,
                                    "isDetail": true,
                                    "isTitle": false,
                                    "mark": null,
                                    "maxCredits": "8.00",
                                    "minCredits": "8.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 258,
                                    "semester": "",
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Administrative Law: Basic Principles & Public Business Law",
                                    "id": 4401,
                                    "shortName": "Administrative Law: Basic Principles & Public Business Law"
                                },
                                {
                                    "achievementLanguage": "",
                                    "gradeText": "",
                                    "hierarchy": "00100200304",
                                    "hierarchyParent": "00100200",
                                    "hierarchyLevel": 3,
                                    "isDetail": true,
                                    "isTitle": false,
                                    "mark": null,
                                    "maxCredits": "4.00",
                                    "minCredits": "4.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 258,
                                    "semester": "",
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Fiscal Law",
                                    "id": 4408,
                                    "shortName": "Fiscal Law"
                                }
                            ],
                            "achievementLanguage": "",
                            "gradeText": "",
                            "hierarchy": "00100200",
                            "hierarchyParent": "00100",
                            "hierarchyLevel": 2,
                            "isDetail": false,
                            "isTitle": true,
                            "mark": null,
                            "maxCredits": "32.00",
                            "minCredits": "32.00",
                            "mncp": "0.00",
                            "scoreCardMask": 258,
                            "semester": "",
                            "studyPlanMainFocus": null,
                            "sumOfCredits": null,
                            "description": "Compulsory Subjects",
                            "id": 4395,
                            "shortName": "Compulsory Subjects"
                        },
                        {
                            "items": [
                                {
                                    "items": [],
                                    "achievementLanguage": null,
                                    "gradeText": "",
                                    "hierarchy": "00100201300",
                                    "isTitle": true,
                                    "mark": null,
                                    "maxCredits": "52.00",
                                    "minCredits": "44.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 258,
                                    "semester": "",
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Core Electives",
                                    "id": 4423,
                                    "shortName": "Core Electives"
                                },
                                {
                                    "items": [],
                                    "achievementLanguage": null,
                                    "gradeText": "",
                                    "hierarchy": "00100201301",
                                    "isTitle": true,
                                    "mark": null,
                                    "maxCredits": "8.00",
                                    "minCredits": "0.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 258,
                                    "semester": "",
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Electives Law / Moot Courts",
                                    "id": 4421,
                                    "shortName": "Electives Law / Moot Courts"
                                }
                            ],
                            "achievementLanguage": null,
                            "gradeText": "",
                            "hierarchy": "00100201",
                            "isTitle": true,
                            "mark": null,
                            "maxCredits": "52.00",
                            "minCredits": "52.00",
                            "mncp": "0.00",
                            "scoreCardMask": 258,
                            "semester": "",
                            "studyPlanMainFocus": null,
                            "sumOfCredits": null,
                            "description": "Core Electives and Electives",
                            "id": 4426,
                            "shortName": "Core Electives and Electives"
                        }
                    ],
                    "achievementLanguage": null,
                    "gradeText": "",
                    "hierarchy": "00100",
                    "isTitle": true,
                    "mark": null,
                    "maxCredits": "84.00",
                    "minCredits": "84.00",
                    "mncp": "0.00",
                    "scoreCardMask": 770,
                    "semester": "",
                    "studyPlanMainFocus": null,
                    "sumOfCredits": null,
                    "description": "Core Studies",
                    "id": 4391,
                    "shortName": "Core Studies"
                },
                {
                    "items": [
                        {
                            "items": [],
                            "achievementLanguage":null,
                            "gradeText": "",
                            "hierarchy": "00101200",
                            "isTitle": true,
                            "mark": null,
                            "maxCredits": "24.00",
                            "minCredits": "12.00",
                            "mncp": "0.00",
                            "scoreCardMask": 258,
                            "semester": "",
                            "studyPlanMainFocus": null,
                            "sumOfCredits": null,
                            "description": "Area of Concentration",
                            "id": 4397,
                            "shortName": "Area of Concentration"
                        },
                        {
                            "items": [
                                {
                                    "items": [],
                                    "achievementLanguage": null,
                                    "gradeText": "",
                                    "hierarchy": "00101201300",
                                    "isTitle": true,
                                    "mark": null,
                                    "maxCredits": "12.00",
                                    "minCredits": "0.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 258,
                                    "semester": "",
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Skills",
                                    "id": 4396,
                                    "shortName": "Skills"
                                },
                                {
                                    "items": [],
                                    "achievementLanguage": null,
                                    "gradeText": "",
                                    "hierarchy": "00101201301",
                                    "isTitle": true,
                                    "mark": null,
                                    "maxCredits": "0.00",
                                    "minCredits": "0.00",
                                    "mncp": "0.00",
                                    "scoreCardMask": 264,
                                    "semester": "",
                                    "studyPlanMainFocus": null,
                                    "sumOfCredits": null,
                                    "description": "Languages",
                                    "id": 4411,
                                    "shortName": "Languages"
                                }
                            ],
                            "achievementLanguage": null,
                            "gradeText": "",
                            "hierarchy": "00101201",
                            "isTitle": true,
                            "mark": null,
                            "maxCredits": "12.00",
                            "minCredits": "0.00",
                            "mncp": "0.00",
                            "scoreCardMask": 258,
                            "semester": "",
                            "studyPlanMainFocus": null,
                            "sumOfCredits": null,
                            "description": "Skills and Languages",
                            "id": 4424,
                            "shortName": "Skills and Languages"
                        }                       
                    ],
                    "achievementLanguage": null,
                    "gradeText": "",
                    "hierarchy": "00101",
                    "isTitle": true,
                    "mark": null,
                    "maxCredits": "24.00",
                    "minCredits": "24.00",
                    "mncp": "0.00",
                    "scoreCardMask": 770,
                    "semester": "",
                    "studyPlanMainFocus": null,
                    "sumOfCredits": null,
                    "description": "Contextual Studies",
                    "id": 4392,
                    "shortName": "Contextual Studies"
                },
                {
                    "items": [],
                    "achievementLanguage": null,
                    "gradeText": "",
                    "hierarchy": "00102",
                    "isTitle": true,
                    "mark": null,
                    "maxCredits": "12.00",
                    "minCredits": "12.00",
                    "mncp": "0.00",
                    "scoreCardMask": 774,
                    "semester": "",
                    "studyPlanMainFocus": null,
                    "sumOfCredits": null,
                    "description": "Bachelor's thesis",
                    "id": 4427,
                    "shortName": "Bachelor's thesis",
                }
            ],
            "achievementLanguage": null,
            "gradeText": "",
            "hierarchy": "00",
            "isTitle": true,
            "mark": null,
            "maxCredits": "120.00",
            "minCredits": "120.00",
            "mncp": "0.00",
            "scoreCardMask": 1,
            "semester": "",
            "studyPlanMainFocus": null,
            "sumOfCredits": null,
            "description": "BLAW",
            "id": 4390,
            "shortName": "BLAW"
        },
    ],
    minCreditsDe: null,
    minCreditsEn: null,
    scoreCardLinks: [],
    showMncp: true,
    id: 0,
};