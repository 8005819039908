import React from "react";
import MobileCalendar from "./MobileCalendar"

// Container holding the calendar
export const MobileCalendarContainer = ({
	mobileCalendarState,
	setMobileCalendarState,
	selectedEventDates,
	SelectEvent,
	ToggleDetails,
}) => {
	return (
		<div
			className={`transition duration-1000 ease-in-out z-10  w-full h-full ${
				mobileCalendarState && "transform -translate-y-full"
			}`}
		>
			<div className="h-full bg-gray-100">
				<MobileCalendar
					selectedEventDates={selectedEventDates}
					SelectEvent={SelectEvent}
					ToggleDetails={ToggleDetails}
				/>
			</div>
		</div>
	);
};
