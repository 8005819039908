// Dependencies
import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useFilterToggle } from "../utils/events/FilterToggle";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

// Components
import LoginStatus from "../utils/auth/LoginStatus";
import {
  AllCourseRatings,
  CleanCourses,
  CourseRatingDetailById,
  CoursesLastSemester,
  initialStudyPlan,
  MyCourses,
  MyCourseSheets,
  RatedCoursesForUser,
  SubmitCourseRatingById,
} from "../utils/data/apiData";

import RightCol from "../components/rightCol/rightCol";
import LeftCol from "../components/leftCol/leftCol";
import { UseToggle } from "../utils/calendar/CalendarToggle";
import MobileView from "../components/mobile/MobileView";
import EmptyCalPopUp from "../utils/events/EmptyCalPopUp";
import {
  OverlappingEventDates,
  ReturnSelectedEventDates,
} from "../utils/events/EventsFunctions";
import { AcquisitionModal } from "../utils/modals/AcquisitionModal";
import { eventDateColors } from "../utils/calendar/EventColors";
import { RatingModal } from "../utils/modals/RatingModal";

function ProtectedComponent({ setCookieState }) {
  const { instance, inProgress, accounts } = useMsal();
  const [events, setEvents] = useState(null);
  const [myCourses, setMyCourses] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [lastSelectedEvent, setLastSelectedEvent] = useState(null);
  const [selectedEventDates, setSelectedEventDates] = useState([]);
  const [focusEvent, setFocusEvent] = useState([]);
  const [focusEventId, setFocusEventId] = useState();
  const [isToggled, toggle] = UseToggle(true);
  const [emptyCalOpen, setEmptyCalOpen] = useState(false);
  const [mobileCalendarState, setMobileCalendarState] = useState(false);
  const [toBeRated, SetToBeRated] = useState();
  const [accessToken, setAccessToken] = useState()

  useEffect(() => {
    setCookieState(false);
    if (!events && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: ["https://integration.unisg.ch/api/user_impersonation"],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          // Acquire token interactive success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken)

          const myCourseData = await MyCourses(accessToken);
          const mySheetsData = await MyCourseSheets(accessToken);
          const AllRatings = await AllCourseRatings(accessToken);
          const userStudyPlan = await initialStudyPlan(accessToken);
          // console.log("myCourseData",myCourseData)
          // console.log("mySheetsData",mySheetsData)
          // console.log("AllRatings",AllRatings)
          // console.log("userStudyPlan", userStudyPlan)
          // const AllRatings = [
          //   {
          //     "_id": "11,202,1.00",
          //     "avgRating": 4.7
          //   },
          //   {
          //     "_id": "11,204,1.00",
          //     "avgRating": 3.9
          //   },
          //   {
          //     "_id": "11,400,1.00",
          //     "avgRating": 4.2
          //   },
          //   {
          //     "_id": "7,064,1.00",
          //     "avgRating": 3.3
          //   },
          //   {
          //     "_id": "7,066,1.00",
          //     "avgRating": 4.6
          //   },
          //   {
          //     "_id": "7,080,1.00",
          //     "avgRating": 4.8
          //   },
          //   {
          //     "_id": "7,115,1.00",
          //     "avgRating": 4.6
          //   },
          //   {
          //     "_id": "7,116,1.00",
          //     "avgRating": 4.0
          //   },
          //   {
          //     "_id": "7,117,1.00",
          //     "avgRating": 3.7
          //   },
          //   {
          //     "_id": "7,118,1.00",
          //     "avgRating": 3.9
          //   }
          // ]

          const cleanCourses = CleanCourses(
            mySheetsData,
            myCourseData,
            AllRatings,
            userStudyPlan
          );
          setEvents(cleanCourses);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                // Call API with token
                MyCourses(accessToken)
                  .then((response) => {
                    console.debug(response);
                    setMyCourses(response);
                    const myCourseIds = response.map(
                      (course) => course.eventCourseNumber
                    );
                    return myCourseIds;
                  })
                  .then((myCourseIds) => {
                    MyCourseSheets(accessToken).then((response) => {
                      setEvents(CleanCourses(response, myCourseIds));
                    });
                  });
              })
              .catch(function (error) {
                setEvents([]);
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, events, selectedEvents, setCookieState]);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["https://integration.unisg.ch/api/user_impersonation"],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(async (accessTokenResponse) => {
        // Acquire token interactive success
        let accessToken = accessTokenResponse.accessToken;

        const takenCourses = await CoursesLastSemester(accessToken);
        const ratedCourses = await RatedCoursesForUser(accessToken);

        const toBeRated = [];

        takenCourses.forEach((course) => {
          if (!ratedCourses.includes(course.eventCourseNumber)) {
            toBeRated.push({
              courseId: course.eventCourseNumber,
              courseName: course.eventDescription,
              semesterName: course.semesterName,
            });
          }
        });
        SetToBeRated(toBeRated);
      })
      .catch((error) => {
        // if (error instanceof InteractionRequiredAuthError) {
        //   instance
        //     .acquireTokenPopup(accessTokenRequest)
        //     .then(function (accessTokenResponse) {
        //       // Acquire token interactive success
        //       let accessToken = accessTokenResponse.accessToken;
        //       // Call API with token
        //       MyCourses(accessToken)
        //         .then((response) => {
        //           console.debug(response);
        //           setMyCourses(response);
        //           const myCourseIds = response.map(
        //             (course) => course.eventCourseNumber
        //           );
        //           return myCourseIds;
        //         })
        //         .then((myCourseIds) => {
        //           MyCourseSheets(accessToken).then((response) => {
        //             setEvents(CleanCourses(response, myCourseIds));
        //           });
        //         });
        //     })
        //     .catch(function (error) {
        //       setEvents([]);
        //       console.log(error);
        //     });
        // }
        // console.log(error);
      });
  }, [instance, accounts]);

  useEffect(() => {
    const eventDateSelection = ReturnSelectedEventDates(selectedEvents);
    const overlapping = OverlappingEventDates(eventDateSelection);
    const uniqueEvents = [...new Set(overlapping.map((item) => item.courseId))];
    selectedEvents
      .map((event) => {
        if (uniqueEvents.includes(event.id)) {
          event.overlapping = true;
          return event;
        } else {
          event.overlapping = false;
          return event;
        }
      })
      .filter((event) => event.overlapping === true);
    setSelectedEventDates(eventDateSelection);

    eventDateSelection.map((event) => {
      if (overlapping.includes(event)) {
        event.color = eventDateColors.overlapping;
        return event;
      } else {
        event.color = eventDateColors.notOverlapping;
        return event;
      }
    });

  }, [accessToken, events, selectedEvents])

  // -------------------------------------------------------------------------------------
  // Start of Functions --------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  const SelectEvent = (id) => {
    const updatedEvents = events
      .map((event) => {
        if (event.id === id) {
          event.selected = !event.selected;
          setLastSelectedEvent(event);
          if (event.selected && event.calendar.length === 0) {
            setEmptyCalOpen(true);
          } else {
            setEmptyCalOpen(false);
          }
        }
        if (event.allocated === true) {
          event.selected = true;
        }
        return event;
      })
      .filter((event) => event.selected === true);
    setSelectedEvents(updatedEvents);
    const selectedEventIds = updatedEvents.map((event) => event.id);
    localStorage.setItem("selectedEvents", selectedEventIds.toString());
  };

  const ToggleDetails = (id) => {
    setFocusEventId(id);
    focusEventId && id !== focusEventId && isToggled === false
      ? console.debug("change me")
      : toggle(!isToggled);
  };

  const postRating = (ratingData) => {
    const accessTokenRequest = {
      scopes: ["https://integration.unisg.ch/api/user_impersonation"],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(async (accessTokenResponse) => {
        // Acquire token interactive success
        let accessToken = accessTokenResponse.accessToken;

        SubmitCourseRatingById(accessToken, ratingData);
      })
      .catch((error) => {
        // if (error instanceof InteractionRequiredAuthError) {
        //   instance
        //     .acquireTokenPopup(accessTokenRequest)
        //     .then(function (accessTokenResponse) {
        //       // Acquire token interactive success
        //       let accessToken = accessTokenResponse.accessToken;
        //       // Call API with token
        //       MyCourses(accessToken)
        //         .then((response) => {
        //           console.debug(response);
        //           setMyCourses(response);
        //           const myCourseIds = response.map(
        //             (course) => course.eventCourseNumber
        //           );
        //           return myCourseIds;
        //         })
        //         .then((myCourseIds) => {
        //           MyCourseSheets(accessToken).then((response) => {
        //             setEvents(CleanCourses(response, myCourseIds));
        //           });
        //         });
        //     })
        //     .catch(function (error) {
        //       setEvents([]);
        //       console.log(error);
        //     });
        // }
        // console.log(error);
      });
  };
  // End of Functions --------------------------------------------------------------------

  // -------------------------------------------------------------------------------------
  // Start of Effects
  // -------------------------------------------------------------------------------------
  // useEffect(() => {
  //   let selectedEventIds = [];
  //   if (
  //     events &&
  //     selectedEvents.length === 0 &&
  //     localStorage.getItem("selectedEvents") &&
  //     localStorage.getItem("selectedEvents").length > 0
  //   ) {
  //     console.log("IF CLAUSE")
  //     selectedEventIds = localStorage
  //       .getItem("selectedEvents")
  //       .toString()
  //       .split(",");
  //     // remove selectedEventIds that are not in events
  //     selectedEventIds = selectedEventIds.filter((id) =>
  //       events.map((event) => event.id).includes(id)
  //     );
  //     selectedEventIds.map((id) => {
  //       const updatedEvents = events
  //         .map((event) => {
  //           if (event.id === id) {
  //             event.selected = !event.selected;
  //             setLastSelectedEvent(event);
  //             if (event.selected && event.calendar.length === 0) {
  //               setEmptyCalOpen(true);
  //             } else {
  //               setEmptyCalOpen(false);
  //             }
  //           }
  //           return event;
  //         })
  //         .filter((event) => event.selected === true);
  //       setSelectedEvents(updatedEvents);
  //       return id;
  //     });
  //   } else {
  //     console.log("ELSE CLAUSE")
  //     selectedEventIds = selectedEvents.map((event) => event.id);
  //   }

  //   History.push(`/?courses=` + selectedEventIds);
  //   const eventDateSelection = ReturnSelectedEventDates(selectedEvents);
  //   const overlapping = OverlappingEventDates(eventDateSelection);
  //   const uniqueEvents = [...new Set(overlapping.map((item) => item.courseId))];
  //   selectedEvents
  //     .map((event) => {
  //       if (uniqueEvents.includes(event.id)) {
  //         event.overlapping = true;
  //         return event;
  //       } else {
  //         event.overlapping = false;
  //         return event;
  //       }
  //     })
  //     .filter((event) => event.overlapping === true);
  //   setSelectedEventDates(eventDateSelection);

  //   eventDateSelection.map((event) => {
  //     if (overlapping.includes(event)) {
  //       event.color = eventDateColors.overlapping;
  //       return event;
  //     } else {
  //       event.color = eventDateColors.notOverlapping;
  //       return event;
  //     }
  //   });
  // }, [events, selectedEvents]);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["https://integration.unisg.ch/api/user_impersonation"],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(async (accessTokenResponse) => {
        // Acquire token interactive success
        let accessToken = accessTokenResponse.accessToken;

        let FocusEventData =
          events &&
          events
            .map((event) => {
              if (focusEventId && event.id === focusEventId) {
                return event;
              } else {
                return undefined;
              }
            })
            .filter((event) => event !== undefined);

        if (FocusEventData && FocusEventData.length > 0) {
          const courseRatingDetails = await CourseRatingDetailById(
            accessToken,
            FocusEventData[0].courseNumber
          );
          // const courseRatingDetails = [
          //   {
          //     "courseNumber": "11,204,1.00",
          //     "avgRating": 3.9,
          //     "nbOfRatings": 6,
          //     "comments": [{ "text": "Super spannender Kurs, kann ich nur empfehlen!", "semester": "HS22" },
          //     { "text": "Cooler Kurs, wünschte nur, die Folien wären strukturierter", "semester": "HS22" }],
          //     "avgRatings": {
          //       "topic": 3.2,
          //       "lecture": 4.5,
          //       "materials": 3,
          //       "professor": 4.6,
          //       "exam": 4.5,
          //       "workload": 3.5
          //     }
          //   }
          // ]
          if (courseRatingDetails.length > 0) {
            delete courseRatingDetails[0].courseNumber;
            Object.assign(FocusEventData[0], courseRatingDetails[0]);
          }
        }
        setFocusEvent(FocusEventData && FocusEventData[0]);
      });
  }, [focusEventId, events, instance, accounts]);

  // Filter & Search ----------------------------------------
  const [filterToggle, setFilterToggle] = useFilterToggle(true);
  const [filterActive, setFilterActive] = useState(false);

  const [classificationSelection, setClassificationSelection] = useState("");
  const [allocatedSelection, setAllocatedSelection] = useState("");
  const [languageSelection, setLanguageSelection] = useState("");
  const [ectsSelection, setEctsSelection] = useState("");
  const [lecturerSelection, setLecturerSelection] = useState("");

  const [filterContainerFilterData, setFilterContainerFilterData] = useState(
    []
  );

  const [searchValue, setSearchValue] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);

  function OnlyUniqueValue(value, index, self) {
    return self.indexOf(value) === index;
  }

  const handleAllocatedOptionChange = (event) => {
    setAllocatedSelection(event.target.value);
  };

  const handleClassificationOptionChange = (e) => {
    setClassificationSelection(e.target.value);
  };

  const handleLanguageOptionChange = (e) => {
    setLanguageSelection(e.target.value);
  };

  const handleEctsOptionChange = (e) => {
    setEctsSelection(e.target.value);
  };

  const handleLecturerOptionChange = (e) => {
    setLecturerSelection(e.target.value);
  };

  useEffect(() => {
    if (events !== null) {
      let allocatedOptions = events
        .map((event) => {
          return event.allocated ? "true" : "false";
        })
        .filter(OnlyUniqueValue)
        .sort();
      let classificationOptions = events
        .map((event) => {
          return event.classification;
        })
        .filter(OnlyUniqueValue)
        .sort();

      let languageOptions = events
        .map((event) => {
          return event.language;
        })
        .filter(OnlyUniqueValue)
        .sort();

      let ectsOptions = events
        .map((event) => {
          return event.ects;
        })
        .filter(OnlyUniqueValue)
        .sort();

      let lecturerOptions = events
        .map((event) => {
          return event.lecturers.map((lecturer) => {
            return lecturer.firstName + " " + lecturer.lastName;
          });
        })
        .flat()
        .filter(OnlyUniqueValue)
        .sort();

      setFilterContainerFilterData([
        {
          title: "Allocated",
          values: allocatedOptions,
          value: allocatedSelection,
          handleOption: handleAllocatedOptionChange,
        },
        {
          title: "Classification",
          values: classificationOptions,
          value: classificationSelection,
          handleOption: handleClassificationOptionChange,
        },
        {
          title: "Language",
          values: languageOptions,
          value: languageSelection,
          handleOption: handleLanguageOptionChange,
        },
        {
          title: "ECTS",
          values: ectsOptions,
          value: ectsSelection,
          handleOption: handleEctsOptionChange,
        },
        {
          title: "Lecturers",
          values: lecturerOptions,
          value: lecturerSelection,
          handleOption: handleLecturerOptionChange,
        },
      ]);
    }

    const searchRegex = searchValue && new RegExp(`${searchValue}`, "i");
    const result =
      events &&
      events.filter(
        (event) =>
          (!searchRegex ||
            searchRegex.test(event.shortName) ||
            searchRegex.test(event.courseNumber)) &&
          (!classificationSelection ||
            event.classification === classificationSelection) &&
          (!allocatedSelection ||
            event.allocated.toString() === allocatedSelection) &&
          (!languageSelection || event.language === languageSelection) &&
          (!ectsSelection || event.ects === parseInt(ectsSelection)) &&
          (!lecturerSelection ||
            event.lecturers.some(
              (lecturer) =>
                lecturer.firstName + " " + lecturer.lastName ===
                lecturerSelection
            ))
      );
    setFilteredEvents(result);

    setFilterActive(
      classificationSelection !== "" ||
      allocatedSelection !== "" ||
      languageSelection !== "" ||
      ectsSelection !== "" ||
      lecturerSelection !== ""
    );
  }, [
    events,
    searchValue,
    classificationSelection,
    allocatedSelection,
    languageSelection,
    ectsSelection,
    lecturerSelection,
    myCourses,
  ]);
  // End Filter & Search ----------------------------------------

  // End of Effects --------------------------------------------------------------------
  return (
    <div className="w-full h-full">
      <AcquisitionModal />
      <RatingModal />
      <div className="flex-1 hidden h-full overflow-hidden lg:flex">
        <LeftCol
          events={events}
          selectedEvents={selectedEvents}
          SelectEvent={SelectEvent}
          selectedEventDates={selectedEventDates}
          ToggleDetails={ToggleDetails}
          isToggled={isToggled}
          toggle={toggle}
          focusEvent={focusEvent}
          filterToggle={filterToggle}
          setFilterToggle={setFilterToggle}
          filterContainerFilterData={filterContainerFilterData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filteredEvents={filteredEvents}
          filterActive={filterActive}
          myCourses={myCourses}
          toBeRated={toBeRated}
          postRating={postRating}
          accessToken={accessToken}
        />
        <RightCol
          selectedEventDates={selectedEventDates}
          SelectEvent={SelectEvent}
          isToggled={isToggled}
          toggle={toggle}
          focusEvent={focusEvent}
          ToggleDetails={ToggleDetails}
        />
      </div>
      <div
        className={`absolute z-50 bottom-0 md:left-1/2 ${!emptyCalOpen ? "hidden" : ""
          }`}
      >
        <EmptyCalPopUp
          setEmptyCalOpen={setEmptyCalOpen}
          lastSelectedEvent={lastSelectedEvent}
        />
      </div>
      <div className="w-screen h-screen lg:hidden">
        <MobileView
          mobileCalendarState={mobileCalendarState}
          setMobileCalendarState={setMobileCalendarState}
          isToggled={isToggled}
          toggle={toggle}
          focusEvent={focusEvent}
          ToggleDetails={ToggleDetails}
          selectedEventDates={selectedEventDates}
          SelectEvent={SelectEvent}
          selectedEvents={selectedEvents}
          filteredEvents={filteredEvents}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterToggle={filterToggle}
          setFilterToggle={setFilterToggle}
          filterActive={filterActive}
          filterContainerFilterData={filterContainerFilterData}
          accessToken={accessToken}
        />
      </div>
    </div>
  );
}

export default function Biddit() {
  const [cookieState, setCookieState] = useState(true);

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="flex h-full">
        <LoginStatus
          cookieState={cookieState}
          setCookieState={setCookieState}
        />
        <AuthenticatedTemplate>
          <ProtectedComponent setCookieState={setCookieState} />
        </AuthenticatedTemplate>
      </div>
    </div>
  );
}
