import { atom, selector } from "recoil";
import { tokenQuery } from "../../Token";
import axios from "axios";
import { programEnrollmentStatusState } from "./programEnrollmentStatus";
import { currentEnrollmentsState } from "./currentEnrollments";
import { studienOrdnungAssessmentStandard } from "./scorecardTemplates/studienOrdnungAssessmentStandard";
import { studienOrdnungAssessmentInfo } from "./scorecardTemplates/studienOrdnungAssessmentInfo";
import { studienOrdnungBBWL } from "./scorecardTemplates/studienOrdnungBBWL";
import { studienOrdnungBVWL } from "./scorecardTemplates/studienOrdnungBVWL";
import { studienOrdnungBLAW } from "./scorecardTemplates/studienOrdnungBLAW";


import { toast } from 'react-toastify';

const _ = require("lodash");


export const scorecardGraduationState = atom({
    key: "scorecardGraduation",
    default: selector({
        key: "scorecardGraduation/Default",
        get: async ({ get }) => {
            const token = await get(tokenQuery);
            const currentEnrollments = get(currentEnrollmentsState);
            const programEnrollmentStatus = get(programEnrollmentStatusState);

            console.log('currentEnrollments', currentEnrollments)

            let scorecardShortName = "";
            try {
                scorecardShortName = currentEnrollments.filter(
                    (enrollment) => enrollment.isMainStudy
                )[0].studyRegulationDescription;
            } catch (err) {
                throw new Error(
                    "No Main Study found.\n This can have multiple causes including that you are currently not enrolled in a program or that the university servers haven't been updated yet.\n Unfortunately, this is nothing the Biddit Team can fix.",
                    "scorecardGraduation",
                    "93"
                );
            }

            console.log('scorecardShortName', scorecardShortName)


            //let scorecardId;

            try {
                console.log('programEnrollmentStatus', programEnrollmentStatus)
                const relevantScorecard = programEnrollmentStatus.find(
                    (scorecard) => scorecard.shortName === scorecardShortName ||
                    scorecard.status.description === "laufend" ||
                    scorecard.status.description === "ongoing"
                );
            
                if (!relevantScorecard) {
                    throw new Error(`No matching scorecard found for scorecardShortName: ${scorecardShortName}`);
                }
            
                //scorecardId = relevantScorecard.id;
            } catch (err) {
    throw new Error(
        `No Scorecard found.\n This can have multiple causes including that you are newly enrolled in a program or that the university servers haven't been updated yet.\n Unfortunately, this is nothing the Biddit Team can fix.\n scorecardShortName: ${scorecardShortName}`,
        "ScorecardGraduation",
        "113"
    );
}

            
            // console.log("SCORECARD ID: ", scorecardId);

            try {
                // Call the first API endpoint (Update Aug 2024)
                // This is used to find the studyRegulationId and attempt for the other calls
                const studiesResponse = await axios.get(
                    `https://integration.unisg.ch/AchievementApi/MyScoreCards/studiesWithProgramEnrollmentStatus`,
                    {
                        headers: {
                            "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                            "X-RequestedLanguage": "EN",
                            "API-Version": "1",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log("studiesResponse: ", studiesResponse)

                // Primary and fallback matching logic for finding relevant study
                const relevantStudy = studiesResponse.data.find(study => {
                    // First attempt: Direct shortName match (original logic)
                    if (study.shortName === scorecardShortName) return true;
                    
                    // Second attempt: Match scorecard description with study description
                    if (study.description && study.description === scorecardShortName) return true;
            
                    return false;
                });
                console.log("Relevant Study identified: ", relevantStudy)

                if (!relevantStudy) {
                    console.warn("Study matching failed. Attempted matches:", {
                        scorecardShortName,
                        availableStudies: studiesResponse.data.map(s => ({
                            shortName: s.shortName,
                            description: s.description
                        }))
                    });
                    throw new Error("No relevant study found after trying all matching methods");
                }

                // Call the other API endpoints
                let detailResponse;
                try {
                    console.log("Trying to fetch API detailResponse with following url:", `https://integration.unisg.ch/AchievementApi/MyScoreCards/detail/byStudyRegulationId/${relevantStudy.studyRegulationId}/byAttempt/${relevantStudy.attempt}`)
                    detailResponse = await axios.get(
                        `https://integration.unisg.ch/AchievementApi/MyScoreCards/detail/byStudyRegulationId/${relevantStudy.studyRegulationId}/byAttempt/${relevantStudy.attempt}`,
                        {
                            headers: {
                                "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                                "X-RequestedLanguage": "EN",
                                "API-Version": "1",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    console.log("Scorecard detailResponse from API: ", detailResponse)
                } catch (error) {
                    console.error(`Error fetching scorecard details for studyRegulationId: ${relevantStudy.studyRegulationId}, attempt: ${relevantStudy.attempt}:`, error);
                    toast.warn(
                        "Your data from the official university database is incomplete. We’re displaying a default Transcript, which may not reflect your degree. Your accurate curriculum will be shown once the university updates their data. Unfortunately, this is nothing that the Biddit Team can fix.",
                        {
                            position: "top-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    // If the API call fails, return the custom scorecard based on the program
                    return validateScorecard({}, scorecardShortName);
                }

                // Recreate hierarchical structure of original response from new Scorecard API
                const createHierarchicalItems = (items) => {
                    const itemMap = new Map();
                    const rootItems = [];
                
                    items.forEach(item => {
                        itemMap.set(item.hierarchy, item);
                        item.items = item.items || [];
                
                        if (item.hierarchyParent && itemMap.has(item.hierarchyParent)) {
                            const parent = itemMap.get(item.hierarchyParent);
                            parent.items = parent.items || [];
                            parent.items.push(item);
                        } else {
                            rootItems.push(item);
                        }
                    });
                
                    return rootItems;
                };

                // Combine the responses to match the old structure (pre-Scorecard API change)
                const combinedResponse = {
                    ...detailResponse.data,
                    items: createHierarchicalItems(detailResponse.data.items[0].items),
                    scoreCardLinks: [],
                    showMncp: true,
                };

                console.log('Combined Scorecard:', JSON.stringify(combinedResponse, null, 2));

                return validateScorecard(combinedResponse, scorecardShortName);
            } catch (err) {
                console.error("Error in try: ", err);
                return validateScorecard({}, scorecardShortName);
            }
        },
    }),
});

function validateScorecard(scorecard, program) {
    console.log('validateScorecard help: ', scorecard, program)
    if (_.isEmpty(scorecard)) {
        console.log('validateScorecard help: scorecard is empty')
        // console.log("CUSTOM SCORECARD RETURNED FOR PROGRAM: " + program);

        console.log("DEBUG: program: ", program);
        switch (program) {
            case "18ASJ":
                return studienOrdnungAssessmentStandard;
            case "18ASWd":
                return studienOrdnungAssessmentStandard;
            case "18ASWe":
                return studienOrdnungAssessmentStandard;
            case "22AJ-BCS":
                return studienOrdnungAssessmentInfo;
            case "BBWL":
                console.log('Scorecard Layout identified: BBWL')
                return studienOrdnungBBWL;
            case "19BBWL":
                console.log('Scorecard Layout identified: 19BBWL')
                return studienOrdnungBBWL;
            case "BVWL":
                console.log('Scorecard Layout identified: BVWL')
                return studienOrdnungBVWL;
            case "19BVWL":
                console.log('Scorecard Layout identified: 19BVWL')
                return studienOrdnungBVWL;
            case "BLAW":
                console.log('Scorecard Layout identified: BLAW')
                return studienOrdnungBLAW;
            default:
                console.log("DEBUG: Reverting to standard scorecard");
                return standardScorecard;
        }
    } else {
        console.log('validateScorecard help: scorecard is not empty - return scorecard')
        console.log('returned scorecard from validateScorecard', scorecard)
        return scorecard;
    }
}

export const standardScorecard = {
    creditsDe: null,
    creditsEn: null,
    creditsFulfilledDe: null,
    creditsFulfilledEn: null,
    isProcessing: false,
    items: [
        {
            items: [
                {
                    items: [
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3453.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "15.00",
                            minCredits: "15.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: "15.00",
                            description: "Pflichtbereich",
                            id: 3453,
                            shortName: "Pflichtbereich",
                        },
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.3674.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "15.00",
                                    minCredits: "3.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: "6.00",
                                    description: "Methodenkurs",
                                    id: 3674,
                                    shortName: "Methodenkurs",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.3465.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "12.00",
                                    minCredits: "12.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: "8.00",
                                    description:
                                        "Forschungs-, Praxis-, Ventureprojekte / Issue Coverage",
                                    id: 3465,
                                    shortName:
                                        "Forschungs-, Praxis-, Ventureprojekte / Issue Coverage",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.3458.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "24.00",
                                    minCredits: "12.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: "12.00",
                                    description: "Pflichtwahlbereich",
                                    id: 3458,
                                    shortName: "Pflichtwahlbereich",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.3459.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "12.00",
                                    minCredits: "0.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: "4.00",
                                    description: "Wahlbereich",
                                    id: 3459,
                                    shortName: "Wahlbereich",
                                },
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "39.00",
                            minCredits: "39.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: "30.00",
                            description: "Pflichtwahl- und Wahlbereich",
                            id: 3457,
                            shortName: "Pflichtwahl- und Wahlbereich",
                        },
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3452.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "54.00",
                    minCredits: "54.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: "45.00",
                    description: "Fachstudium",
                    id: 3452,
                    shortName: "Fachstudium",
                },
                {
                    items: [
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3462.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "18.00",
                            minCredits: "12.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: "3.00",
                            description: "Fokusbereiche",
                            id: 3462,
                            shortName: "Fokusbereiche",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "6.00",
                            minCredits: "0.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: "3.00",
                            description: "Skills",
                            id: 3461,
                            shortName: "Skills",
                        },
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3460.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "18.00",
                    minCredits: "18.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: "6.00",
                    description: "Kontextstudium",
                    id: 3460,
                    shortName: "Kontextstudium",
                },
                {
                    items: [],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3463.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "18.00",
                    minCredits: "18.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: "0.00",
                    description: "Master-Arbeit (Titel in Originalsprache)",
                    id: 3463,
                    shortName: "Master-Arbeit (Titel in Originalsprache)",
                },
            ],
            achievementLanguage: null,
            gradeText: "",
            hierarchy: ".3451.",
            isTitle: true,
            mark: null,
            maxCredits: "90.00",
            minCredits: "90.00",
            mncp: "0.00",
            scoreCardMask: 1,
            semester: "",
            studyPlanMainFocus: null,
            sumOfCredits: "51.00",
            description: "Core Studies (Standard Scorecard - PLACEHOLDER)",
            id: 3451,
            shortName: "Core Studies (Standard Scorecard - PLACEHOLDER)",
        },
    ],
    minCreditsDe: null,
    minCreditsEn: null,
    scoreCardLinks: [],
    showMncp: true,
    id: 0,
};