// import { Fragment } from "react";
// import { selectedTabState } from "../../../recoil/atoms/general/selectedTab";
// import { selectedCourseState } from "../../../recoil/atoms/general/selectedCourse";
// import { useSetRecoilState } from "recoil";
// import { StatusElement } from "./StatusElement";
import { TranscriptTable } from "./TranscriptTable";
// import Tooltip from "../util/Tooltip";

export default function Transcript({ currItem }) {

  return (
    <div className="flex flex-col p-2">
      <div className="w-full h-full">
        <div className="inline-block w-full h-full min-w-full align-middle">
          <div className="pb-3 overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <TranscriptTable row={currItem} />
          </div>
        </div>
      </div>
    </div>
  );
}
