import React from "react";
import { useRecoilValue } from "recoil";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { courseRatingDetailQuery } from "../../../recoil/atoms/shsg_ratings/courseRatingDetail";

import Collapsible from "./FocusCollapsible";
import ReactTooltip from "react-tooltip";
import { tooltipTexts } from "./tooltipTexts";
import { StarIcon } from "@heroicons/react/solid";
import { courseExamInformationQuery } from "../../../recoil/selectors/hsg/courseExamInformation";
import { examinationIdsState } from "../../../recoil/atoms/hsg/examinationIds";

export function CourseDetails() {
    // HIER HAST AUCH DIREKT ZUGRIFF AUF ALLE RATING DATEN .avgRating; .avgRatings; .comments; .nbOfRatings
    const selectedCourse = useRecoilValue(courseRatingDetailQuery);
    const examInformation = useRecoilValue(courseExamInformationQuery);
    const examinationIds = useRecoilValue(examinationIdsState)

    return (
        <div className="flex-col flex p-4 h-full  text-gray-800 rounded-lg shadow-sm">
            {/* <ErrorComponent /> */}
            {/* Course tital and key info */}
            <header className="font-bold lg:text-2xl">
                <div className="flex justify-between pb-4">
                    <a
                        href={selectedCourse && selectedCourse.timeTableLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {selectedCourse &&
                        selectedCourse.shortName !== undefined
                            ? selectedCourse.shortName
                            : "Click on a course to see details."}
                    </a>
                    {selectedCourse.courseLink && (
                        <a
                            href={selectedCourse.courseLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ExternalLinkIcon className="h-6 w-6" />
                        </a>
                    )}
                </div>
            </header>

            <div className="overflow-auto scrollbar-hide overscroll-auto">
                {selectedCourse && selectedCourse.shortName !== undefined ? (
                    <div className="text-xs font-semibold text-gray-700 lg:text-base">
                        <div className="" label="credits">
                            {(selectedCourse.credits / 100).toFixed(2)} ECTS |{" "}
                            {selectedCourse.classification} {examInformation.examinationFormStatus.isCentral ? "| Central" : examInformation.examinationFormStatus.isDecentral ? "| Decentral" : ""}
                        </div>
                        <div className="mb-4">
                            {selectedCourse.lecturers &&
                                selectedCourse.lecturers
                                    .map((prof) => {
                                        return (
                                            prof.firstName + " " + prof.lastName
                                        );
                                    })
                                    .join(" • ")}
                        </div>
                    </div>
                ) : null}

                {selectedCourse && "avgRatings" in selectedCourse ? (
                    // <StarCollapsilbe
                    //     label={`${selectedCourse.avgRating} · ${
                    //         selectedCourse.nbOfRatings
                    //     } ${
                    //         selectedCourse.nbOfRatings === 1 ? "Rating" : "Ratings"
                    //     }`}
                    //     CloseOnToggle={true}
                    // >
                    <div className="grid grid-cols-5 gap-8 text-base pb-4">
                        <div className="col-span-2">
                            <dl className="">
                                <div className="flex text-sm font-medium toggle md:text-lg text-gray-900">
                                    <StarIcon
                                        className="w-6 h-6 mr-1"
                                        color="#386641"
                                    />
                                    {selectedCourse.avgRating} ·{" "}
                                    {selectedCourse.nbOfRatings}{" "}
                                    {selectedCourse.nbOfRatings === 1
                                        ? "Rating"
                                        : "Ratings"}
                                </div>
                                {Object.keys(selectedCourse.avgRatings).filter((category) => category !== 'professor').map(
                                    (category) => (
                                        <dt>
                                            <div className="items-center flex flex-row">
                                                <div
                                                    className="capitalize w-1/3 md:w-1/5 mr-4"
                                                    data-tip="tooltip"
                                                    data-for={category}
                                                >
                                                    {category}
                                                </div>
                                                <ReactTooltip
                                                    id={category}
                                                    type="light"
                                                >
                                                    <span>
                                                        {tooltipTexts[category]}
                                                    </span>
                                                </ReactTooltip>

                                                <div className="bg-gray-300 rounded-full h-3 dark:bg-gray-600 align-middle flex-1 ">
                                                    <div
                                                        className="bg-gray-500 h-3 rounded-full dark:bg-gray-300"
                                                        style={{
                                                            width: `${
                                                                selectedCourse
                                                                    .avgRatings[
                                                                    category
                                                                ] * 20
                                                            }%`,
                                                        }}
                                                    />
                                                </div>
                                                <div className="ml-4 w-1/6">
                                                    Ø{" "}
                                                    {
                                                        selectedCourse
                                                            .avgRatings[
                                                            category
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                        </dt>
                                    )
                                )}
                            </dl>
                        </div>
                        {/* RATING COMMENTS */}
                        {/* <div className="col-span-3 h-40 overflow-auto">
                            {selectedCourse &&
                            "comments" in selectedCourse &&
                            selectedCourse.comments.length > 0 ? (
                                <dl className="space-y-2 ">
                                    {selectedCourse.comments.map((comment) => (
                                        <div key={comment.text}>
                                            <dt className="">
                                                <h4 className="font-bold">
                                                    {comment.semester}
                                                </h4>
                                                <p className="">
                                                    {comment.text}
                                                </p>
                                            </dt>
                                        </div>
                                    ))}
                                </dl>
                            ) : (
                                <></>
                            )}
                        </div> */}
                    </div>
                ) : (
                    // </StarCollapsilbe>
                    <></>
                )}

                <section className="pb-2">
                    <h2 className="text-lg font-bold text-gray-700 ">
                        Exam Information
                    </h2>
                    <div className="pb-1">
                        {selectedCourse && examInformation &&
                        examInformation !== undefined ? (
                            examInformation.examinationParts.map((part) => {
                                return (
                                    <div className="w-full text-sm">
                                        <div className="grid grid-cols-3">
                                            <div className="font-semibold">
                                                {examinationIds[part.examinationTypeId].shortName}
                                            </div>
                                            <div>
                                                {part.remark}
                                            </div>
                                            <div className="text-left">                                                
                                                {part.weightage / 100}%
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="text-base text-gray-700">
                                No Exam Information
                            </div>
                        )}
                    </div>
                </section>

                <section
                    className="flex flex-col flex-1"
                    label="Course Information"
                >
                    <h2 className="flex-none pb-2 text-xl font-bold text-gray-700 ">
                        Course Information
                    </h2>

                    <div className="flex-1 text-gray-700 rounded-lg">
                        <Collapsible
                            label="Learning Objectives"
                            CloseOnToggle={false}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedCourse &&
                                        selectedCourse.learningObjectives,
                                }}
                            />
                        </Collapsible>
                        <Collapsible label="Content" CloseOnToggle={true}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedCourse &&
                                        selectedCourse.courseContent,
                                }}
                            />
                        </Collapsible>
                        <Collapsible label="Prerequisites" CloseOnToggle={true}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedCourse &&
                                        selectedCourse.coursePrerequisites,
                                }}
                            />
                        </Collapsible>

                        <Collapsible label="Structure" CloseOnToggle={true}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedCourse &&
                                        selectedCourse.courseStructure,
                                }}
                            />
                        </Collapsible>
                        <Collapsible label="Literature" CloseOnToggle={true}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedCourse &&
                                        selectedCourse.courseLiterature,
                                }}
                            />
                        </Collapsible>
                        <Collapsible
                            label="Additional Information"
                            CloseOnToggle={true}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedCourse &&
                                        selectedCourse.courseAdditionalInformation,
                                }}
                            />
                        </Collapsible>
                    </div>
                </section>
            </div>
        </div>
    );
}
