import React from "react";
import { EventListSearchInput } from "./SearchInput";
import { SearchIcon } from "@heroicons/react/outline";

// Search bar for courses
export const EventListSearchBar = ({ searchValue, setSearchValue }) => {
	return (
		<div className="relative bg-white border border-transparent rounded-lg">
			<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
				<SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
			</div>
			<EventListSearchInput
				searchValue={searchValue}
				setSearchValue={setSearchValue}
			/>
		</div>
	);
};
