import { atom, selector } from "recoil";
import { relevantCisIdsQuery } from "../../selectors/hsg/relevantCisIds";
import { tokenQuery } from "../../Token";
import axios from "axios";

export const allocatedCoursesState = atom({
    key: 'allocatedCourses',
    default: selector({
        key: 'allocatedCourses/Default',
        get: async ({ get }) => {
            const token = get(tokenQuery)
            const termIds = get(relevantCisIdsQuery)

            async function loadCourses(termId) {
                try {
                    const res = await axios.get(
                        `https://integration.unisg.ch/eventapi/MyCourses/byTerm/${termId}`,
                        {
                            headers: {
                                "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                                "X-RequestedLanguage": "EN",
                                "API-Version": "1",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    return { id: termId, data: res.data };
                } catch (err) {
                    try {
                        const res = await axios.get(
                            `https://integration.unisg.ch/eventapi/MyCourses/byTerm/${termId}`,
                            {
                                headers: {
                                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                                    "X-RequestedLanguage": "EN",
                                    "API-Version": "1",
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                        return { id: termId, data: res.data };
                    } catch (err) {
                        return console.log(err);
                    }
                }
            }
            const data = await Promise.all(termIds.map(course => loadCourses(course.termId))).then((results) => { return Object.assign({}, ...results.map(semester => ({ [semester.id]: semester.data }))) }).then((results) => { return results })

            return data
        }
    })
})