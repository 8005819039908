import { TitleElement } from "./TitleElement";
import { TABLE_COLS } from "./constants";
import { CourseElement } from "./CourseElement";

export const BodyElement = ({
  row,
  chosenCourses,
  structure,
  previousDescription,
  previousLevel,
  level,
}) => {
  if (row.isTitle === true && structure) {
    if (row.description !== previousDescription) {
      previousLevel = level;
    }

    const structureItem = structure.find(
      (item) => item.name === row.description
    );
    // filter chosenCourses to only include courses that are in the same classification as the current row
    const filteredChosenCourses = chosenCourses
      ? chosenCourses.filter(
          (course) =>
            course.classification === row.description &&
            (/.*,1.0.$/.test(course.courseNumber) ||
              /^\d{5}$/.test(course.courseNumber))
        )
      : [];

    function calculateDiff() {
      const diff = level - previousLevel;
      if (previousLevel === 0) {
        return "py-1 mb-1";
      }

      if (diff <= -2) {
        return "pt-1 mt-5";
      } else if (diff === -1) {
        return "";
      } else if (diff === 1) {
        return "";
      } else {
        return "";
      }
    }

    let selectedChildCredits = 0;
    const addSelectedCredits = (row) => {
      if (row.items) {
        row.items.forEach((item) => {
          if (item.isTitle === true) {
            chosenCourses.forEach((course) => {
              if (course.classification === item.description) {
                selectedChildCredits += course.credits / 100;
                // gradeSum += parseFloat(course.mark);
                if (selectedChildCredits > parseFloat(row.maxCredits)) {
                  selectedChildCredits = parseFloat(row.maxCredits);
                }
              }
            });
          }
          addSelectedCredits(item);
        });
      }
    };

    addSelectedCredits(row);

    level = row.hierarchy.split(".").length - 3;
    previousDescription = row.description;

    function additionalCreditsString() {
      if (structureItem) {
        const sum =
          Math.max(structureItem.fullCredits, structureItem.totalCredits) -
          parseFloat(row.maxCredits);
        if (sum > 0) {
          return ` +${sum}`;
        }
      }
    }

    // console.log("xxx", filteredChosenCourses);
    return (
      <div className="flex flex-col text-sm">
        <div className={`flex ${rowStyle(level)} px-2 ${calculateDiff()}`}>
          <TitleElement parentStyle="w-12 text-center" />
          <TitleElement
            title={row.description}
            parentStyle={`flex-1 truncate pl-${level * 2} font-bold `}
          />
          <div
            className={`grid w-1/2 text-left items-center text-sm grid-cols-${TABLE_COLS}`}
          >
            <TitleElement span={3} parentStyle="px-4" />
            <TitleElement
              title=""
              parentStyle=""
              span={6}
              childStyle="grid grid-cols-5 items-right font-normal text-right items-center"
            >
              <TitleElement title={row.minCredits} parentStyle="text-xs" />
              <TitleElement title={row.maxCredits} parentStyle="text-xs" />
              <TitleElement
                title={structureItem ? structureItem.ECTS.toFixed(2) : ""}
                parentStyle="font-semibold text-right text-sm"
                span={2}
              />
              <TitleElement
                title={additionalCreditsString()}
                parentStyle="text-left"
                toolTip={`${additionalCreditsString()} ECTS too much.`}
              />
            </TitleElement>
            <TitleElement
              title={
                structureItem
                  ? structureItem.avg.toFixed(2) === "NaN"
                    ? ""
                    : isFinite(structureItem.avg.toFixed(2))
                    ? structureItem.avg.toFixed(2)
                    : ""
                  : ""
              }
              parentStyle="text-right"
              span={1}
            />
            {/* Custom grades */}
            <TitleElement
              title={
                structureItem
                  ? structureItem.customGrade.toFixed(2) === "NaN"
                    ? ""
                    : isFinite(structureItem.customGrade.toFixed(2))
                    ? structureItem.customGrade.toFixed(2)
                    : ""
                  : ""
              }
              parentStyle="text-right"
              span={1}
            />
          </div>
        </div>
        <div>
          {row.items &&
            row.items.map((item) => (
              <BodyElement
                row={item}
                chosenCourses={chosenCourses}
                structure={structure}
              />
            ))}
        </div>
        <div>
          {row.items &&
            row.items.map((item) => (
              <CourseElement
                row={item}
                level={level}
                previousLevel={previousLevel}
                previousDescription={previousDescription}
              />
            ))}
        </div>

        {filteredChosenCourses &&
          filteredChosenCourses.map((item) =>
            row.description === item.classification ? (
              <CourseElement
                row={item}
                level={level}
                previousLevel={previousLevel}
                previousDescription={previousDescription}
              />
            ) : null
          )}
      </div>
    );
  } else {
    return null;
  }
};

function rowStyle(level) {
  switch (level) {
    case 0:
      return "mb-2 bg-white py-2 text-base font-bold rounded";
    case 1:
      return "mb-1 bg-main py-1 text-sm font-semibold rounded bg-opacity-50 ";
    case 2:
      return "mb-1 bg-gray-300 text-sm py-1 rounded font-semibold";
    default:
      return "bg-gray-200 py-1 rounded text-sm font-semibold";
  }
}
