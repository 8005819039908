import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { MobileCalendarContainer } from "./mobileCalendar/MobileCalendarContainer";
import { MobileFocusEvent } from "./mobileFocusEvent/MobileFocusEvent";
import { Summary } from "../../leftCol/topRow/Summary/Summary";
import Pagination from "./Pagination";
import { MobileFilterContainer } from "./MobileFilterContainer/MobileFilterContainer";

const styles = {
  style: {
    height: "100%",
    width: "100%",
    flex: 1,
    paddingBottom: "12px",
  },
  containerStyle: {
    height: "100%",
    width: "100%",
  },
  slide: {
    height: "100%",
    width: "100%",
    WebkitOverflowScrolling: "touch",
  },
};

// Container for the swiping between summary, course details, and calendar
const MobileTop = ({
  selectedEventDates,
  mobileCalendarState,
  setMobileCalendarState,
  ToggleDetails,
  SelectEvent,
  selectedEvents,
  filterToggle,
  setFilterToggle,
  filterContainerFilterData,
  isToggled,
  toggle,
  focusEvent,
}) => {
  const [index, setIndex] = useState(1);

  return (
    <div className="relative flex flex-col w-full h-full overflow-hidden scrollbar-hide">
      <MobileFilterContainer
        filterToggle={filterToggle}
        setFilterToggle={setFilterToggle}
        filterContainerFilterData={filterContainerFilterData}
      />
      <SwipeableViews
        style={styles.style}
        containerStyle={styles.containerStyle}
        index={index}
        onChangeIndex={setIndex}
      >
        <div className="p-2" style={Object.assign({}, styles.slide, styles.slide1)}>
          <Summary selectedEvents={selectedEvents} />
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide2)}>
          <MobileFocusEvent
            toggle={toggle}
            isToggled={isToggled}
            focusEvent={focusEvent}
          />
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide3)}>
          <MobileCalendarContainer
            mobileCalendarState={mobileCalendarState}
            setMobileCalendarState={setMobileCalendarState}
            selectedEventDates={selectedEventDates}
            SelectEvent={SelectEvent}
            ToggleDetails={ToggleDetails}
          />
        </div>
      </SwipeableViews>
      <Pagination dots={3} index={index} onChangeIndex={setIndex} />
    </div>
  );
};

export default MobileTop;
