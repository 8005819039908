import React from "react";
import { FilterIcon } from "@heroicons/react/outline";

// Filter button with two states, indicating wether filters are set or not
export const EventListFilterButton = ({ setFilterToggle, filterActive }) => {
  return (
    <div>
      {filterActive ? (
        // Filter active
        <button
          id="filter_courses"
          className="flex items-center justify-center h-full ml-3 text-white rounded-lg shadow-sm lg:w-custom64 bg-hsg-700 hover:bg-hsg-600 active:bg-hsg-800 w-custom50"
          onClick={setFilterToggle}
        >
          <FilterIcon className="w-6 h-6" />
        </button>
      ) : (
        // No filter active
        <button
          id="filter_courses"
          className="flex items-center justify-center h-full ml-3 bg-white border-2 rounded-lg shadow-sm text-hsg-700 lg:w-custom64 border-hsg-700 hover:bg-hsg-700 hover:text-white active:bg-hsg-800 w-custom50"
          onClick={setFilterToggle}
        >
          <FilterIcon className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};
