import { selector } from "recoil";
import { filteredCoursesQuery } from "./filteredCourses";

export const sortedCoursesQuery = selector({
    key: 'sortedCourses',
    get: async ({ get }) => {
        const filteredCourses = get(filteredCoursesQuery)

        const sortedCourses = [...filteredCourses]
            .sort((a, b) => Number(b.selected) - Number(a.selected))
            .sort((a, b) => Number(b.allocated) - Number(a.allocated))
            .sort((a, b) => Number(b.overlapping) - Number(a.overlapping))

        return sortedCourses
    }
})