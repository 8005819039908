import React, { useState, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { saveCourse, deleteCourse } from "../../../../utils/data/apiData";

// Component for the event list on mobile
const MobileEventList = ({ filteredEvents, SelectEvent, ToggleDetails, accessToken }) => {
  const [sortedEvents, setSortedEvents] = useState();
  const [listLength, setListLength] = useState(100);

  // Row component that is used by react-window List
  // Each row is one course
  const Row = ({ index, style }) => {
    const event = sortedEvents[index];
    return (
      <div key={index} className="flex w-full pb-2" style={style}>
        <div
          onClick={() => ToggleDetails(event && event.id)}
          className={`flex-1 px-2 pt-0.5 rounded-lg shadow-sm overflow-hidden cursor-pointer hover:shadow-md transition duration-500 ease-in-out ${
            event && event.allocated
            ? "bg-neutral text-white font-medium"
            : event.selected
            ? event.overlapping
              ? "bg-warning text-white"
              : "bg-hsg-800 text-white"
            : "bg-white dark:bg-gray-200"
        }`}
        >
          <div className="">
            <p className="truncate">
              {event ? `${event.shortName}` : "Loading..."}
            </p>
          </div>
          <div
            className={` text-xs grid grid-cols-10  ${
              event ? (event.selected || event.allocated ? "text-white " : "text-gray-600") : ""
            }`}
          >
            <p className="col-span-3">
              {event ? `${event.courseNumber}` : "..."}
            </p>
            <p className="col-span-2 ">
              {event ? `${event.ects} ECTS` : "..."}
            </p>
            <p className="col-span-3 truncate md:col-span-5 xl:col-span-5">
              {event ? `${event.classification} ` : "..."}
            </p>
            {(event && event.avgRating !== null) ? <p className="col-span-2 truncate md:col-span-2 xl:col-span-2 flex">
              <StarIcon width={16} color="#386641" />
              {event.avgRating}
            </p> : <></>}
          </div>
        </div>
        <button
          id="select_course"
          onClick={() => { SelectEvent(event && event.id); event.selected ? saveCourse(event, accessToken) : deleteCourse(event, accessToken) }}
          className={`flex justify-center items-center h-full w-custom50 shadow-sm rounded-lg ml-3 cursor-pointer hover:shadow-md transition duration-500 ease-in-out ${
            event && event.allocated
            ? "bg-neutral text-white font-medium"
            : event.selected
            ? event.overlapping
              ? "bg-warning text-white"
              : "bg-hsg-800 text-white"
            : "bg-white dark:bg-gray-200"
        }`}
      >
        {event ? (
          event.allocated ? (
            "A"
          ) : event.selected ? (
            <MinusIcon className="w-6 h-6" />
          ) : (
            <PlusIcon className="w-6 h-6 text-gray-800" />
          )
        ) : (
          ""
        )}
        </button>
      </div>
    );
  };

  // Sorting courses to that overlapping > selected > rest
  useEffect(() => {
    if (typeof filteredEvents !== "undefined" && filteredEvents !== null) {
      const EventSort = filteredEvents
        .sort((a, b) => b.overlapping - a.overlapping)
        .sort((a, b) => b.selected - a.selected);
      setSortedEvents(EventSort);
      setListLength(EventSort.length);
    } else {
      setSortedEvents([]);
    }
  }, [filteredEvents, SelectEvent]);

  // List of courses with window & auto sized
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="overflow-hidden scrollbar-hide"
          height={height}
          itemCount={listLength}
          itemSize={55}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};
export default MobileEventList;
