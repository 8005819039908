import React, { useState, useRef } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";

// Button for AboutModal
export const AboutButton = () => {
  const [open, setOpen] = useState(false);

  function updateCookie() {
    const o = localStorage.getItem("aboutus") === "true" ? true : true;
    localStorage.setItem("aboutus", !o);
    setOpen(!open);
  }
  const cancelButtonRef = useRef(null);
  return (
    <>
      <button
        className="inline-flex items-center justify-center p-2 text-white rounded-md hover:bg-hsg-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:bg-hsg-800"
        onClick={(e) => updateCookie()}
      >
        <InformationCircleIcon className="block w-6 h-6" aria-hidden="true" />
      </button>
      {open ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity backdrop-filter backdrop-blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-center transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="m-4 text-lg font-medium leading-6 text-gray-900"
                        >
                          Honestly, thank you! 🤯
                        </Dialog.Title>
                        <div className="mt-2 text-gray-500 text-md">
                          <p>
                            When we came up with the idea for Biddit about a
                            year ago,{" "}
                            <a
                              className="text-hsg-700"
                              href="https://www.unisg.ch/en/wissen/newsroom/aktuell/rssnews/campus/2022/juni/biddit-9juni2022"
                              target="_blank"
                              rel="noreferrer"
                            >
                              we never expected it to blow up like this
                            </a>
                            . All we wanted was to learn some new skills and
                            hopefully build something useful along the way. So
                            ye, you can imagine our excitement, when half of you
                            decided to use it last semester.
                          </p>
                          <br />
                          <p>
                            However, with this came also some need for long-term
                            planning. Given that we won't be around forever, we
                            wanted to make sure that Biddit would be available,
                            free of charge and with the students needs in mind,
                            even after our graduations. We're convinced that
                            SHSG is exactly the right place for that. Here, we
                            can continue to grow and develop Biddit by building
                            new features and integrating it more closely with
                            both the SHSG and HSG ecosystem.
                          </p>
                          <br />
                          <p>
                            Which brings us ultimately back to you: Projects
                            like this live from the feedback and engagement of
                            the students. We can't count the amount of inputs
                            and ideas we got from you guys and it helped us
                            tremendously. Thank you for that! We're already
                            looking forward to hear what you think about the new
                            features we're building for next semester 👀
                          </p>
                          <p className="mt-2">
                            Anyway, this is already too long. Please feel free
                            to reach out!
                          </p>
                          <p className="mt-2">Lot's of love 💚</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full pt-4 text-center text-md">
                      <a
                        className="text-hsg-700"
                        href="https://www.linkedin.com/in/marc-robin-gruener/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Marc
                      </a>{" "}
                      &{" "}
                      <a
                        className="text-hsg-700"
                        href="https://www.linkedin.com/in/michabrugger/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Micha
                      </a>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </>
  );
};
