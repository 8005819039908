import { atom, selector } from "recoil";
import axios from "axios";

import { tokenQuery } from "../../Token";

export const customGradesState = atom({
  key: "customGrades",
  default: selector({
    key: "customGrades/Default",

    get: async ({ get }) => {
      const token = get(tokenQuery);
      console.debug("loading custom grades");
      try {
        const res = await axios.get(`https://api.shsg.ch/course-grades`, {
          headers: {
            "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
            "X-RequestedLanguage": "EN",
            "API-Version": "1",
            Authorization: `Bearer ${token}`,
          },
        });
        return res.data;
      } catch (err) {
        try {
          const res = await axios.get(`https://api.shsg.ch/course-grades`, {
            headers: {
              "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
              "X-RequestedLanguage": "EN",
              "API-Version": "1",
              Authorization: `Bearer ${token}`,
            },
          });
          return res.data;
        } catch (err) {
          return console.log(err);
        }
      }
    },
  }),
});

export async function AddCourseGrade(token, courseNumber, grade) {
  try {
    const res = await axios.post(
      `https://api.shsg.ch/course-grades`,
      {
        courseNumber: courseNumber,
        grade: grade,
      },
      {
        headers: {
          "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
          "X-RequestedLanguage": "EN",
          "API-Version": "1",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    try {
      const res = await axios.post(
        `https://api.shsg.ch/course-grades`,
        {
          courseNumber: courseNumber,
          grade: grade,
        },
        {
          headers: {
            "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
            "X-RequestedLanguage": "EN",
            "API-Version": "1",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
}

export async function RemoveCourseGrade(token, courseNumber) {
  try {
    const res = await axios.delete(
      `https://api.shsg.ch/course-grades/${courseNumber}`,
      {
        headers: {
          "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
          "X-RequestedLanguage": "EN",
          "API-Version": "1",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (err) {
    try {
      const res = await axios.delete(
        `https://api.shsg.ch/course-grades/${courseNumber}`,
        {
          headers: {
            "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
            "X-RequestedLanguage": "EN",
            "API-Version": "1",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
}
