import React from "react";
import Select from "react-select";
import { useRecoilState, useRecoilValue } from "recoil";
import { relevantCisIdsQuery } from "../../../recoil/selectors/hsg/relevantCisIds";
import { selectedSemesterState } from "../../../recoil/atoms/general/selectedSemester";

export function SelectSemester() {
  let options = useRecoilValue(relevantCisIdsQuery);

  options = [
    ...options,
    // {
    //     label: "HS 2023",
    //     date: "2022-09-19T00:00:00",
    //     termId: "HS 23 - Placeholder",
    //     cisTermId: "HS 23 - Placeholder",
    //     value: "Fall 2023 Placeholder",
    // },
    // {
    //   label: "FS 2024",
    //   date: "2022-09-19T00:00:00",
    //   termId: "FS 24 - Placeholder",
    //   cisTermId: "FS 24 - Placeholder",
    //   value: "Spring 2024 Placeholder",
    // },
    // {
    //   label: "HS 2024",
    //   date: "2022-09-19T00:00:00",
    //   termId: "HS 24 - Placeholder",
    //   cisTermId: "HS 24 - Placeholder",
    //   value: "Fall 2024 Placeholder",
    // },
    {
      label: "FS 2025",
      date: "2022-09-19T00:00:00",
      termId: "FS 25 - Placeholder",
      cisTermId: "FS 25 - Placeholder",
      value: "Spring 2025 Placeholder",
    },
    {
      label: "HS 2025",
      date: "2022-09-19T00:00:00",
      termId: "HS 25 - Placeholder",
      cisTermId: "HS 25 - Placeholder",
      value: "Fall 2025 Placeholder",
    },
    {
      label: "FS 2026",
      date: "2022-09-19T00:00:00",
      termId: "FS 26 - Placeholder",
      cisTermId: "FS 26 - Placeholder",
      value: "Spring 2026 Placeholder",
    },
    {
      label: "HS 2026",
      date: "2022-09-19T00:00:00",
      termId: "HS 26 - Placeholder",
      cisTermId: "HS 26 - Placeholder",
      value: "Fall 2026 Placeholder",
    }
  ];

  // options = options.filter(option => option.label !== "Herbstsemester 2022")
  // console.log("OPTIONS: ", options)

  const [selectedSemester, setSelectedSemester] = useRecoilState(
    selectedSemesterState
  );

  // //the options should be filtered by the selected program
  // const [selectedOption, setSelectedOption] = useState(programOptions[0]);

  function bidditTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#EBFFF2",
        primary: "#009938",
      },
    };
  }

  return (
    <div className="w-full text-sm text-gray-700 App">
      {/* <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={programOptions}
      /> */}
      {/* GGF SOLLTE selectedCourse HIER DANN AUCH ZURÜCK GESETZT WERDEN AUF DEFAULT VALUE {} */}

      <Select
        defaultValue={selectedSemester}
        onChange={setSelectedSemester}
        options={options}
        className="w-full text-sm text-gray-700"
        theme={(theme) => bidditTheme(theme)}
      />
      {/* if selectedSemester.value contains "Placeholder" then show the following: */}

      {selectedSemester.value.includes("Placeholder") && (
        <div className="w-full px-1 py-2 text-sm text-left text-gray-700">
          Disclaimer: The course data for your currently selected
          semester is not yet confirmed and may not be accurate. We
          display it as a preview for you to be able to plan ahead. We
          will update the data as soon as HSG releases it.
        </div>
      )}

      {/* <div className="grid w-full grid-flow-col ">
        <button></button>
        <button>2</button>
        <button>3</button>
        <button>4</button>
        <button>5</button>
      </div> */}
    </div>
  );
}
