import { atom, selector } from "recoil";
import axios from "axios";

import { tokenQuery } from "../../Token";
import { courseInformationSheetsState } from "../hsg/courseInformationSheets";
import {
    AddCoursesToStudyPlan,
    RemoveCoursesFromStudyPlan,
} from "./userStudyPlan";

export const userStudyPlanMigrationState = atom({
    key: "userStudyPlanMigration",
    default: selector({
        key: "userStudyPlanMigration/Default",
        get: async ({ get }) => {
            const token = get(tokenQuery);
            const courses = get(courseInformationSheetsState);

            const cleanedCourses = {};
            courses.forEach((semester) => {
                cleanedCourses[semester.id] = semester.data
                    .map((parentCourse) => {
                        return parentCourse.courses.map((childCourse) => {
                            return childCourse;
                        });
                    })
                    .flat(1);
            });

            console.log("MIGRATION", cleanedCourses);

            try {
                const res = await axios.get(`https://api.shsg.ch/study-plans`, {
                    headers: {
                        "X-ApplicationId":
                            "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (Object.keys(res.data).length === 0) {
                    const queryParams = new URLSearchParams(
                        window.location.search
                    ).get("courses");
                    if (queryParams) {
                        try {
                            const res = await axios.post(
                                `https://api.shsg.ch/study-plans`,
                                {
                                    semesters: {
                                        "d33babff-17c7-4997-b35b-a5348a2cb7bd":
                                            queryParams.split(","),
                                    },
                                },
                                {
                                    headers: {
                                        "X-ApplicationId":
                                            "820e077d-4c13-45b8-b092-4599d78d45ec",
                                        "X-RequestedLanguage": "EN",
                                        "API-Version": "1",
                                        Authorization: `Bearer ${token}`,
                                    },
                                }
                            );
                            return res.data;
                        } catch (err) {
                            try {
                                const res = await axios.post(
                                    `https://api.shsg.ch/study-plans`,
                                    {
                                        semesters: {
                                            "d33babff-17c7-4997-b35b-a5348a2cb7bd":
                                                queryParams.split(","),
                                        },
                                    },
                                    {
                                        headers: {
                                            "X-ApplicationId":
                                                "820e077d-4c13-45b8-b092-4599d78d45ec",
                                            "X-RequestedLanguage": "EN",
                                            "API-Version": "1",
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                );
                                return res.data;
                            } catch (err) {
                                console.log("Error in migration function getting study plan")
                                return { updatedStudyPlan: {}, coursesNotMigrated: {} };
                            }
                        }
                    }
                }
                return await migratePlaceholderSemesters(
                    res.data,
                    cleanedCourses,
                    token
                );
            } catch (err) {
                return console.log(err);
            }
        },
    }),
});

export async function migratePlaceholderSemesters(
    studyPlan,
    cleanedCourses,
    token
) {
    if (studyPlan) {
        const placeholderSemestersToMigrate = {
            "HS 23 - Placeholder": "74db9567-f466-44c3-a9d5-04cbecb0d084",
        };

        const placeholderSemesterDataOrigin = {
            "HS 23 - Placeholder": "d33babff-17c7-4997-b35b-a5348a2cb7bd",
        };

        const placeholderSemestersToMigrateIds = Object.keys(
            placeholderSemestersToMigrate
        );
        const studyPlanSemesterIds = Object.keys(studyPlan);

        const coursesDict = Object.fromEntries(
            Object.entries(cleanedCourses).filter(
                ([key]) => !key.includes("Placeholder")
            )
        );

        const courseNameByCourseNumber = {};
        Object.keys(cleanedCourses).forEach((semester) => {
            cleanedCourses[semester].forEach((course) => {
                courseNameByCourseNumber[course.courseNumber] =
                    course.shortName;
            });
        });

        const updatedStudyPlan = studyPlan;

        const coursesNotMigrated = {};

        placeholderSemestersToMigrateIds.forEach((semester) => {
            if (studyPlanSemesterIds.includes(semester)) {
                const coursesToMigrate = [];

                const placeholderSemesterId = placeholderSemestersToMigrate[semester]; // check if any placeholder semesters available
                if (coursesDict[placeholderSemesterId]) { // if no placeholder semesters available, skip
                    const courseNumbersForSemester = coursesDict[
                        placeholderSemestersToMigrate[semester]
                        ].map((course) => {
                    return course.courseNumber;
                });

                studyPlan[semester].forEach((oldCourseId) => {
                    if (oldCourseId.includes("PLACEHOLDER")) {
                        if (
                            updatedStudyPlan[
                                placeholderSemestersToMigrate[semester]
                            ]
                        ) {
                            updatedStudyPlan[
                                placeholderSemestersToMigrate[semester]
                            ].push(oldCourseId);
                        } else {
                            updatedStudyPlan[
                                placeholderSemestersToMigrate[semester]
                            ] = [oldCourseId];
                        }
                        coursesToMigrate.push(oldCourseId);
                    } else {
                        const originalSemesterId = placeholderSemesterDataOrigin[semester]; // potential fix for S.Peter
                        if (coursesDict[originalSemesterId]) { // only to if original semester is available
                            const courseNumber = coursesDict[
                                placeholderSemesterDataOrigin[semester]
                            ].filter((x) => x.id === oldCourseId)[0]?.courseNumber; // MB: This seems to cause the issue

                        
                        if (courseNumbersForSemester.includes(courseNumber)) {
                            const newCourseId = cleanedCourses[
                                placeholderSemestersToMigrate[semester]
                            ].filter((x) => x.courseNumber === courseNumber)[0]
                                .id;
                            if (
                                updatedStudyPlan[
                                    placeholderSemestersToMigrate[semester]
                                ]
                            ) {
                                updatedStudyPlan[
                                    placeholderSemestersToMigrate[semester]
                                ].push(newCourseId);
                            } else {
                                updatedStudyPlan[
                                    placeholderSemestersToMigrate[semester]
                                ] = [newCourseId];
                            }
                            coursesToMigrate.push(newCourseId);
                        } else if (coursesNotMigrated[semester]) {
                            coursesNotMigrated[semester].push(
                                courseNameByCourseNumber[courseNumber]
                            );
                        } else {
                            coursesNotMigrated[semester] = [
                                courseNameByCourseNumber[courseNumber],
                            ];
                        }
                        } else { // Potential fix for S.Peter Issue
                            console.error(`No data found in coursesDict for originalSemesterId: ${originalSemesterId}`);
                        }
                    }
                });
                AddCoursesToStudyPlan(
                    token,
                    placeholderSemestersToMigrate[semester],
                    coursesToMigrate
                );
                RemoveCoursesFromStudyPlan(token, semester);
            }
            delete updatedStudyPlan[semester];
            }
        });
        return [updatedStudyPlan, coursesNotMigrated];
    }
}