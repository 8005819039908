// Dependencies
import React from "react";
import ReactTooltip from "react-tooltip";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

// Other
import "./mobileCalendar.css";
import "../../../rightCol/calendar/fixedEvents.json";

// Calendar on mobile
export default function MobileCalendar({ selectedEventDates, ToggleDetails }) {
	const fixedEvents =
		require("../../../rightCol/calendar/fixedEvents.json").flat();
	const clickEvent = function (info) {
		if (info.event._def.extendedProps.selected === true) {
			ToggleDetails(info.event._def.extendedProps.courseId);
		}
	};
	const finalEvents = [...selectedEventDates, ...fixedEvents];

	// Hovering/clicking info
	const hoverEvent = (info) => {
		let text2 = info.event.title;
		info.el.setAttribute("data-tip", `${text2}`);
		ReactTooltip.rebuild();
	};

	// Data to be shown when clicking on event
	function renderEventContent(eventInfo) {
		return (
			<>
				{" "}
				<p className="truncate cal-time">{eventInfo.timeText}</p>
				<p className="text-xs font-bold truncate ">{eventInfo.event.title}</p>
			</>
		);
	}

	// Forward & backward button for calendar
	const calendarRef = React.useRef();
	const WeekChange = (value) => {
		let calendarApi = calendarRef.current.getApi();
		value === "next" ? calendarApi.next() : calendarApi.prev();
	};

	// calendar styling
	var cal = {
		firstDay: "1",
		dayHeaderFormat: {
			month: "short",
			day: "numeric",
		},
		eventColor: "#386641",
		slotMinutes: 60,
		duration: "00:60:00",
	};

	return (
		<div className="w-full h-full">
			<ReactTooltip />
			<div className="flex w-full h-full">
				{/* week backward button */}
				<div
					className="flex items-center pr-1 ease-in-out rounded-lg cursor-pointer md:px-2 mt-7 focus-within hover:bg-gray-200 active:bg-gray-300"
					onClick={() => WeekChange("prev")}
				>
					<ChevronLeftIcon
						aria-hidden="true"
						className="w-5 h-full text-gray-500 align-middle "
					/>
				</div>

				{/* calendar */}
				<div className="flex-1 w-full h-full">
					<FullCalendar
						ref={calendarRef}
						plugins={[timeGridPlugin]}
						initialView="timeGridWeek"
						height="100%"
						width="100%"
						events={finalEvents}
						firstDay={cal.firstDay}
						slotMinTime="08:00:00"
						slotMaxTime="22:00:00"
						hiddenDays="[0,6]"
						eventColor="#386641"
						expandRows={true}
						slotEventOverlap={false}
						eventClick={clickEvent}
						eventContent={renderEventContent}
						eventMouseEnter={hoverEvent}
						slotMinutes={cal.slotMinutes}
						slotDuration={cal.duration}
						allDaySlot={false}
						headerToolbar={false}
						footerToolbar={false}
						slotLabelFormat={cal.eventTimeFormat}
						slotLabelInterval={cal.slotLabelInterval}
						dayHeaderFormat={cal.dayHeaderFormat}
					/>
				</div>

				{/* week forward button */}
				<div
					className="flex items-center pl-1 ease-in-out rounded-lg mt-7 focus-within hover:bg-gray-200 active:bg-gray-300 md:px-2"
					onClick={() => WeekChange("next")}
				>
					<ChevronRightIcon
						aria-hidden="true"
						className="w-5 h-full text-gray-500 align-middle"
					/>
				</div>
			</div>
		</div>
	);
}
