import { selector } from "recoil";
import axios from "axios";
import { selectedCourseState } from "../general/selectedCourse";
import { tokenQuery } from "../../Token";

export const courseRatingDetailQuery = selector({
    key: 'courseDetailRating/Default',
    get: async ({ get }) => {
        const token = get(tokenQuery)
        const selectedCourse = get(selectedCourseState)

        try {
            const res = await axios.get(`https://api.shsg.ch/course-ratings/by-course/${selectedCourse.courseNumber}`, {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            });
            res.data = res.data.filter((course) => course.courseNumber !== '7,721,1.00')

            if (res.data.length > 0) {
                const ratingData = res.data[0]

                return { ...selectedCourse, avgRatings: ratingData.avgRatings, nbOfRatings: ratingData.nbOfRatings, comments: ratingData.comments }
            }

            return selectedCourse
        } catch (err) {
            try {
                const res = await axios.get(`https://api.shsg.ch/course-ratings/by-course/${selectedCourse.courseNumber}`, {
                    headers: {
                        "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                });
                res.data = res.data.filter((course) => course.courseNumber !== '7,721,1.00')

                if (res.data.length > 0) {
                    const ratingData = res.data[0]

                    return { ...selectedCourse, avgRatings: ratingData.avgRatings, nbOfRatings: ratingData.nbOfRatings, comments: ratingData.comments }
                }

                return selectedCourse
            } catch (err) {
                return console.log(err)
            }
        }
    }
})