import React from 'react';
import PropTypes from 'prop-types';

const styles = {
	root: {
		height: 12,
		width: 18,
		cursor: 'pointer',
	},
	dot: {
		backgroundColor: '#9CA3AF',
		height: 8,
		width: 8,
		borderRadius: "100%",
		margin: 'auto',
	},
	active: {
		backgroundColor: '#386641'
	}
};

// Dot indicators for swiping
class PaginationDot extends React.Component {
	handleClick = event => {
		this.props.onClick(event, this.props.index);
	};

	render() {
		const { active } = this.props;

		let styleDot;

		if (active) {
			styleDot = Object.assign({}, styles.dot, styles.active);
		} else {
			styleDot = styles.dot;
		}

		return (
			<button
				type="button"
				style={styles.root}
				onClick={this.handleClick}
			>
				<div style={styleDot} />
			</button>
		);
	}
}

PaginationDot.propTypes = {
	active: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired
};

export default PaginationDot;
