import { selector } from "recoil";
import axios from "axios";
import { selectedCourseState } from "../../atoms/general/selectedCourse";
import { tokenQuery } from "../../Token";

export const courseExamInformationQuery = selector({
    key: 'courseExamInformation/Default',
    get: async ({ get }) => {
        const token = get(tokenQuery)
        const selectedCourse = get(selectedCourseState)

        try {
            const res = await axios.get(`https://integration.unisg.ch/EventApi/CourseInformationSheets/latestPublishedByHsgEntityId/${selectedCourse.hsgEntityId}`, {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "3",
                    Authorization: `Bearer ${token}`,
                },
            });

            return res.data
        } catch (err) {
            try {
                const res = await axios.get(`https://integration.unisg.ch/EventApi/CourseInformationSheets/latestPublishedByHsgEntityId/${selectedCourse.courseNumber}`, {
                    headers: {
                        "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "3",
                        Authorization: `Bearer ${token}`,
                    },
                });
                return res.data
            } catch (err) {
                return console.log(err)
            }
        }
    }
})