import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import {
  AddCourseToStudyPlan,
  RemoveCourseFromStudyPlan,
  userStudyPlanState,
} from "../shsg_studyplan/userStudyPlan";
import { tokenQuery } from "../../Token";
import { RemoveCourseGrade } from "../custom_grades/customGrades";

export const localSelectedCoursesState = atom({
  key: "localSelectedCourses",
  default: selector({
    key: "localSelectedCourses/Default",
    get: async ({ get }) => {
      const defaultValues = {
        // "df38c9b2-4db7-4389-8485-20fdb4318f40": [],
        "d33babff-17c7-4997-b35b-a5348a2cb7bd": [],
        "74db9567-f466-44c3-a9d5-04cbecb0d084": [],
        "2dce7d87-6653-4c17-8b74-96371edbf894": [],
        "HS 24 - Placeholder": [],
        "FS 25 - Placeholder": [],
        "HS 25 - Placeholder": [],
        "FS 26 - Placeholder": [],
      };
      return { ...defaultValues, ...get(userStudyPlanState) };
    },
  }),
});

export const useLocalSelectedCourses = () => {
  const [localSelectedCourses, setLocalSelectedCourses] = useRecoilState(
    localSelectedCoursesState
  );

  const token = useRecoilValue(tokenQuery);

  function updateLocalSelectedCourses(event) {
    if (!event.selected) {
      AddCourseToStudyPlan(token, event.semesterId, event.id);
    } else {
      RemoveCourseFromStudyPlan(token, event.semesterId, event.id);
      RemoveCourseGrade(token, event.courseNumber)
    }

    if (localSelectedCourses[event.semesterId].includes(event.id)) {
      setLocalSelectedCourses({
        ...localSelectedCourses,
        [event.semesterId]: [
          ...localSelectedCourses[event.semesterId].filter(
            (id) => id !== event.id
          ),
        ],
      });
    } else {
      setLocalSelectedCourses({
        ...localSelectedCourses,
        [event.semesterId]: [
          ...localSelectedCourses[event.semesterId],
          event.id,
        ],
      });
    }
  }
  return updateLocalSelectedCourses;
};
