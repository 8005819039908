import React, { useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { RecoilRoot } from "recoil";
import { ShieldComponent } from "../newBiddit/utils/ShieldComponent";

// re-used
import LoginStatus from "../utils/auth/LoginStatus";

export default function NewBiddit() {
  const [cookieState, setCookieState] = useState(true);

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="flex h-full">
        <LoginStatus
          cookieState={cookieState}
          setCookieState={setCookieState}
        />
        <AuthenticatedTemplate>
          <RecoilRoot>
            <ShieldComponent setCookieState={setCookieState} />
          </RecoilRoot>
        </AuthenticatedTemplate>
      </div>
    </div>
  );
}
