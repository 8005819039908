import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";

export const RatingModal = () => {
  const [open, setOpen] = useState(
    localStorage.getItem("ratingannouncement") === "false" ? false : true
  );

  function updateCookie() {
    localStorage.setItem("ratingannouncement", false);
    setOpen(false);
  }

  const cancelButtonRef = useRef(null);
  return (
    <div>
      {open ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity backdrop-filter backdrop-blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Biddit by{" "}
                          <a
                            className="text-hsg-700"
                            href="https://shsg.ch"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SHSG
                          </a>
                          {" "}just got better 🥳
                        </Dialog.Title>
                        <div className="mt-2 text-sm text-gray-500">
                          <p>
                            We are excited to announce the first new feature:
                          </p>
                          <p className="mt-2">
                            Course Ratings - by students for students ⭐️
                          </p>
                          <p className="mt-2">
                            Share your thought on courses you took in the past and see what others think about the ones you want to take.
                          </p>
                          <p className="mt-2">
                            We just ask everyone to be respectful in the ratings.
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500 mx-auto mt-4"
                      onClick={() => updateCookie()}
                      ref={cancelButtonRef}
                    >
                      Check it out
                      <ArrowRightIcon className="ml-2 h-6 w-6" />
                    </button>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <></>
      )}
    </div>
  );
};
