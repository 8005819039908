import { atom, selector } from "recoil";
import axios from "axios";

import { tokenQuery } from "../../Token";

export const examinationIdsState = atom({
  key: "examinationIds",
  default: selector({
    key: "examinationIds/Default",
    get: async ({ get }) => {
      const token = get(tokenQuery);

      try {
        const res = await axios.get(
          `https://integration.unisg.ch/AcametaApi/ExaminationTypes?fields=id,shortName,description`,
          {
            headers: {
              "X-ApplicationId":
                "820e077d-4c13-45b8-b092-4599d78d45ec",
              "X-RequestedLanguage": "EN",
              "API-Version": "1",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        const examinationIdObject = {};
        res.data.forEach((examinationId) => {
            examinationIdObject[examinationId.id] = {
                'shortName': examinationId.shortName,
                'description': examinationId.description,
            }
        });

        return examinationIdObject;
      } catch (err) {
        try {
          const res = await axios.get(
            `https://integration.unisg.ch/AcametaApi/ExaminationTypes?fields=id,shortName,description`,
            {
              headers: {
                "X-ApplicationId":
                  "820e077d-4c13-45b8-b092-4599d78d45ec",
                "X-RequestedLanguage": "EN",
                "API-Version": "1",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const examinationIdObject = {};
          res.data.forEach((examinationId) => {
              examinationIdObject[examinationId.id] = {
                  'shortName': examinationId.shortName,
                  'description': examinationId.description,
              }
          });
  
          return examinationIdObject;
        } catch (err) {
          return console.log(err);
        }
      }
    },
  }),
});
