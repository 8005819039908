import { atom, selector } from "recoil";
import { userStudyPlanMigrationState } from "./userStudyPlanMigration";

export const coursesNotMigratedState = atom({
    key: "coursesNotMigrated",
    default: selector({
        key: "coursesNotMigrated/Default",
        get: async ({ get }) => {
            return await get(userStudyPlanMigrationState)[1];
        },
    }),
});
