import React from "react";
import { EventList } from "./eventList";
import { Inputs } from "./inputs";
import { Placeholder } from "./placeholder";

export function EventListContainer() {
  return (
    <div className="flex flex-col w-full h-full">
      <Inputs />
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-full h-full mb-4 text-xs text-center text-gray-600 align-middle bg-gray-200 rounded animate-pulse">
            Loading your courses from unisg.ch...
          </div>
        }
      >
        <Placeholder />
        <EventList />
      </React.Suspense>
    </div>
  );
}
