// Dependencies
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import history from "../utils/other/History";

// Components
import Biddit from "../pages/Biddit";
import NewBiddit from "../pages/NewBiddit";
import { useState } from "react";
import { useEffect } from "react";

// Routing for App
function App() {
  const [mobile, setMobile] = useState()

  // detecting vertical phone or small screens at initial render. Alternative would be do have responsive rendering using tailwind breakpoints and block/hidden for one or the other. This way it would change rendering dynamically and not only upon re-load
  useEffect(() => {
    // setMobile(window.screen.width < 640)
    setMobile(window.innerHeight > window.innerWidth || window.innerWidth < 640)
  }, [])

  // This here is important to get routes working on netlify:
  // https://answers.netlify.com/t/netlify-page-not-found-when-sharing-react-router-dom-based-links/11744/5
  
  return (
    <Router history={history}>
      <div className="bg-gray-100">
        <Switch>
          {/* <Route path={["/home", "/", ""]} exact component={NewBiddit} />
          <Route path={"/legacy"} exact component={Biddit} /> */}
          <Route path={"/legacy"} exact component={Biddit} />
          {mobile ? <Route path={["/home", "/", ""]} exact component={Biddit} /> : <Route path={["/home", "/", ""]} exact component={NewBiddit} /> }
        </Switch>
      </div>
    </Router>
  );
}

export default App;
