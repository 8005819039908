import React, { useState } from "react";
import { CreditsByClass } from "../../../../utils/summary/CreditsByClass";
import { ExamsByClassification } from "../../../../utils/summary/ExamsByClassification";
import { TutorialComponent } from "../../../../utils/tutorial/TutorialComponent";
import { AllocatedCourses } from "../../../../utils/summary/AllocatedCourses";

// Tabs for summary Overview/Credits/Exam
export const Summary = ({ selectedEvents, myCourses }) => {
  const [activeTab, setActiveTab] = useState("credits");

  // Content of summary (coming from utils/summary/.), dependent on switch
  function renderSwitch(activeTab) {
    switch (activeTab) {
      case "credits":
        return <CreditsByClass selectedEvents={selectedEvents} />;
      case "exams":
        return <ExamsByClassification selectedEvents={selectedEvents} />;
      case "course-overview":
        return <AllocatedCourses selectedEvents={selectedEvents} />;
      default:
        console.debug("error with switch");
    }
  }

  // Checking if summary tutorial has beed closed before
  const [open, setOpen] = useState(localStorage.getItem("summarytutorial")==="false" ? false : true);

  // Updating cookies after summary was opened
  function updateCookie() {
    localStorage.setItem("summarytutorial", false)
    setOpen(false)
  }

  // Showing summaries dependent on switch
  return (
    <div className="relative h-full p-4 bg-white rounded-lg shadow-sm">
      {/* Tutorial conditionally rendered */}
      {open ? 
      <div className="absolute z-10 w-full h-full -m-4">
        <TutorialComponent open={open} setOpen={updateCookie} text={"Breakdown of the credits and exams based on the courses you selected."}/>
      </div> : <></> }
      
      {/* Tab buttons */}
      <div className="flex flex-col flex-1 h-full mx-4">
        <div className="flex-none pb-2">
          <nav className="flex space-x-4" aria-label="Tabs">
            <div
              onClick={() => setActiveTab("course-overview")}
              className={`cursor-pointer px-3 py-2 font-medium text-sm rounded-md ${
                activeTab === "course-overview"
                  ? `bg-hsg-50 text-hsg-700`
                  : `text-gray-500 hover:text-gray-700`
              }`}
            >
              Overview
            </div>
            <div
              onClick={() => setActiveTab("credits")}
              className={`cursor-pointer px-3 py-2 font-medium text-sm rounded-md ${
                activeTab === "credits"
                  ? `bg-hsg-50 text-hsg-700`
                  : `text-gray-500 hover:text-gray-700`
              }`}
            >
              Credits
            </div>
            <div
              onClick={() => setActiveTab("exams")}
              className={`cursor-pointer px-3 py-2 font-medium text-sm rounded-md ${
                activeTab === "exams"
                  ? `bg-hsg-50 text-hsg-700`
                  : `text-gray-500 hover:text-gray-700`
              }`}
            >
              Exams
            </div>
          </nav>
        </div>

        {renderSwitch(activeTab)}
      </div>
    </div>
  );
};