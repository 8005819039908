import { useLocalSelectedCourses } from "../../../recoil/atoms/general/localSelectedCourses";
// import Tooltip from "./Tooltip";

export const LockOpen = ({ clg, event }) => {
  const setLocalSelectedCourses = useLocalSelectedCourses();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={
        clg + " hover:text-red-600 transition duration-500 ease-in-out"
      }
      onMouseDown={(e) => {
        e.preventDefault();
        event ? setLocalSelectedCourses(event) : console.log("no event");
      }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
      />
    </svg>
  );
};
