import { TitleElement } from "./TitleElement";
import { TABLE_COLS, TABLE_GAP } from "./constants";

export const HeaderElement = () => {
  return (
    <div className="flex flex-row px-2 pt-1 bg-gray-200 rounded">
      <TitleElement title="" parentStyle="w-12 text-center" span={2} />
      <TitleElement title="" parentStyle="flex-1 pb-4" />
      <div
        className={`grid w-1/2 pb-2 text-sm text-center grid-cols-${TABLE_COLS} ${TABLE_GAP} font-semibold`}
      >
        <TitleElement span={1} title="" />
        <TitleElement span={2} title="" />

        <TitleElement
          title="Credits"
          parentStyle="font-semibold"
          span={6}
          childStyle="grid grid-cols-5 text-xs text-right font-normal pt-2"
        >
          <div>Min.</div>
          <div>Max.</div>
          <TitleElement
            title="Earned"
            parentStyle="text-right font-semibold"
            toolTip="All credits earned."
            span={2}
          />
          <div />
        </TitleElement>
        <TitleElement
          title="Grade"
          parentStyle="text-center"
          span={2}
          childStyle="grid grid-cols-2 text-xs text-center font-normal pt-2"
        >
          <div className="text-right">Real</div>
          <TitleElement
            title="Wish"
            parentStyle="text-right"
            toolTip="Curve pls 🙏"
            span={1}
          />
        </TitleElement>
      </div>
    </div>
  );
};
