import { useRecoilState, useRecoilValue } from "recoil";
import { classificationsFilterOptionsState } from "../../../../recoil/atoms/general/classificationsFilterOptions";
import {
  AddCourseToStudyPlan,
  customPlaceholderCourseState,
  placeholderFormValidQuery,
} from "../../../../recoil/atoms/shsg_studyplan/userStudyPlan";
import { tokenQuery } from "../../../../recoil/Token";
import { selectedSemesterState } from "../../../../recoil/atoms/general/selectedSemester";
import { useLocalSelectedCourses } from "../../../../recoil/atoms/general/localSelectedCourses";
import { PlusIcon } from "@heroicons/react/outline";
import { React } from "react";
import { AddCourseGrade } from "../../../../recoil/atoms/custom_grades/customGrades";

export function Placeholder() {
  // const classificationOptions = useRecoilValue(classificationsFilterOptionsState)

  const token = useRecoilValue(tokenQuery);
  const selectedSemester = useRecoilValue(selectedSemesterState);

  const setLocalSelectedCourses = useLocalSelectedCourses();

  const classificationsFilterOptions = useRecoilValue(
    classificationsFilterOptionsState
  );
  // const classificationFilterSelection = useRecoilValue(classificationsFilterSelectionState)
  // function setClassificationsFilterSelection() {

  // }

  const thesisRe = /thesis/;
  const finalClassificationsFilterOptions = classificationsFilterOptions.map(
    (element) => {
      if (element.label.toLowerCase().match(thesisRe)) {
        return {
          label: element.label,
          options: [
            ...element.options,
            { value: element.label, label: element.label },
          ],
        };
      }
      return element;
    }
  );

  const [customPlaceholderCourse, setCustomPlaceholderCourse] = useRecoilState(
    customPlaceholderCourseState
  );
  const placeholderFormValid = useRecoilValue(placeholderFormValidQuery);
  // const [customPlaceholderGrade, setCustomPlaceholderGrade] = React.useState(5);

  // const useLocalSelectedCourses =

  function sendPlaceholder() {
    const courseId =
      "PLACEHOLDER;" +
      customPlaceholderCourse.courseName.replace(";", " ") +
      ";" +
      customPlaceholderCourse.classification +
      ";" +
      customPlaceholderCourse.ects +
      ";" +
      customPlaceholderCourse.randId;

    AddCourseToStudyPlan(token, selectedSemester.cisTermId, courseId);
    AddCourseGrade(
      token,
      customPlaceholderCourse.randId,
      customPlaceholderCourse.grade
    );

    setLocalSelectedCourses({
      calendarEntry: [],
      classification: customPlaceholderCourse.classification,
      achievementContent: "",
      achievementLiterature: "",
      achievementParts: [],
      comment: null,
      courseAdditionalInformation: null,
      courseContent: "",
      courseLanguage: {
        code: "EN",
        id: 2,
        shortName: "English",
      },
      courses: [
        {
          courseLanguage: {
            code: "EN",
            id: 2,
            shortName: "English",
          },
          courseNumber: customPlaceholderCourse.randId,
          eventSkeletonKeyId: 7060,
          lecturers: [],
          timeTableLink: "",
          id: "",
          shortName: customPlaceholderCourse.courseName.replace(";", " "),
        },
      ],
      courseStructure: "",
      credits: customPlaceholderCourse.ects * 100,
      learningObjectives: "",
      specialInformation: null,
      achievementFormStatus: {
        isCentral: false,
        isDeCentral: true,
        isRegulated: false,
        description: "Free Decentral",
        id: "88d032b7-f64f-486b-8a31-30ba2f41fd76",
        shortName: "F/D",
      },
      courseNumber: customPlaceholderCourse.randId,
      hasPublishedVersion: true,
      semesterId: selectedSemester.cisTermId,
      description: null,
      id: courseId,
      shortName: customPlaceholderCourse.courseName.replace(";", " "),
      lecturers: [],
      selected: false,
      allocated: false,
      overlapping: false,
    });

    setCustomPlaceholderCourse({
      courseName: "",
      classification: "",
      ects: 0,
      randId: Math.floor(Math.random() * 100000),
      grade: "",
    });
  }

  return (
    <div className="flex overflow-visible pb-2.5 ">
      {/* <div>PLACEHOLDER COURSE</div> */}
      <div className="flex flex-col w-full border border-white rounded-lg shadow-sm">
        <div className="bg-white">
          <input
            type="text"
            name="courseName"
            id="courseName"
            className="w-full text-sm font-semibold text-gray-800 placeholder-gray-600 border-gray-300 border-none outline-none focus:border-white focus:ring-white"
            placeholder="Add your placeholder course here"
            value={customPlaceholderCourse.courseName}
            onChange={(e) =>
              setCustomPlaceholderCourse({
                ...customPlaceholderCourse,
                courseName: e.target.value,
              })
            }
          />
        </div>
        <div className="flex text-gray-600">
          <div className="">
            <select
              required
              id="ects"
              name="ects"
              className="block w-full py-2 pl-3 pr-10 text-xs border-gray-300 border-none outline-none focus:border-white focus:ring-white"
              value={customPlaceholderCourse.ects}
              placeholder="ECTS"
              onChange={(e) =>
                setCustomPlaceholderCourse({
                  ...customPlaceholderCourse,
                  ects: e.target.value,
                })
              }
            >
              <option selected value={0}>
                ECTS
              </option>
              {[...Array(18).keys()]
                .map((e) => e + 1)
                .map((ects) => {
                  return <option key={ects}>{ects}</option>;
                })}
            </select>
          </div>
          <div className="flex-1">
            <select
              id="classification"
              name="classification"
              className="block w-full py-2 pl-3 pr-10 text-xs border-gray-300 border-none focus:border-white focus:outline-none focus:ring-white"
              value={customPlaceholderCourse.classification}
              onChange={(e) =>
                setCustomPlaceholderCourse({
                  ...customPlaceholderCourse,
                  classification: e.target.value,
                })
              }
            >
              <option key={0} selected value="">
                Classification
              </option>
              {finalClassificationsFilterOptions.map((classificatioGroup) => {
                return (
                  <optgroup label={classificatioGroup.label}>
                    {classificatioGroup.options.map((classification) => {
                      return (
                        <option key={classification.value}>
                          {classification.value}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
            </select>
          </div>
          <div className="">
            {/* <select
              required
              id="grade"
              name="grade"
              className="block w-full py-2 pl-3 pr-10 text-xs border-gray-300 border-none outline-none focus:border-white focus:ring-white"
              value={customPlaceholderCourse.grade}
              placeholder="Grade"
              onChange={(e) =>
                setCustomPlaceholderCourse({
                  ...customPlaceholderCourse,
                  grade: e.target.value,
                })
              }
            >
              <option selected value={0}>
                Grade
              </option>
              {[...Array(6).keys()]
                .map((e) => e + 0.25)
                .map((grade) => {
                  return <option key={grade}>{grade}</option>;
                })}
            </select> */}
            <input
              type="text"
              name="grade"
              id="grade"
              className="w-full text-sm font-semibold text-gray-800 placeholder-gray-600 border-gray-300 border-none outline-none focus:border-white focus:ring-white"
              placeholder="Grade"
              value={customPlaceholderCourse.grade}
              onChange={(e) =>
                setCustomPlaceholderCourse({
                  ...customPlaceholderCourse,
                  grade: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      {!placeholderFormValid ? (
        <button
          type="button"
          className="flex items-center justify-center h-full px-8 ml-3 text-gray-800 bg-white border-none rounded-md shadow-sm cursor-not-allowed w-custom64"
          disabled={!placeholderFormValid}
        ></button>
      ) : (
        <button
          type="button"
          className="flex items-center justify-center h-full ml-3 text-gray-800 transition duration-500 ease-in-out bg-white border-none rounded-md shadow-sm w-18 hover:shadow-md"
          onClick={sendPlaceholder}
          disabled={!placeholderFormValid}
        >
          <PlusIcon className="w-6 h-6 text-gray-800 " />
        </button>
      )}
    </div>
  );
}

// rounded - lg

// rounded - md border - none px - 6 text - gray - 800
