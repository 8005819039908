// Dependencies
import { React } from "react";

// Components
import { BottomRow } from "./bottomRow/bottomRow";
import { TopRow } from "./topRow/TopRow";

// Component for left column, holding topRow & bottomRow
const LeftCol = ({
  events,
  selectedEvents,
  SelectEvent,
  ToggleDetails,
  filterToggle,
  setFilterToggle,
  filterContainerFilterData,
  searchValue,
  setSearchValue,
  filteredEvents,
  filterActive,
  myCourses,
  toBeRated,
  postRating,
  accessToken
}) => {
  return (
    <div className="relative w-full h-full xl:w-1/3">
      <div className="w-full h-full">
        <TopRow
          filterToggle={filterToggle}
          setFilterToggle={setFilterToggle}
          events={events}
          filterContainerFilterData={filterContainerFilterData}
          selectedEvents={selectedEvents}
          myCourses={myCourses}
          toBeRated={toBeRated}
          postRating={postRating}
        />
        <BottomRow
          SelectEvent={SelectEvent}
          selectedEvents={selectedEvents}
          setFilterToggle={setFilterToggle}
          ToggleDetails={ToggleDetails}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filteredEvents={filteredEvents}
          filterActive={filterActive}
          myCourses={myCourses}
          accessToken={accessToken}
        />
      </div>
    </div>
  );
};

export default LeftCol;
