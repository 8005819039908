import React from "react";
import { FilterCloseButton } from "../../../leftCol/topRow/FilterContainer/FilterCloseButton";

// Container for mobile filters
export const MobileFilterContainer = ({
  filterToggle,
  setFilterToggle,
  filterContainerFilterData,
}) => {
  return (
    <div
      className={`z-10 p-2 pb-6  absolute w-full h-full transition duration-1000 ease-in-out ${
        filterToggle && "transform -translate-y-full"
      }`}
    >
      <div className="flex flex-col w-full h-full p-4 rounded-lg xl:rounded-r-lg bg-hsg-800">
        <div className="flex items-start justify-end flex-none text-hsg-100">
          <FilterCloseButton setFilterToggle={setFilterToggle} />
        </div>
        <div className="flex-1 h-full overflow-auto scrollbar-hide">
          {filterContainerFilterData.map((filter, i) => {
            return (
              <div className="py-1" key={i}>
                <select
                  id="type"
                  name="type"
                  className="block w-full py-1 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none "
                  value={filter.value}
                  onChange={filter.handleOption}
                >
                  <option className="text-gray-200" value="" disabled selected>
                    {filter.title}
                  </option>
                  <option className="" key="" value="">
                    All
                  </option>

                  {filter.values.map((filterOption) => {
                    return (
                      <option key={filterOption} value={filterOption}>
                        {filterOption}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
