import React from "react";
// Sub-Component of the analysis component in the top left corner
export const ExamsByClassification = ({ selectedEvents }) => {
  const exams = [];

  const isPaperRegex = /paper|hausarbeit/;
  const isPresentationRegex = /präsentation|vortrag|presentation/;
  const isExam = /klausur|written examination/;
  const isParticipationRegex = /participation|teilnahme/;

  const groupByExam = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const selectedEventsFiltered = selectedEvents.filter(
    (thing, index, self) =>
      index ===
      self.findIndex((t) => t.parentCourseNumber === thing.parentCourseNumber)
  );

  selectedEventsFiltered.map((event) => {
    return exams.push(
      event.achievementParts.map((exam) => {
        var newType = "Other";
        if (isPaperRegex.test(exam.achievementForm.shortName.toLowerCase())) {
          newType = "Papers";
        } else if (
          isPresentationRegex.test(exam.achievementForm.shortName.toLowerCase())
        ) {
          newType = "Presentations";
        } else if (isExam.test(exam.achievementForm.shortName.toLowerCase())) {
          newType = "Exams";
        } else if (
          isParticipationRegex.test(
            exam.achievementForm.shortName.toLowerCase()
          )
        ) {
          newType = "Participations";
        }

        const tempObject = {
          courseNumber: event.parentCourseNumber,
          courseName: event.shortName,
          typeOriginal: exam.achievementForm.shortName,
          typeNew: newType,
          isCentral: exam.achievementForm.isCentral,
        };
        return tempObject;
      })
    );
  });

  const examsByType = groupByExam(exams.flat(), "typeNew");
  // console.log(examsByType);

  if (Object.keys(examsByType).length === 0) {
    return (
      <div className="text-sm font-medium text-gray-500 md:text-base">
        <div>Select a course first.</div>
      </div>
    );
  } else {
    return (
      <div>
        {Object.keys(examsByType)
          .sort()
          .map((key) => {
            return (
              <div className="relative grid grid-cols-4 py-1 group">
                <dt className="col-span-3 text-sm font-medium text-gray-500 truncate md:text-base">
                  {key}
                </dt>
                <dd className="text-sm text-right text-gray-700 md:text-base ">
                  <span className="font-medium">{examsByType[key].length}</span>
                </dd>
                <div className="hidden md:block">
                  <div className="absolute z-10 hidden mt-8 bg-white rounded-lg shadow md:ml-4 md:left-full md:w-128 group-hover:block">
                    <div className="px-4 py-5 sm:p-6">
                      {examsByType[key].map((exam) => {
                        return (
                          <div className="grid grid-cols-3 md:grid-cols-6">
                            <div className="truncate md:col-span-2">
                              {exam.courseName}
                            </div>
                            <div className="">
                              {exam.isCentral ? "central" : "decentral"}
                            </div>
                            <div className="md:col-span-3">
                              {exam.typeOriginal}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
};
