import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Select from "react-select";
import { creditsFilterOptionsState } from "../../../../../recoil/atoms/general/creditsFilterOptions";
import { creditsFilterSelectionState } from "../../../../../recoil/atoms/general/creditsFilterSelection";
import { lecturersFilterOptionsState } from "../../../../../recoil/atoms/general/lecturersFilterOptions";
import { lecturersFilterSelectionState } from "../../../../../recoil/atoms/general/lecturersFilterSelection";
import { ratingsFilterOptionsState } from "../../../../../recoil/atoms/general/ratingsFilterOptions";
import { ratingsFilterSelectionState } from "../../../../../recoil/atoms/general/ratingsFilterSelection";
import { classificationsFilterOptionsState } from "../../../../../recoil/atoms/general/classificationsFilterOptions";
import { classificationsFilterSelectionState } from "../../../../../recoil/atoms/general/classifiationsFilterSelection";

export function Filter() {
    const creditsFilter = useRecoilValue(creditsFilterOptionsState);
    // const creditsFilterSelection = useRecoilValue(creditsFilterSelectionState);
    const setCreditsFilterSelection = useSetRecoilState(
        creditsFilterSelectionState
    );

    const lecturersFilterOptions = useRecoilValue(lecturersFilterOptionsState);
    // const lecturersFilterSelection = useRecoilValue(lecturersFilterSelectionState)
    const setLecturersFilterSelection = useSetRecoilState(
        lecturersFilterSelectionState
    );

    const classificationsFilterOptions = useRecoilValue(
        classificationsFilterOptionsState
    );
    // const classificationFilterSelection = useRecoilValue(classificationsFilterSelectionState)
    const setClassificationsFilterSelection = useSetRecoilState(
        classificationsFilterSelectionState
    );

    const ratingsFilterOptions = useRecoilValue(ratingsFilterOptionsState);
    // const ratingsFilterSelection = useRecoilValue(ratingsFilterSelectionState)
    const setRatingsFilterSelection = useSetRecoilState(
        ratingsFilterSelectionState
    );

    function bidditTheme(theme) {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#EBFFF2",
                primary: "#009938",
            },
        };
    }

    return (
        <div className="grid grid-cols-2 mb-4 text-sm">
            <Select
                options={lecturersFilterOptions}
                defaultValue={[]}
                isMulti
                name="Lecturers Filter"
                onChange={setLecturersFilterSelection}
                placeholder="Lecturers"
                className="col-span-2"
                theme={(theme) => bidditTheme(theme)}
            />
            <Select
                options={classificationsFilterOptions}
                defaultValue={[]}
                name="Classification Filter"
                onChange={setClassificationsFilterSelection}
                placeholder="Classification"
                isClearable={true}
                className="col-span-2"
                theme={(theme) => bidditTheme(theme)}
            />

            <Select
                options={creditsFilter}
                defaultValue={[]}
                name="Credits Filter"
                onChange={setCreditsFilterSelection}
                placeholder="ECTS"
                isMulti
                isClearable={false}
                theme={(theme) => bidditTheme(theme)}
            />

            <Select
                options={ratingsFilterOptions}
                defaultValue={[]}
                name="Ratings Filter"
                onChange={setRatingsFilterSelection}
                placeholder="Ratings"
                isClearable={true}
                theme={(theme) => bidditTheme(theme)}
            />
        </div>
    );
}
