import { Dialog, Transition } from "@headlessui/react";
import { LeftCol } from "./leftCol/index.jsx";
import { SideNav } from "./leftCol/sideNav/index.jsx";
import { RightCol } from "./rightCol/index.jsx";
import React, { Fragment, useRef, useState } from "react";
import { MigrationModal } from "../../utils/modals/MigrationModal.jsx";
// import { ErrorComponent } from "../utils/ErrorComponent.jsx";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Main() {
  const [open, setOpen] = useState(
    localStorage.getItem("newBiddit") === "false" ? false : true
  );

  function updateCookie() {
    localStorage.setItem("newBiddit", false);
    setOpen(false);
  }

  const cancelButtonRef = useRef(null);

  return (
      <div className="flex w-screen h-screen bg-gray-100">
          {/* <ErrorComponent /> */}
          <SideNav />
          <React.Suspense fallback={<></>}>
              <MigrationModal />
          </React.Suspense>
          <div>
              {open ? (
                  <Transition.Root show={open} as={Fragment}>
                      <Dialog
                          as="div"
                          className="relative z-10"
                          initialFocus={cancelButtonRef}
                          onClose={setOpen}
                      >
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                          >
                              <div className="fixed inset-0 transition-opacity backdrop-filter backdrop-blur" />
                          </Transition.Child>

                          <div className="fixed inset-0 z-10 overflow-y-auto">
                              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                                  <Transition.Child
                                      as={Fragment}
                                      enter="ease-out duration-300"
                                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                                      leave="ease-in duration-200"
                                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                  >
                                      <Dialog.Panel className="relative px-4 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                          <div>
                                              <div className="text-center">
                                                  <Dialog.Title
                                                      as="h3"
                                                      className="pb-2 text-lg font-medium leading-6 text-gray-900"
                                                  >
                                                      Welcome to the new version
                                                      of{" "}
                                                      <span className="text-hsg-700">
                                                          Biddit
                                                      </span>
                                                  </Dialog.Title>
                                                  <div className="mt-2 text-sm text-gray-500">
                                                      <p className="mt-2">
                                                          While we kept the
                                                          amazing features you
                                                          already love, we added
                                                          new capabilities that
                                                          allow you to plan your
                                                          entire study program
                                                          🚀
                                                      </p>
                                                      <p className="mt-2">
                                                          Switch between
                                                          semesters, select
                                                          courses, check your
                                                          grades and track your
                                                          progress towards your
                                                          degree. You can even
                                                          add custom placeholder
                                                          courses. Selected
                                                          courses will also sync
                                                          across your devices.
                                                      </p>
                                                  </div>
                                                  <div className="pt-4 text-sm text-gray-500">
                                                      <p className="pb-2">
                                                          We also updated some
                                                          of the icons:
                                                      </p>
                                                      <div className="grid grid-cols-3 gap-2 mx-auto text-xs w-80">
                                                          <div className="col-span-1"></div>
                                                          <div className="col-span-1">
                                                              not overlapping
                                                          </div>
                                                          <div className="col-span-1">
                                                              overlapping
                                                          </div>
                                                          <div className="col-span-1">
                                                              allocated
                                                          </div>
                                                          <div className="flex justify-center col-span-1 text-main">
                                                              <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  fill="none"
                                                                  viewBox="0 0 24 24"
                                                                  stroke-width="1.5"
                                                                  stroke="currentColor"
                                                                  class="w-6 h-6"
                                                              >
                                                                  <path
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                                  />
                                                              </svg>
                                                          </div>
                                                          <div className="flex justify-center col-span-1 text-warning">
                                                              <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  fill="none"
                                                                  viewBox="0 0 24 24"
                                                                  stroke-width="1.5"
                                                                  stroke="currentColor"
                                                                  class="w-6 h-6"
                                                              >
                                                                  <path
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                                  />
                                                              </svg>
                                                          </div>
                                                          <div className="col-span-1">
                                                              selected
                                                          </div>
                                                          <div className="flex justify-center col-span-1 text-main">
                                                              <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  fill="none"
                                                                  viewBox="0 0 24 24"
                                                                  stroke-width="1.5"
                                                                  stroke="currentColor"
                                                                  class="w-6 h-6"
                                                              >
                                                                  <path
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                                  />
                                                              </svg>
                                                          </div>
                                                          <div className="flex justify-center col-span-1 text-warning">
                                                              <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  fill="none"
                                                                  viewBox="0 0 24 24"
                                                                  stroke-width="1.5"
                                                                  stroke="currentColor"
                                                                  class="w-6 h-6"
                                                              >
                                                                  <path
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                                  />
                                                              </svg>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <button
                                              type="button"
                                              className="justify-center w-full px-4 py-2 mt-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500 sm:col-start-2 sm:text-sm"
                                              onClick={() => updateCookie()}
                                              ref={cancelButtonRef}
                                          >
                                              Continue to Biddit
                                          </button>
                                      </Dialog.Panel>
                                  </Transition.Child>
                              </div>
                          </div>
                      </Dialog>
                  </Transition.Root>
              ) : (
                  <></>
              )}
          </div>
          <div className="grid w-full h-screen grid-flow-col grid-cols-3 gap-4 py-4 pr-4">
              <LeftCol styleProps="h-full flex col-span-1" />
              <React.Suspense
                  fallback={
                      <div className="flex items-center justify-center flex-1 w-full h-full col-span-2 text-xs text-center text-gray-600 align-middle bg-gray-200 rounded animate-pulse">
                          Calculating your grades...
                      </div>
                  }
              >
                  <RightCol styleProps="h-full flex bg-white col-span-2 overflow-hidden rounded-lg shadow-sm" />
              </React.Suspense>
          </div>
          <ToastContainer />
      </div>

  );
}
