import React from "react";
import shsg_logo_icon_title_white from "../../../../assets/SHSG_Logo_Icon_Title_small_white.png";
import { ContactButton } from "./ContactButton";
import { PrivacyButton } from "./PrivacyButton";
import { AboutButton } from "./AboutButton";
import { SignOutButton } from "../../../utils/SignOutButton";
import { ReviewButton } from "./ReviewButton";
import { ThumbUpIcon } from "@heroicons/react/outline";

// container holding logo and several buttons
export const SideNav = () => {
    return (
        <div className="items-center justify-start p-3 mr-4 flex-col flex bg-hsg-800 h-full">
            <div className="flex flex-col mb-8">
                <a href="https://shsg.ch" target="_blank" rel="noreferrer">
                    <img
                        className="object-contain w-8 mt-2"
                        src={shsg_logo_icon_title_white}
                        alt="SHSG Logo"
                    />
                </a>
            </div>
            <div className="flex flex-col">
                <React.Suspense
                    fallback={
                        <div className="inline-flex items-center justify-center p-2 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:bg-hsg-800 relative">
                            <ThumbUpIcon
                                className="block w-6 h-6"
                                aria-hidden="true"
                            />
                        </div>
                    }
                >
                    <div className="py-1">
                        <ReviewButton />
                    </div>
                </React.Suspense>
                <div className="py-1">
                    <AboutButton />
                </div>
                <div className="py-1">
                    <PrivacyButton />
                </div>
                <div className="py-1">
                    <ContactButton />
                </div>
                <div className="py-1">
                    <SignOutButton />
                </div>
            </div>
        </div>
    );
};
