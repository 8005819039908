import React from "react";
import { useMsal } from "@azure/msal-react";
import { LogoutIcon } from "@heroicons/react/outline";

export const SignOutButton = () => {
  // Logout of Msal
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  return (
    <div>
      <button
        className="items-center justify-center hidden p-2 text-white rounded-md lg:inline-flex hover:bg-hsg-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:bg-hsg-800"
        onClick={() => handleLogout()}
      >
        <LogoutIcon className="w-6 h-6" />
      </button>
    </div>
  );
};
