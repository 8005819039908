export const studienOrdnungBVWL = {
    creditsDe: null,
    creditsEn: null,
    creditsFulfilledDe: null,
    creditsFulfilledEn: null,
    isProcessing: false,
    items: [
        {
            items: [
                {
                    items: [
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "4.00",
                                    minCredits: "4.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Macroeconomics II",
                                    id: 3461,
                                    shortName: "Macroeconomics II",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "4.00",
                                    minCredits: "4.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Microeconomics II",
                                    id: 3461,
                                    shortName: "Microeconomics II",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Data Analytics I: Statistics (VWL)",
                                    id: 3461,
                                    shortName: "Data Analytics I: Statistics (VWL)",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "4.00",
                                    minCredits: "4.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Data Handling: Import, Cleaning and Visualisation",
                                    id: 3461,
                                    shortName: "Data Handling: Import, Cleaning and Visualisation",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Microeconomics III",
                                    id: 3461,
                                    shortName: "Microeconomics III",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Macroeconomics III",
                                    id: 3461,
                                    shortName: "Macroeconomics III",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Data Analytics II: Empirical Research Methods",
                                    id: 3461,
                                    shortName: "Data Analytics II: Empirical Research Methods",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "4.00",
                                    minCredits: "4.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Accounting, Controlling, Auditing",
                                    id: 3461,
                                    shortName: "Accounting, Controlling, Auditing",
                                }
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.3458.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "40.00",
                            minCredits: "40.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Compulsory Subjects",
                            id: 3458,
                            shortName: "Compulsory Subjects",
                        },
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "30.00",
                                    minCredits: "18.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "SEPS",
                                    id: 3457,
                                    shortName: "SEPS",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "18.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "LS",
                                    id: 3457,
                                    shortName: "LS",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3452.3457.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "16.00",
                                    minCredits: "4.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "SoM and SOF",
                                    id: 3457,
                                    shortName: "SoM and SOF",
                                }
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "40.00",
                            minCredits: "28.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Core Electives",
                            id: 3457,
                            shortName: "Core Electives",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "12.00",
                            minCredits: "0.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Electives",
                            id: 3457,
                            shortName: "Electives",
                        },
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3452.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "80.00",
                    minCredits: "80.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: null,
                    description: "Core Studies",
                    id: 3452,
                    shortName: "Core Studies",
                },
                {
                    items: [
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "24.00",
                            minCredits: "12.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Areas of Concentration ",
                            id: 3461,
                            shortName: "Areas of Concentration ",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "12.00",
                            minCredits: "0.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Skills",
                            id: 3461,
                            shortName: "Skills",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "12.00",
                            minCredits: "0.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Languages",
                            id: 3461,
                            shortName: "Languages",
                        }
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3460.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "24.00",
                    minCredits: "24.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: null,
                    description: "Contextual Studies",
                    id: 3460,
                    shortName: "Contextual Studies",
                },
                {
                    items: [],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3463.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "16.00",
                    minCredits: "16.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: "0.00",
                    description: "Bachelor’s thesis",
                    id: 3463,
                    shortName: "Bachelor’s thesis",
                }
            ],
            achievementLanguage: null,
            gradeText: "",
            hierarchy: ".3451.",
            isTitle: true,
            mark: null,
            maxCredits: "120.00",
            minCredits: "120.00",
            mncp: "0.00",
            scoreCardMask: 1,
            semester: "",
            studyPlanMainFocus: null,
            sumOfCredits: null,
            description: "BVWL",
            id: 3451,
            shortName: "BVWL",
        },
    ],
    minCreditsDe: null,
    minCreditsEn: null,
    scoreCardLinks: [],
    showMncp: true,
    id: 0,
};