import React from "react";
import shsg_logo_icon_title_white from "../../../../assets/SHSG_Logo_Icon_Title_small_white.png";
import { ContactButton } from "./ContactButton";
import { SignOutButton } from "../../../../utils/auth/SignOutButton";
import { PrivacyButton } from "./PrivacyButton";
import { AboutButton } from "./AboutButton";
import { ReviewButton } from "./ReviewButton";

// container holding logo and several buttons
export const SideNav = ({toBeRated, postRating}) => {
  return (
    <div className="items-center justify-between hidden p-3 mr-4 rounded-r-lg lg:flex-col lg:flex bg-hsg-800">
      <div className="flex flex-col">
        <a href="https://shsg.ch" target="_blank" rel="noreferrer">
          <img
            className="object-contain w-8 mt-2"
            src={shsg_logo_icon_title_white}
            alt="SHSG Logo"
          />
        </a>
      </div>
      <div className="flex flex-col">
        <div className="py-1">
          <ReviewButton toBeRated={toBeRated} postRating={postRating} />
        </div>
        <div className="py-1">
          <AboutButton />
        </div>
        <div className="py-1">
          <PrivacyButton />
        </div>
        <div className="py-1">
          <ContactButton />
        </div>
        <div className="py-1">
          <SignOutButton />
        </div>
      </div>
    </div>
  );
};
