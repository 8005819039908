export function SendErrorButton({ error, buttonColor, buttonHovorColor }) {
    const email = `mailto:biddit@shsg.ch?subject=Biddit Error&body=Error message:%0D%0A----------%0D%0A
    Description:    ${error.description}%0D%0A
    File Name:      ${error.fileName}%0D%0A
    Line Number:    ${error.lineNumber}%0D%0A
    Message:        ${error.message}%0D%0A
    Name:           ${error.name}%0D%0A
    Number:         ${error.number}%0D%0A
    Stack:          ${error.stack}%0D%0A
    Prototype:      ${error.prototype}%0D%0A----------`;

    return (
        <a
            href={email}
            className={`rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:${buttonHovorColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${buttonColor}`}
        >
            Send Error
        </a>
    );
}
