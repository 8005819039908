import { atom, selector } from "recoil";

export const ratingsFilterOptionsState = atom({
  key: "ratingsFilterOptions",
  default: selector({
    key: "ratingsFilterOptions/Default",
    get: () => {
      return [
        // { value: 0, label: "All Ratings" },
        { value: 1, label: "> 1" },
        { value: 2, label: "> 2" },
        { value: 3, label: "> 3" },
        { value: 4, label: "> 4" },
      ];
    },
  }),
});
