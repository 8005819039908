export function ErrorCodeBlock({ error }) {
    const errorText = `
    Error message:
    ----------
        Description:    ${error.description}
        File Name:      ${error.fileName}
        Line Number:    ${error.lineNumber}
        Message:        ${error.message}
        Name:           ${error.name}
        Number:         ${error.number}
        Stack:          ${error.stack}
        Prototype:      ${error.prototype}
    ----------
    `;

    const handleClick = function () {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(errorText).then(() => {
                alert("Send the Error to biddit@shsg.ch");
            });
        } else {
            alert("Error copying. Please use the 'Send Error'-Button below.");
        }
    };
    return (
        <div
            className="prose text-left my-4 relative mx-auto"
            id="ErrorMessage"
        >
            <pre className="max-h-76">
                <div className="absolute top-0 right-0">
                    <button
                        className="mt-2 mr-6 p-2 rounded-md transition ease-in-out bg-white bg-opacity-20 hover:bg-opacity-40 bg-tran"
                        onClick={handleClick}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                            />
                        </svg>
                    </button>
                </div>
                <code class="language-html">
                    Description: {error.description}
                    <br />
                    File Name: {error.fileName}
                    <br />
                    Line Number: {error.lineNumber}
                    <br />
                    Message: {error.message}
                    <br />
                    Name: {error.name}
                    <br />
                    Number: {error.number}
                    <br />
                    Stack: {error.stack}
                    <br />
                    Prototype: {error.prototype}
                    <br />
                </code>
            </pre>
        </div>
    );
}
