import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

// Switch to go back to teh calendar 
export const UpDownSwitch = ({ toggle, isToggled }) => {
  return (
    <div className="cursor-pointer">
      {isToggled ? (
        <ChevronDownIcon className="w-6 h-6" onClick={toggle} />
      ) : (
        <ChevronUpIcon className="w-6 h-6" onClick={toggle} />
      )}
    </div>
  );
};
