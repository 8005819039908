import React from "react";
import { FilterContainer } from "./FilterContainer/FilterContainer";
import { SideNav } from "./SideNav/SideNav";
import { Summary } from "./Summary/Summary";

// Component for top row, holding filter popout, summary, and side nav
export const TopRow = ({
  filterToggle,
  setFilterToggle,
  events,
  filterContainerFilterData,
  selectedEvents,
  myCourses,
  toBeRated,
  postRating,
}) => {
  return (
    <div className="relative flex py-4 pl-4 pr-4 lg:pl-0 h-1/2">
      <SideNav toBeRated={toBeRated} postRating={postRating} />
      <FilterContainer
        filterToggle={filterToggle}
        setFilterToggle={setFilterToggle}
        events={events}
        filterContainerFilterData={filterContainerFilterData}
      />

      <div className="flex-1">
        <Summary selectedEvents={selectedEvents} myCourses={myCourses} />
      </div>
    </div>
  );
};
