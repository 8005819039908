// Dependencies
import React from "react";

// Components
import { EventListContainer } from "./eventListContainer/EventListContainer";

// Simple component that contains the bottom row
export const BottomRow = ({
  SelectEvent,
  selectedEvents,
  setFilterToggle,
  languageFilterSelection,
  typeFilterSelection,
  ToggleDetails,
  filterActive,
  searchValue,
  setSearchValue,
  filteredEvents,
  myCourses,
  accessToken
}) => {
  return (
    <div className="flex w-full lg:flex-row h-1/2">
      <div className="hidden 2xl:w-16 xl:flex-none xl:block"></div>
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full h-full">
          <EventListContainer
            selectedEvents={selectedEvents}
            SelectEvent={SelectEvent}
            setFilterToggle={setFilterToggle}
            languageFilterSelection={languageFilterSelection}
            typeFilterSelection={typeFilterSelection}
            ToggleDetails={ToggleDetails}
            filterActive={filterActive}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filteredEvents={filteredEvents}
            myCourses={myCourses}
            accessToken={accessToken}
          />
        </div>
      </div>
    </div>
  );
};
