import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import biddit_x_shsg from "../../assets/Biddit_x_SHSG_green.png";

export const AcquisitionModal = () => {
  const [open, setOpen] = useState(
    localStorage.getItem("bidditacquisition") === "false" ? false : true
  );

  function updateCookie() {
    localStorage.setItem("bidditacquisition", false);
    setOpen(false);
  }

  const cancelButtonRef = useRef(null);
  return (
    <div>
      {open ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity backdrop-filter backdrop-blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                      <img
                        className="h-16 mx-auto mb-2"
                        src={biddit_x_shsg}
                        alt="SHSG x Biddit"
                      ></img>

                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Biddit is now part of the{" "}
                          <a
                            className="text-hsg-700"
                            href="https://shsg.ch"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SHSG 🥳
                          </a>
                        </Dialog.Title>
                        <div className="mt-2 text-sm text-gray-500">
                          <p>
                            We are excited to announce that we are now a part of the Student Union of the HSG (SHSG). Together with the SHSG, we will continue to build tools that make student life at HSG easier.
                          </p>
                          <p className="mt-2">
                            This means you can look forward to even better
                            support, new features, and lots more 🚀
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500 sm:col-start-2 sm:text-sm"
                          type="button"
                          onClick={() => { updateCookie(); window.open("https://www.instagram.com/p/CgUIAwTKsA5/?igshid=YmMyMTA2M2Y=", "_blank") }}
                        >
                          Read Announcement
                        </button>
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => updateCookie()}
                        ref={cancelButtonRef}
                      >
                        Continue to Biddit
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <></>
      )}
    </div>
  );
};
