import React from "react";
import MobileEventList from "./mobileEventList/MobileEventList";
import { EventListInputs } from "../../leftCol/bottomRow/eventListContainer/inputs/EventListInputs";

// Component containing the EventListInputs (Search & Filter) and EventList
const MobileBottom = ({
  events,
  SelectEvent,
  ToggleDetails,
  searchValue,
  setSearchValue,
  setFilterToggle,
  filterActive,
  filteredEvents,
  accessToken
}) => {
  return (
    <div className="flex flex-col h-full px-2 pb-4 overflow-auto scrollbar-hide">
      <div className="">
        <EventListInputs
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setFilterToggle={setFilterToggle}
          filterActive={filterActive}
        />
      </div>
      <div className="h-full pt-4">
        <MobileEventList
          filteredEvents={filteredEvents}
          SelectEvent={SelectEvent}
          ToggleDetails={ToggleDetails}
          accessToken={accessToken}
        />
      </div>
    </div>
  );
};

export default MobileBottom;
