import React from "react";
import { HeaderElement } from "./HeaderElement";
import { useRecoilValue } from "recoil";
import { selectedCoursesQuery } from "../../../recoil/selectors/general/selelctedCourses";
import { BodyElement } from "./BodyElement";
import { localCustomGradesState } from "../../../recoil/atoms/custom_grades/localCustomGrades";
// import { useRecoilRefresher_UNSTABLE } from "recoil";
// import { useRecoilCallback } from "recoil";

// helpers to build table structure
let level = 0;
let previousLevel = 0;
let previousDescription = "";

export const TranscriptTable = ({ row }) => {
  const chosenCoursesOnLoad = useRecoilValue(selectedCoursesQuery);
  const [chosenCourses, setChosenCourses] = React.useState([]);
  const [structure, setStructure] = React.useState([]);
  const localCustomGrades = useRecoilValue(localCustomGradesState);

  React.useEffect(() => {
    let chosenCourses = chosenCoursesOnLoad.flat().filter((course) => {
      // return if it's either this format /.*,1.0.$/.test(course.courseNumber) or its a 5 digit number
      return (
        /.*,1.0.$/.test(course.courseNumber) ||
        /^\d{5}$/.test(course.courseNumber)
      );
    });
    // df38c9b2-4db7-4389-8485-20fdb4318f40
    // filter if course.allocated is true AND course.semesterId is not equal to df38c9b2-4db7-4389-8485-20fdb4318f40
    chosenCourses = chosenCourses.filter((course) => {
      return !(
        course.allocated === true &&
        course.semesterId !== "74db9567-f466-44c3-a9d5-04cbecb0d084" // semesterchange maybe?
      );
    });

    //console.debug("chosenCourses", chosenCourses);
    setChosenCourses(chosenCourses);
  }, [chosenCoursesOnLoad]);

  React.useEffect(() => {
    let currentStructure = {};
    let struct = [];

    function getCustomGradeForCourse(identifier) {
      if (localCustomGrades[identifier]) {
        return localCustomGrades[identifier];
      } else {
        return null;
      }
    }

    currentStructure = {
      name: row.description,
      maxCredits: parseFloat(row.maxCredits),
      minCredits: parseFloat(row.minCredits),
      totalCredits: parseFloat(row.sumOfCredits),
      addedCredits: 0,
      courses: [],
      children: [],
      directChildren: [],
      isTitle: true,
      gradeSum: 0,
      filteredCredits: parseFloat(row.sumOfCredits),
    };

    if (row.items && row.items[0] && row.items[0].isTitle === false) {
      // check if the course is already added to the structure
      row.items.forEach((course) => {
        currentStructure.courses.push({
          ...course,
          customGrade: getCustomGradeForCourse(course.shortName),
        });
        currentStructure.gradeSum +=
          parseFloat(course.mark) * parseFloat(course.sumOfCredits);
        if (course.description === "Campus Credits") {
          currentStructure.filteredCredits -= parseFloat(course.sumOfCredits);
        }
      });
    }
    if (row.items && row.items[0] && row.items[0].isTitle === true) {
      // check if the course is already added to the structure
      row.items.forEach((course) => {
        currentStructure.directChildren.push(course);
      });
    }
    pushChildrenRecursive(row);
    addAdditionalCredits(row);

    function pushChildrenRecursive(row) {
      if (row.items) {
        row.items.forEach((item) => {
          if (item.isTitle === true) {
            currentStructure.children.push(item.description);
          }
          pushChildrenRecursive(item);
        });
      }
    }
    function addAdditionalCredits(row) {
      chosenCourses.flat().forEach((course) => {
        if (course.classification === row.description) {
          if (currentStructure.courses.indexOf(course) === -1) {
            currentStructure.courses.push({
              ...course,
              customGrade: getCustomGradeForCourse(course.shortName),
            });
            currentStructure.totalCredits += course.credits / 100;
            currentStructure.addedCredits += course.credits / 100;
          }
        }
      });
    }
    struct.push(currentStructure);

    function getStructure(row) {
      if (row.items) {
        row.items.forEach((item) => {
          if (item.isTitle === true) {
            currentStructure = {
              name: item.description,
              maxCredits: parseFloat(item.maxCredits),
              minCredits: parseFloat(item.minCredits),
              totalCredits: parseFloat(item.sumOfCredits),
              addedCredits: 0,
              courses: [],
              children: [],
              directChildren: [],
              isTitle: true,
              gradeSum: 0,
              filteredCredits: parseFloat(item.sumOfCredits),
              customGradeSum: 0,
              customEctsSum: 0,
            };
            if (
              item.items &&
              item.items[0] &&
              item.items[0].isTitle === false
            ) {
              // check if the course is already added to the structure
              item.items.forEach((course) => {
                currentStructure.courses.push({
                  ...course,
                  customGrade: getCustomGradeForCourse(course.shortName),
                });
                currentStructure.gradeSum +=
                  parseFloat(course.mark) * parseFloat(course.sumOfCredits) ||
                  0;

                let customCourseGradeSum =
                  getCustomGradeForCourse(course.shortName) *
                  parseFloat(course.sumOfCredits) || 0;

                if (customCourseGradeSum > 0) {
                  currentStructure.customGradeSum += customCourseGradeSum;
                  currentStructure.customEctsSum += parseFloat(
                    course.sumOfCredits
                  );
                }

                if (course.description === "Campus Credits" || course.description === "Practice Credits") {
                  currentStructure.filteredCredits -= parseFloat(
                    course.sumOfCredits
                  );
                }
              });
            }
            if (item.items && item.items[0] && item.items[0].isTitle === true) {
              // check if the course is already added to the structure
              item.items.forEach((course) => {
                currentStructure.directChildren.push(course);
              });
            }
            pushChildrenRecursive(item);
            addAdditionalCredits(item);

            struct.push(currentStructure);
          }

          function pushChildrenRecursive(row) {
            if (row.items) {
              row.items.forEach((item) => {
                if (item.isTitle === true) {
                  currentStructure.children.push(item.description);
                }

                pushChildrenRecursive(item);
              });
            }
          }

          function addAdditionalCredits(row) {
            chosenCourses.flat().forEach((course) => {
              if (course.classification === row.description) {
                if (currentStructure.courses.indexOf(course) === -1) {
                  let customGrade = getCustomGradeForCourse(
                    course.courseNumber
                  );
                  currentStructure.courses.push({
                    ...course,
                    customGrade: getCustomGradeForCourse(course.courseNumber),
                  });
                  currentStructure.totalCredits += course.credits / 100;
                  currentStructure.addedCredits += course.credits / 100;
                  if (customGrade > 0) {
                    currentStructure.customGradeSum +=
                      customGrade * (course.credits / 100);
                    currentStructure.customEctsSum += course.credits / 100;
                  }
                }
              }
            });
          }

          getStructure(item);
        });
      }
    }
    getStructure(row);

    const sumOfAllChildCredits = (item, struct) => {
      let sum = 0;
      let gradeSum = 0;
      let filteredCredits = 0;
      let allCredits = 0;
      let customGradeSum = 0;
      let customECTSsum = 0;

      // console.debug("struct", item, customGradeSum);
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          struct.forEach((structureItem) => {
            if (
              structureItem.name === child &&
              structureItem.courses.length > 0 &&
              structureItem.maxCredits > 0
            ) {
              // console.log("struct-1", structureItem, child);
              sum += Math.min(
                structureItem.totalCredits,
                structureItem.maxCredits
              );
              gradeSum += structureItem.gradeSum;
              filteredCredits += structureItem.filteredCredits;
              allCredits += structureItem.totalCredits;
              customGradeSum += structureItem.customGradeSum;
              customECTSsum += structureItem.customEctsSum;
            }
          });
        });
      } else if (item.courses.length > 0) {
        sum += Math.min(item.totalCredits, item.maxCredits);
        gradeSum += item.gradeSum;
        filteredCredits += item.filteredCredits;
        allCredits += item.totalCredits;

        customGradeSum += item.customGradeSum;
        customECTSsum += item.customEctsSum;
      }
      // console.debug("struct-2", item, customGradeSum);

      return {
        sum,
        gradeSum,
        filteredCredits,
        allCredits,
        customGradeSum,
        customECTSsum,
      };
    };

    struct &&
      struct.forEach((item) => {
        item.ECTS = Math.min(
          sumOfAllChildCredits(item, struct).sum,
          item.maxCredits
        );
        item.fullCredits = sumOfAllChildCredits(item, struct).sum;
        item.avg =
          sumOfAllChildCredits(item, struct).gradeSum /
          sumOfAllChildCredits(item, struct).filteredCredits;
        item.customGrade =
          (sumOfAllChildCredits(item, struct).customGradeSum + sumOfAllChildCredits(item, struct).gradeSum) /
          (sumOfAllChildCredits(item, struct).customECTSsum + sumOfAllChildCredits(item, struct).filteredCredits);
      });

    // console.debug("struct", struct);
    setStructure(struct);
  }, [row, chosenCourses, localCustomGrades]); //

  if (
    !structure ||
    structure.length === 0 ||
    !row ||
    !chosenCourses ||
    structure === undefined
  ) {
    return null;
  }

  //  console.debug("chosen", structure);

  return (
    <div className="p-4 text-sm">
      <HeaderElement />
      <BodyElement
        row={row}
        chosenCourses={chosenCourses}
        structure={structure}
        previousLevel={previousLevel}
        level={level}
        previousDescription={previousDescription}
      />
    </div>
  );
};
