// Dependencies
import { XIcon } from "@heroicons/react/outline";

// Popup that shows when a selected course does not have any calendar events
// This then provides a link for the user to go to the original source, in case they want to check

export default function EmptyCalPopUp({ setEmptyCalOpen, lastSelectedEvent }) {
  return (
    <div className="relative p-4 rounded-md md:-left-1/2 h bg-yellow-50">
      <div className="flex">
        <div className="flex flex-1 ml-3 md:flex md:justify-between ">
          <p className="text-xs text-left text-yellow-700 lg:text-sm">
            We can't find any events for{" "}
            {lastSelectedEvent && lastSelectedEvent.courseNumber}. If you'd like
            to make sure, here's the{" "}
            <a
              className="italic hover:text-yellow-500"
              href={lastSelectedEvent && lastSelectedEvent.timeTableLink}
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>{" "}
            to check it in compass.
          </p>
          <div className="flex-1 ml-2">
            <button
              type="button"
              className="text-yellow-400 rounded-md bg-yellow-50 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 "
              onClick={() => setEmptyCalOpen(false)}
            >
              <span className="sr-only">Close</span>
              <XIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
