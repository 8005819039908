import Calendar from "./calendar/Calendar";
import { FocusEvent } from "./focusEvent/FocusEvent";

// Right column containing focusEvent (course details) and calendar
const RightCol = ({
  selectedEventDates,
  SelectEvent,
  isToggled,
  toggle,
  focusEvent,
  ToggleDetails,
}) => {
  return (
    <div className="hidden mr-4 lg:relative lg:flex-1 lg:block">
      {/* Focus Event */}
      <div
        onClick={isToggled && focusEvent && toggle}
        className={`py-4 rounded-lg transition duration-1000 ease-in-out absolute w-full h-9/10 bottom-1/10
		${isToggled && "transform -translate-y-9/10 cursor-pointer"}`}
      >
        <FocusEvent
          focusEvent={focusEvent}
          isToggled={isToggled}
          toggle={toggle}
        />
      </div>

      {/* Calendar */}
      <div
        onClick={focusEvent && toggle && !isToggled}
        className={`py-4 transition duration-1000 ease-in-out absolute w-full h-9/10 top-1/10
		${!isToggled && "transform translate-y-9/10 cursor-pointer"}`}
      >
        <Calendar
          selectedEventDates={selectedEventDates}
          SelectEvent={SelectEvent}
          ToggleDetails={ToggleDetails}
        />
      </div>
    </div>
  );
};

export default RightCol;
