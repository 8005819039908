import { atom, selector } from "recoil";
import { coursesNotMigratedState } from "./coursesNotMigrated";

export const migrationAlertModalState = atom({
    key: "migrationAlertModal",
    default: selector({
        key: "migrationAlertModal/Default",
        get: async ({ get }) => {
            return Object.keys(await get(coursesNotMigratedState)).length > 0;
        },
        set: ({ set }, newValue) => set(migrationAlertModalState, newValue),
    }),
});
