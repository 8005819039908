import React from "react";
import { EventListContainer } from "./eventListContainer/index";

export function LeftCol({ styleProps }) {
  return (
    <div className={styleProps}>
      <div className="flex flex-col w-full">
        {/* Disclaimer should be displayed as a popup when necessary */}
        {/* <div className="flex items-center justify-center w-full h-24 bg-green-100">
          Disclaimer
        </div> */}

        {/* <React.Suspense fallback={<div>loading your data from unisg.ch..</div>}> */}
        <EventListContainer />
        {/* </React.Suspense> */}
      </div>
    </div>
  );
}
