import { atom, selector } from "recoil";
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

// export const tokenState = atom({
//     key: 'tokenState',
//     default: ''
// })

async function GetToken() {
  const { instance, inProgress, accounts } = useMsal();
  if (inProgress === InteractionStatus.None) {
    const accessTokenRequest = {
      scopes: ["https://integration.unisg.ch/api/user_impersonation"],
      account: accounts[0],
    };
    const token = await instance
      .acquireTokenSilent(accessTokenRequest)
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      })
      .then(async (accessTokenResponse) => {
        const token = accessTokenResponse.accessToken;
        return token;
      });
    return token;
  }
}

export const tokenQuery = atom({
  key: "token",
  default: selector({
    key: "token/Default",
    get: async () => {
      const x = await GetToken();
      return x;
    },
  }),
});
