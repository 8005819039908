// Dependencies
import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { PlusIcon } from "@heroicons/react/outline";

import { StarIcon } from "@heroicons/react/solid";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { selectedCourseState } from "../../../../recoil/atoms/general/selectedCourse";
import { selectedTabState } from "../../../../recoil/atoms/general/selectedTab";
import { sortedCoursesQuery } from "../../../../recoil/selectors/general/sortedCourses";
import { useLocalSelectedCourses } from "../../../../recoil/atoms/general/localSelectedCourses";
import { LockOpen } from "../../../rightCol/util/LockOpen";
import { LockClosed } from "../../../rightCol/util/LockClosed";
// import { Placeholder } from "../placeholder";

// List of all the courses a student has available
export const EventList = () => {
  const courses = useRecoilValue(sortedCoursesQuery);
  const setSelectedCourse = useSetRecoilState(selectedCourseState);
  const [listLength, setListLength] = useState(100);
  const setSelectedTab = useSetRecoilState(selectedTabState);

  const setLocalSelectedCourses = useLocalSelectedCourses();

  useEffect(() => {
    setListLength(courses.length);
  }, [courses]);

  // Row component that is used by react-window List
  // Each row is one course
  const Row = ({ index, style }) => {
    const event = courses[index];

    if (!event) {
      return null;
    } else {
      return (
        <div
          key={index}
          className="flex w-full h-full overflow-visible pb-2.5"
          style={style}
        >
          <div
            onClick={() => {
              setSelectedCourse(event);
              setSelectedTab(0);
            }}
            className={`flex-1 py-2 pl-3 pr-4 rounded-lg shadow-sm overflow-hidden cursor-pointer hover:shadow-md transition duration-500 ease-in-out bg-white dark:bg-gray-200 text-gray-800 dark:text-gray-200`}
          >
            <div className="pb-2 font-semibold">
              <p className="truncate">
                {event ? `${event.shortName}` : "Loading..."}
              </p>
            </div>
            <div className={`text-xs grid grid-cols-12 text-gray-700`}>
              {event && event.hasOwnProperty("avgRating") ? (
                <p className="flex col-span-2 ">
                  <StarIcon width={12} color="#006625" /> {event.avgRating}
                </p>
              ) : (
                <p className="flex col-span-2 text-gray-500">
                  <StarIcon width={12} color="#6b7280" /> NA
                </p>
              )}
              <p className="hidden col-span-2 truncate md:block">
                {event ? `${event.credits / 100} ECTS` : "NA"}
              </p>

              <p className="col-span-8 truncate">
                {event ? `${event.classification} ` : "NA"}
              </p>
            </div>
          </div>
          <button
            id="select_course"
            onClick={() => {
              setLocalSelectedCourses(event);
              setSelectedCourse(event);
            }}
            disabled={event.allocated}
            className={`flex justify-center items-center h-full w-custom64 shadow-sm rounded-lg ml-3 transition duration-500 ease-in-out bg-white ${
              event && event.overlapping
                ? "border-warning text-warning cursor-pointer hover:shadow-md"
                : event.allocated
                ? " border-main text-main cursor-not-allowed"
                : event.selected
                ? "border-main text-main cursor-pointer hover:shadow-md"
                : "hover:shadow-md"
            }`}
          >
            {event ? (
              event.allocated ? (
                <LockClosed clg="w-6 h-6 " />
              ) : event.selected ? (
                <LockOpen clg="w-6 h-6 " />
              ) : (
                <PlusIcon className="w-6 h-6 text-gray-800 " />
              )
            ) : (
              ""
            )}
          </button>
        </div>
      );
    }
  };

  // List of courses with window & auto sized
  return (
    <React.Suspense fallback={<div>loading your data from unisg.ch..</div>}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            className="overflow-auto text-sm scrollbar-hide"
            height={height}
            itemCount={listLength}
            itemSize={75}
            width={width}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </React.Suspense>
  );
};
