export function ReturnSelectedEvents(events) {
	const selEvents = events.filter((event) => event.selected);
	return selEvents;
}

export function ReturnSelectedEventDates(events) {
	const selectedEventDates = events
		.map((event) => {
			event.calendar.map((cal) => {
				cal.selected = true;
				return cal;
			});
			return event.calendar;
		})
		.flat();
	return selectedEventDates;
}

export function OverlappingEventDates(selectedEventDates) {
	const overlapping = selectedEventDates.filter((eventDate) => {
		const overlappingEventDates = selectedEventDates.filter((eventDate2) => {
			//eventDate.overlapping = true;
			return (
				eventDate !== eventDate2 &&
        eventDate.courseNumber !== eventDate2.courseNumber &&
				eventDate.start <= eventDate2.end &&
				eventDate.end >= eventDate2.start
			);
		});
		return overlappingEventDates.length > 0;
	});
	return overlapping;
}

