import { selector } from "recoil";
import { selectedSemesterState } from "./selectedSemester";
// import { cleanedCoursesQuery } from "../../selectors/general/cleanedCourses";
import { addedLocalSelectedCoursesQuery } from "../../selectors/general/addedLocalSelectedCourses";

export const coursesSelectedSemesterQuery = selector({
    key: 'coursesSelectedSemester',
    get: async ({ get }) => {
        const selectedSemester = get(selectedSemesterState)
        // const semesterData = get(cleanedCoursesQuery)
        const semesterData = get(addedLocalSelectedCoursesQuery)

        const res = semesterData.find(semester => { return semester.id === selectedSemester.cisTermId })

        return res.data
    }

})