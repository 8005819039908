import React from "react";

// tutorial overlays that are displayed when user opens biddit for the first time
export const TutorialComponent = ({ setOpen, text }) => {
  return (
    <div className="absolute z-10 w-full h-full backdrop-filter backdrop-blur">
      <div className="flex items-end justify-center h-full text-center sm:block sm:p-0">
        <div className="inline-block max-w-sm p-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl">
          <div className="sm:flex sm:items-start">
            <div className="text-center sm:text-left">
              <p className="text-sm text-gray-500">{text}</p>
            </div>
          </div>
          <div className="mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500 sm:w-auto sm:text-sm"
              onClick={() => setOpen()}
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
