
import shsg_logo_line from "../../../../assets/SHSG_Logo_Line_green.png"

// Quick tutorial
const HowToBiddit = () => {
  return (
    <div className="h-full mx-4 prose prose-lg prose-indigo md:pt-4">
      <div className="text-lg font-medium text-gray-900 md:text-xl">Welcome to Biddit by <a target="_blank" rel="noreferrer" href="https://shsg.ch" className="text-hsg-700">SHSG</a>.</div>
      <div className="text-sm font-medium text-gray-500 md:text-base ">A quick Tutorial:</div>
      <ol className="text-xs md:text-sm">
        <li>Get course details by clicking on the course</li>
        <li>Search & filter for courses</li>
        <li>Select a course by clicking the "+"</li>
        <li>Swipe left to get the summary</li>
        <li>Swipe right to get the calendar</li>
      </ol>
      <div className="text-sm font-medium font-gray-500 md:text-base">
        Good luck bidding 🍀
      </div>
      <img src={shsg_logo_line} alt="SHSG Logo" className="h-12 w-48 object-contain pb-4 mx-auto"></img>
    </div>
  );
};

export default HowToBiddit;
