// Dependencies
import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { deleteCourse, saveCourse } from "../../../../../utils/data/apiData";

// List of all the courses a student has available
export const EventList = ({
  filteredEvents,
  SelectEvent,
  ToggleDetails,
  myCourses,
  accessToken
}) => {
  const [sortedEvents, setSortedEvents] = useState();
  const [listLength, setListLength] = useState(100);

  // Row component that is used by react-window List
  // Each row is one course
  const Row = ({ index, style }) => {
    const event = sortedEvents[index];
    return (
      <div
        key={index}
        className="flex w-full h-full overflow-hidden pb-2.5"
        style={style}
      >
        <div
          onClick={() => ToggleDetails(event && event.id)}
          className={`flex-1 p-2 rounded-lg shadow-sm overflow-hidden cursor-pointer hover:shadow-md transition duration-500 ease-in-out ${
            event && event.allocated
              ? "bg-neutral text-white font-medium"
              : event.selected
              ? event.overlapping
                ? "bg-warning text-white"
                : "bg-hsg-800 text-white"
              : "bg-white dark:bg-gray-200"
          }`}
        >
          <div className="pb-1">
            <p className="truncate">
              {event ? `${event.shortName}` : "Loading..."}
            </p>
          </div>
          <div
            className={`text-sm grid grid-cols-5 md:grid-cols-10 xl:grid-cols-10 2xl:grid-cols-10 ${
              event
                ? event.selected || event.allocated
                  ? "text-white "
                  : "text-gray-600"
                : ""
            }`}
          >
            <p className="hidden col-span-3 lg:block">
              {event ? `${event.courseNumber}` : "..."}
            </p>
            <p className="hidden col-span-2 md:block">
              {event ? `${event.ects} ECTS` : "..."}
            </p>
            <p className="col-span-3 truncate md:col-span-3 xl:col-span-3">
              {event ? `${event.classification} ` : "..."}
            </p>
            {event && event.avgRating !== null ? (
              <p className="col-span-2 truncate md:col-span-2 xl:col-span-2 flex">
                <StarIcon width={16} color="#386641" />
                {event.avgRating}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <button
          id="select_course"
          onClick={() => { SelectEvent(event && event.id); event.selected ? saveCourse(event, accessToken) : deleteCourse(event, accessToken) }}
          className={`flex justify-center items-center h-full w-custom64 shadow-sm rounded-lg ml-3 cursor-pointer hover:shadow-md transition duration-500 ease-in-out ${
            event && event.allocated
              ? "bg-neutral text-white font-medium"
              : event.selected
              ? event.overlapping
                ? "bg-warning text-white"
                : "bg-hsg-800 text-white"
              : "bg-white dark:bg-gray-200"
          }`}
        >
          {event ? (
            event.allocated ? (
              "A"
            ) : event.selected ? (
              <MinusIcon className="w-6 h-6" />
            ) : (
              <PlusIcon className="w-6 h-6 text-gray-800" />
            )
          ) : (
            ""
          )}
        </button>
      </div>
    );
  };

  // Sorting courses to that allocated > overlapping > selected > rest
  useEffect(() => {
    if (typeof filteredEvents !== "undefined" && filteredEvents !== null) {
      const EventSort = filteredEvents
        .sort((a, b) => b.overlapping - a.overlapping)
        .sort((a, b) => b.selected - a.selected)
        .sort((a, b) => b.allocated - a.allocated);
      setSortedEvents(EventSort);
      setListLength(EventSort.length);
    } else {
      setSortedEvents([]);
    }
  }, [filteredEvents, SelectEvent, myCourses]);

  // Skeleton placeholder until courses are loaded
  if (
    typeof sortedEvents === "undefined" ||
    sortedEvents === null ||
    sortedEvents.length === 0
  ) {
    return (
      <div className="w-full h-full bg-gray-100 rounded">
        <div className="flex w-full h-full flex-col gap-3 pb-1">
          <div className="h-full grid grid-cols-4 shadow-sm gap-4">
            <div className="h-full col-span-4  rounded-lg bg-white animate-pulse justify-content"></div>
          </div>
          <div className="h-full grid grid-cols-4 shadow-sm gap-4">
            <div className="h-full col-span-4  rounded-lg bg-white animate-pulse justify-content"></div>
          </div>{" "}
          <div className="h-full grid grid-cols-4 shadow-sm gap-4">
            <div className="h-full col-span-4  rounded-lg bg-white animate-pulse justify-content"></div>
          </div>{" "}
          <div className="h-full grid grid-cols-4 shadow-sm gap-4">
            <div className="h-full col-span-4  rounded-lg bg-white animate-pulse justify-content"></div>
          </div>
        </div>
      </div>
    );
  }

  // List of courses with window & auto sized
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="overflow-auto scrollbar-hide"
          height={height}
          itemCount={listLength}
          itemSize={75}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};
