import React
// ,{ Fragment }
  from "react";
import {
  // useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil";
import { coursesTakenForRatingState } from "../../../recoil/atoms/hsg/coursesTakenForRatings";
// import { ReviewCourse } from "../../leftCol/sideNav/ReviewCourse";
// import { XIcon } from "@heroicons/react/outline";
// import { Dialog, Transition } from "@headlessui/react";
import { reviewCourseIdState, reviewCourseNameState, reviewSemesterState } from "../../../recoil/atoms/general/reviewCourse";
import { reviewMenuModalState } from "../../../recoil/atoms/general/reviewMenuModal";

export const ThumbsUp = (element) => {
  // const [openCourses] = useRecoilState(coursesTakenForRatingState);
  // const [isOpen, setOpen] = React.useState(false);
  // console.debug("openCourses", openCourses);
  const setCourseId = useSetRecoilState(reviewCourseIdState);
  const setCourseName = useSetRecoilState(reviewCourseNameState);
  const setSemester = useSetRecoilState(reviewSemesterState);
  const setOpen = useSetRecoilState(reviewMenuModalState);

  const coursesToBeRated = useRecoilValue(coursesTakenForRatingState)

  function setRatingCourse(element) {
    const ratingCourseInfo = coursesToBeRated.filter(course => course.courseName === element.shortName)[0]
    setCourseId(ratingCourseInfo.courseId)
    setCourseName(ratingCourseInfo.courseName)
    setSemester(ratingCourseInfo.semesterName)
    setOpen(true)
  }

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="h-4 w-4"
        onClick={(e) => setRatingCourse(element.event)}
        // onClick={() => setOpen(!isOpen)}
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
        />
      </svg>
      {/* {isOpen ? (
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            // initialFocus={cancelButtonRef}
            onClose={setOpen}
            open={isOpen}
          >
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative w-1/2 px-4 pt-5 pb-4 overflow-hidden text-center transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:p-6">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="p-1 text-gray-400 bg-white rounded-md hover:text-gray-500"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                    <ReviewCourse courseName={event.shortName} />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null} */}
    </div>
  );
};
