import { useRecoilState } from "recoil";
import { courseSearchInputState } from "../../../../../recoil/atoms/general/courseSearchInput";
import { SearchIcon } from "@heroicons/react/outline";

export function Search() {
  const [courseSearchInput, setCourseSearchInput] = useRecoilState(
    courseSearchInputState
  );

  const handleCourseSearchInputChanges = (e) => {
    const cleanValue = e.target.value.replace(/[&/\\#+()$~%'"*?<>{}]/g, "");
    setCourseSearchInput(cleanValue);
  };

  return (
    <form className="mb-4">
      {/* <label for="courseSearch" class="block text-sm font-medium text-gray-700">Search</label> */}
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="courseSearch"
          id="courseSearch"
          className="block w-full pl-10 border-gray-300 rounded-md shadow-sm focus:border-hsg-600 focus:ring-hsg-600 sm:text-sm"
          placeholder="Search"
          value={courseSearchInput || ""}
          onChange={handleCourseSearchInputChanges || ""}
        />
      </div>
    </form>
  );
}
