import React from "react";
import { TitleElement } from "./TitleElement";
import { TABLE_COLS } from "./constants";
import { StatusElement } from "./StatusElement";
import { selectedTabState } from "../../../recoil/atoms/general/selectedTab";
import { selectedCourseState } from "../../../recoil/atoms/general/selectedCourse";
import { useSetRecoilState } from "recoil";
import { useRecoilValue } from "recoil";
import {
  localCustomGradesState,
  useLocalCustomGrades,
} from "../../../recoil/atoms/custom_grades/localCustomGrades";

export const CourseElement = ({
  row,
  level,
  previousLevel,
  previousDescription,
}) => {
  const setSelectedCourse = useSetRecoilState(selectedCourseState);
  const setSelectedTab = useSetRecoilState(selectedTabState);
  const localCustomGrades = useRecoilValue(localCustomGradesState);
  const updateLocalCustomGrades = useLocalCustomGrades();

  const [grade, setGrade] = React.useState();



  function checkIfCourseIsDone(row) {
    if (row.mark) {
        return true;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    // check if row.id or row.courseNumber is in customGrades
  
    

    if (grade === undefined || grade === null) {
      if (row.courseNumber && localCustomGrades[row.courseNumber]) {
        setGrade(localCustomGrades[row.courseNumber]);
      } else if (row.shortName && localCustomGrades[row.shortName]) {
        setGrade(localCustomGrades[row.shortName]);
      }
    }

    if (isNaN(parseFloat(grade))) {
      setGrade("");
    }

    console.debug("localCustomGrades", localCustomGrades);
    // else if (row.mark) {
    //   let id = row.courseNumber ? row.courseNumber : row.shortName;
    //   setGrade(row.mark);
    //   updateLocalCustomGrades(id, row.mark);
    // }
  }, [
    localCustomGrades,
    row.shortName,
    row.courseNumber,
    row.mark,
    grade,
    // updateLocalCustomGrades,
  ]);

  if (row.isTitle === false || !row.isTitle) {
    level = row.hierarchy
      ? row.hierarchy.split(".").length - 2
      : level + 1 || previousLevel + 1;
    previousDescription = row.description;

    function courseSelector(fullEvent) {
      if (fullEvent) {
        setSelectedCourse(fullEvent);
        setSelectedTab(0);
      }
    }


    function handleGradeChange() {
      // const grade = event.target.value;
      if (checkIfValidGrade(grade)) {
        let id = row.courseNumber ? row.courseNumber : row.shortName;
        console.debug("handle", id, grade);
        updateLocalCustomGrades(id, grade);
      } else {
        console.debug("invalid grade");
      }
    }

    function checkIfValidGrade(grade) {
      if (
        (!isNaN(parseFloat(grade)) && 1.0 <= grade && grade <= 6.0) ||
        grade === "" ||
        grade === null ||
        grade === undefined
      ) {
        console.debug("valid");

        return true;
      }
      console.debug("invalid");
      return false;
    }

    function isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    }

    function isInt(n) {
      return Number(n) === n && n % 1 === 0;
    }

    // write function to listen for enter keypress and make enter behave like tab

    function handleOnBlur(e) {
      setGrade(parseFloat(e.target.value).toFixed(2));
      handleGradeChange();
    }

    // when enter is pressed it should execute handleOnBlur
    function handleKeyPress(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        e.target.blur();
      }
    }

    return (
      <div className="flex flex-col text-sm">
        <div className={`flex flex-row px-2`}>
          <TitleElement
            title={StatusElement(row)}
            parentStyle="flex w-12 items-center text-center justify-center"
          />
          <TitleElement
            title={row.name || row.shortName || row.description}
            parentStyle={`flex-1 truncate pl-${level * 2} ${
              row.courseNumber && row.courseNumber !== "00,000,1.00"
                ? "cursor-pointer"
                : ""
            }`}
            onClick={() =>
              row.courseNumber && row.courseNumber !== "00,000,1.00"
                ? courseSelector(row)
                : null
            }
          />

          <div
            className={`grid grid-flow-col w-1/2 text-left gap-2 items-center text-sm grid-cols-${TABLE_COLS}`}
          >
            <TitleElement
              title={
                row.achievementLanguage ||
                row.lang ||
                (row.courseLanguage && row.courseLanguage.code)
              }
              parentStyle="text-xs text-right"
              span={1}
            />
            <TitleElement
              title={
                (row.semester && row.semester.replace(" ", "")) ||
                row.semesterAbbreviation
              }
              parentStyle="text-xs px-4 text-right"
              span={2}
            />
            <TitleElement
              title=""
              parentStyle=""
              span={6}
              childStyle="grid grid-cols-5 text-xs text-right"
            >
              <TitleElement />
              <TitleElement />
              <TitleElement
                title={
                  row.sumOfCredits
                    ? parseFloat(row.sumOfCredits).toFixed(2)
                    : row.credits
                    ? (row.credits / 100).toFixed(2)
                    : ""
                }
                parentStyle="font-semibold text-right"
                span={2}
              />

              <TitleElement title="" parentStyle="font-semibold text-right" />
            </TitleElement>
            <TitleElement title={row.mark} parentStyle="text-right" span={1} />

            {/* Custom Grade */}
            {/* check if input is valid */}

            <div
              className = "bottom-0 w-full p-0 m-0 text-sm text-right text-gray-700 align-middle border-white rounded-sm outline-none focus:ring-main focus:border-main" 
            >
              {
                checkIfCourseIsDone(row)  ? (
                  <div>{row.mark}</div>
                ) : (
                  
                  <input
                  // when pressing enter it should behave like tab
                  onKeyDown={(e) => handleKeyPress(e)}
                  pattern="[0-9]"
              type="text"
              id="intTextBox"
              className={`bottom-0 w-full p-0 m-0 text-sm text-right text-gray-700 align-middle border-white rounded-sm outline-none focus:ring-main focus:border-main ${
                grade === "" || grade === null || grade === undefined
                ? "bg-gray-100 hover:bg-main hover:opacity-20"
                  : ""
                }`}
              placeholder={""}
              value={isFloat(grade) || isInt(grade) ? grade.toFixed(2) : grade}
              onChange={(e) =>
                checkIfValidGrade(e.target.value)
                ? e.target.value !== ""
                ? setGrade(e.target.value)
                : setGrade(e.target.value) && handleGradeChange()
                : setGrade("") && handleGradeChange()
              }
              onBlur={(e) => handleOnBlur(e)}
              />
                )
              }
            
              </div>
          </div>
        </div>
      </div>
    );
  } else if (!row.isTitle) {
    return <div>{row.name}</div>;
  } else {
    return null;
  }
};
