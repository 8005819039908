import { atom, selector } from "recoil";
import axios from "axios";

import { tokenQuery } from "../../Token";

export const avgCourseRatingsState = atom({
    key: 'avgCourseRatings',
    default: selector({
        key: 'avgCourseRatings/Default',
        get: async ({ get }) => {
            const token = get(tokenQuery)

            try {
                const res = await axios.get(`https://api.shsg.ch/course-ratings`, {
                    headers: {
                        "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                });
                return res.data.filter((course) => course._id !== '7,721,1.00')
            } catch (err) {
                try {
                    const res = await axios.get(`https://api.shsg.ch/course-ratings`, {
                        headers: {
                            "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                            "X-RequestedLanguage": "EN",
                            "API-Version": "1",
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    return res.data.filter((course) => course._id !== '7,721,1.00')
                } catch (err) {
                    return console.log(err)
                }
            }
        }
    })
})