export const tooltipTexts = {
  topic:
    "Were the topics covered in class what you expected from the course information sheet?",
  lecture: "How well was the lecture structured?",
  materials:
    "How well did the provided course materials support your education?",
  professor: "How well did the professor perform?",
  exam: "Was the exam fair?",
  workload: "Was the workload appropriate for the ECTS?",
};
