// Sub-Component of the summary component in the top left corner
export const AllocatedCourses = ({ selectedEvents }) => {
  if (selectedEvents.length === 0) {
    return (
      <div className="text-sm font-medium text-gray-500 md:text-base">
        <div>Select a course first.</div>
      </div>
    );
  }
  return (
    <div className="flex-1 overflow-auto scrollbar-hide">
      <div className="mb-2 text-sm font-medium text-gray-600 md:text-base">
        <div>Number of events per course:</div>
        <div className="text-xs">
          We get those from HSG, if it's 0, ask them 🤷🏻‍♂️
        </div>
      </div>
      {selectedEvents.map((event, i) => {
        return (
          <div className="grid grid-cols-4 py-1">
            <div
              key={i}
              className="col-span-3 text-sm font-medium text-gray-500 truncate md:text-base"
            >
              {event.shortName}
            </div>
            <div className="col-span-1 text-sm font-medium text-right text-gray-500 truncate md:text-base">
              {event.calendar.length}
            </div>
          </div>
        );
      })}
    </div>
  );
};
