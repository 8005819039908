import React from "react";

import { LockOpen } from "../util/LockOpen";
import Tooltip from "../util/Tooltip";
import { LockClosed } from "../util/LockClosed";
import { ThumbsUp } from "../util/ThumbsUp";

export const StatusElement = (event) => {
    switch (event.status) {
        case "toBeRated":
            return (
                <Tooltip message={"Rate this course."}>
                    <ThumbsUp event={event ? event : null} />
                </Tooltip>
            );
        case "allocated":
            return (
                <Tooltip
                    message={
                        "This course is allocated and can only be dropped in the bidding tool."
                    }
                >
                    <LockClosed clg="w-4 h-4" />
                </Tooltip>
            );
        case "selected":
            return (
                <Tooltip message={"Unselect this course."}>
                    <LockOpen clg="w-4 h-4" event={event ? event : null} />
                </Tooltip>
            );
        case "completed":
            return <div className="w-7" />;
        default:
            return <div></div>;
    }
};
