// Dependencies
import {React, useState} from "react";

// Components
import { EventListInputs } from "./inputs/EventListInputs";
import { EventList } from "./eventList/EventList";
import { TutorialComponent } from "../../../../utils/tutorial/TutorialComponent";

// Container for filter & search inputs and the list of courses.
export const EventListContainer = ({
  SelectEvent,
  selectedEvents,
  setFilterToggle,
  ToggleDetails,
  filterActive,
  searchValue,
  setSearchValue,
  filteredEvents,
  myCourses,
  accessToken
}) => {
  // Checking cookies if tutorial for event list has been closed before --> only shows on the first time
  const [open, setOpen] = useState(localStorage.getItem("eventlisttutorial")==="false" ? false : true);

  function updateCookie() {
    localStorage.setItem("eventlisttutorial", false)
    setOpen(false)
  }
  
  return (
    <div className="relative flex flex-col w-full px-4 py-4">
      {/* Only showing tutorial if it hasn't been closed before according ot cookies */}
      {open ? 
      <div className="absolute z-10 w-full h-full -m-4">
        <TutorialComponent setOpen={updateCookie} text={"List of all the courses you can take next semester. Simply search by name or use the filters. Found a course that looks interesting? Hit the + icon to add it to the list or click on the name for further information."}/>
      </div> : <div></div> }
      
      {/* Inputs and course list */}
      <div className="pb-4">
        <EventListInputs
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setFilterToggle={setFilterToggle}
          filterActive={filterActive}
        />
      </div>
      <div className="flex-initial w-full h-full pb-3 overflow-auto rounded-lg">
        <EventList
          filteredEvents={filteredEvents}
          SelectEvent={SelectEvent}
          ToggleDetails={ToggleDetails}
          myCourses={myCourses}
          accessToken={accessToken}
        />
      </div>
    </div>
  );
};
