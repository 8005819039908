import { atom, selector } from "recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  AddCourseGrade,
  RemoveCourseGrade,
  customGradesState,
} from "./customGrades";
import { tokenQuery } from "../../Token";

export const localCustomGradesState = atom({
  key: "localCustomGrades",
  default: selector({
    key: "localCustomGrades/Default",
    get: async ({ get }) => {
      const defaultValues = {
        qlsoadsfsdf: 6,
      };
      return { ...defaultValues, ...get(customGradesState) };
    },
  }),
});

export const useLocalCustomGrades = () => {
  const [localCustomGrades, setLocalCustomGrades] = useRecoilState(
    localCustomGradesState
  );

  const token = useRecoilValue(tokenQuery);

  function updateLocalCustomGrades(courseNumber, grade) {
    console.debug("update", localCustomGrades, courseNumber, grade);
    if (grade === "" || grade === null || grade === undefined) {
      console.debug("remove", localCustomGrades, courseNumber, grade);
      const { [courseNumber]: _, ...rest } = localCustomGrades;
      RemoveCourseGrade(token, courseNumber);
      setLocalCustomGrades(rest);
    } else {
      setLocalCustomGrades({
        ...localCustomGrades,
        [courseNumber]: grade,
      });
      AddCourseGrade(token, courseNumber, grade);
    }
  }

  return updateLocalCustomGrades;
};
