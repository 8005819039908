export function GetChosenCourses(data, options) {
  const chosenCourses = [];

  function getLabelFromSemesterId(id) {
    const found = options.find((option) => option.cisTermId === id);

    return found.label.split("-")[0];
  }
  data.map((course) => {
    if (course.allocated === true || course.selected === true) {
      return chosenCourses.push({
        ...course,
        semesterName: getLabelFromSemesterId(course.semesterId),
      });
    }
    return null;
  });

  return chosenCourses;
}
