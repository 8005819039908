import { atom, selector } from "recoil";
import axios from "axios";

import { tokenQuery } from "../../Token";

export const myStudyLevelDurationsState = atom({
    key: 'myStudyLevelDruation',
    default: selector({
        key: 'myStudyLevelDurations/Default',
        get: async ({ get }) => {
            const token = get(tokenQuery)

            try {
                const res = await axios.get(`https://integration.unisg.ch/StudyApi/MyStudyLevelDurations`, {
                    headers: {
                        "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                });
                return res.data
            } catch (err) {
                try {
                    const res = await axios.get(`https://integration.unisg.ch/StudyApi/MyStudyLevelDurations`, {
                        headers: {
                            "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                            "X-RequestedLanguage": "EN",
                            "API-Version": "1",
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    return res.data
                } catch (err) {
                    return console.log(err)
                }
            }
        }
    })
})