// Dependencies
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

// Other
import "./calendar.css";
import "./fixedEvents.json";
import { TutorialComponent } from "../../../utils/tutorial/TutorialComponent";

// Implementation of calendar widget
export default function Calendar({ selectedEventDates, ToggleDetails }) {
  const fixedEvents = require("./fixedEvents.json").flat();
  const clickEvent = function (info) {
    if (info.event._def.extendedProps.selected === true) {
      ToggleDetails(info.event._def.extendedProps.courseId);
    }
  };
  const finalEvents = [...selectedEventDates, ...fixedEvents];

  // Information on hovering
  const hoverEvent = (info) => {
    let text2 = info.event.title;
    info.el.setAttribute("data-tip", `${text2}`);
    ReactTooltip.rebuild();
  };

  // Text to be displayed when hovering
  function renderEventContent(eventInfo) {
    return (
      <>
        <p className="truncate">{eventInfo.timeText}</p>
        <p className="font-bold truncate">{eventInfo.event.title}</p>
        <p className="truncate text-red">
          {eventInfo.event._def.extendedProps.room}
        </p>
      </>
    );
  }

  const [open, setOpen] = useState(
    localStorage.getItem("calendartutorial") === "false" ? false : true
  );

  function updateCookie() {
    localStorage.setItem("calendartutorial", false);
    setOpen(false);
  }

  const calendarRef = React.useRef();
  const WeekChange = (value) => {
    let calendarApi = calendarRef.current.getApi();
    value === "next" ? calendarApi.next() : calendarApi.prev();
  };

  // UNCOMMENT FOR CUSTOM BUTTON
  const Today = (value) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  };

  var cal = {
    firstDay: "1",
    dayHeaderFormat: {
      weekday: "short",
      day: "numeric",
      month: "short",
    },
    eventColor: "#006625",
  };

  return (
    <div className="w-full h-full pb-3">
      <ReactTooltip />
      <div className="flex w-full h-full">
        <div className="flex flex-col items-center justify-center ease-in-out focus-within mt-7 relative">
          <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300 absolute top-0 text-gray-500">
            <div onClick={Today}>Today</div>
          </div>
          <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300">
            <ChevronLeftIcon
              aria-hidden="true"
              className="w-10 h-full text-gray-500 align-middle "
              onClick={() => WeekChange("prev")}
            />
          </div>
        </div>

        {/* calendar */}
        <div className="relative flex-1">
          {open ? (
            <div className="absolute z-10 w-full h-full">
              <TutorialComponent
                open={open}
                setOpen={updateCookie}
                text={
                  "A preview of all the events you're going to have this semester."
                }
              />
            </div>
          ) : (
            <></>
          )}

          <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin, dayGridPlugin]}
            initialView="timeGridWeek"
            height="100%"
            events={finalEvents}
            firstDay={cal.firstDay}
            slotMinTime="08:00:00"
            slotMaxTime="22:00:00"
            hiddenDays="[0]"
            eventColor="#006625"
            expandRows={true}
            slotEventOverlap={false}
            eventClick={clickEvent}
            eventContent={renderEventContent}
            eventMouseEnter={hoverEvent}
            allDaySlot={false}
            headerToolbar={false}
            footerToolbar={false}
            slotLabelFormat={cal.eventTimeFormat}
            slotLabelInterval={cal.slotLabelInterval}
            dayHeaderFormat={cal.dayHeaderFormat}
          />

          {/* <div className="flex justify-center content-center pt-2">
            <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300">
              <ChevronLeftIcon
                aria-hidden="true"
                className="w-10 h-full text-gray-500 align-middle "
                onClick={() => WeekChange("prev")}
              />
            </div>

            <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300 my-auto">
              Today
            </div>

            <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300">
              <ChevronRightIcon
                aria-hidden="true"
                className="w-10 text-gray-500 align-middle "
                onClick={() => WeekChange("next")}
              />
            </div>
          </div> */}
        </div>
        <div className="flex items-center mt-7">
          <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300">
            <ChevronRightIcon
              aria-hidden="true"
              className="w-10 text-gray-500 align-middle "
              onClick={() => WeekChange("next")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
