import React, { useEffect, useState } from "react";

// Sub-Component of the analysis component in the top left corner
export const CreditsByClass = ({ selectedEvents }) => {
  const [creditsByClass, setCreditsByClass] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue.ects
      );
      return result;
    }, {});
  };

  useEffect(() => {
    if (selectedEvents && selectedEvents !== []) {
      // Step to not double count events that also include a lab/exercise
      const selectedEventsFiltered = selectedEvents.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) => t.parentCourseNumber === thing.parentCourseNumber
          )
      );

      const groupedByClass = groupBy(selectedEventsFiltered, "classification");
      let creditsByGroup = {};

      Object.keys(groupedByClass).map((classification) => {
        return (creditsByGroup[classification] = groupedByClass[
          classification
        ].reduce((accumulator, currentValue) => accumulator + currentValue));
      });
      setCreditsByClass(creditsByGroup);

      if (Object.values(groupedByClass).flat().length !== 0) {
        setTotalCredits(
          Object.values(groupedByClass)
            .flat()
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        );
      }
    }
  }, [selectedEvents]);

  if (Object.keys(creditsByClass).length === 0) {
    return (
      <div className="text-sm font-medium text-gray-500 md:text-base">
        <div>Select a course first.</div>
      </div>
    );
  } else {
    return (
      <div className="flex-1 overflow-auto scrollbar-hide">
        {Object.keys(creditsByClass).map((key) => {
          return (
            <div className="grid grid-cols-4 py-1" key={key}>
              <dt className="col-span-3 text-sm font-medium text-gray-500 truncate md:text-base">
                {key}
              </dt>
              <dd className="text-sm text-right text-gray-700 md:text-base ">
                <span className="font-medium">{creditsByClass[key]}</span>
                <span className="hidden sm:inline"> ECTS</span>
              </dd>
            </div>
          );
        })}
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
        </div>
        <div className="grid grid-cols-4 py-1">
          <dt className="col-span-3 text-sm font-medium text-gray-700 md:text-base">
            Total
          </dt>
          <dd className="mt-1 text-sm text-right text-gray-700 md:text-base">
            <span className="font-medium">{totalCredits}</span>
            <span className="hidden sm:inline"> ECTS</span>
          </dd>
        </div>
      </div>
    );
  }
};
