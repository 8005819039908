import { ExclamationIcon } from "@heroicons/react/outline";
import { ErrorCodeBlock } from "./ErrorCodeBlock";
import { SendErrorButton } from "./SendErrorButton";

function getErrorMessage(error) {
    const errorString = error.toString().toLowerCase();
    if (errorString.includes('accesstoken') || errorString.includes('courselink')) {
        return "Please try refreshing the page and logging in again!";
    }
    return "Biddit was unable to combine various pieces of data it received from the HSG servers.";
}

export function ErrorFallback({ error, resetErrorBoundary }) {
    const errorMessage = getErrorMessage(error);

    return (
        <div className="rounded-md bg-yellow-50 p-4 m-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                        An error occured
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                        <p>
                            Unfortunately, there seems to be an error with this
                            feature.
                            <br />
                            {errorMessage}
                            <br />
                            <br />
                            Please send us an email by clicking the{" "}
                            <span className="italic font-bold">
                                Send Error
                            </span>{" "}
                            button or copy the error message below and send us
                            an email at{" "}
                            <span className="text-yellow-700 font-bold">
                                biddit@shsg.ch
                            </span>{" "}
                            so we can fix the issue for you and others.
                        </p>
                    </div>
                    <ErrorCodeBlock error={error} />
                    <SendErrorButton
                        error={error}
                        buttonColor={"bg-yellow-400"}
                        buttonHovorColor={"bg-yellow-600"}
                    />
                </div>
            </div>
        </div>
    );
}
