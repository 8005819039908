import React, { memo } from "react";

const Tooltip = memo((props) => {
  return (
    <div className="relative group">
      <div className="absolute px-2 py-1 text-xs text-black -translate-x-1/2 bg-white rounded opacity-0 pointer-events-none left-1/2 whitespace-nowrap group-hover:z-50 group-hover:opacity-100">
        {props.message}
      </div>
      {props.children}
    </div>
  );
});

Tooltip.displayName = "Tooltip";
export default Tooltip;
