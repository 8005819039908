import { selector } from "recoil";
// import { cleanedCoursesQuery } from "./cleanedCourses";
import { addedLocalSelectedCoursesQuery } from "./addedLocalSelectedCourses";
import { coursesTakenForRatingState } from "../../atoms/hsg/coursesTakenForRatings";

export const selectedCoursesQuery = selector({
  key: "selectedCourses",
  get: async ({ get }) => {
    // TEMPORARY AND SHOULD PROBABLY BE AN ATOM!!!
    const semesters = get(addedLocalSelectedCoursesQuery);
    const openCourses = get(coursesTakenForRatingState);

    function checkIfCourseIsToBeRated(courseName) {
      for (let i = 0; i < openCourses.length; i++) {
        if (openCourses[i].courseName === courseName) {
          return true;
        }
      }
      return false;
    }

    return semesters.map((semester) => {
      return semester.data
        .filter(
          (course) => course.selected === true || course.allocated === true
        )
        .map((course) => {
          return {
            ...course,
            status: checkIfCourseIsToBeRated(course.shortName)
              ? "toBeRated"
              : course.allocated
              ? "allocated"
              : "selected",
          };
        });
    });
  },
});
