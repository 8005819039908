import { atom, selector } from "recoil";
import { coursesSelectedSemesterQuery } from "./coursesSelectedSemester";

export const creditsFilterOptionsState = atom({
  key: "creditsFilterOptions",
  default: selector({
    key: "creditsFilterOptions/Default",
    get: async ({ get }) => {
      const courses = get(coursesSelectedSemesterQuery);

      const creditsFilterOptions = [
        ...new Set(courses.map((course) => course.credits)),
      ]
        .sort()
        .map((credit) => {
          return { label: (credit / 100).toFixed(2), value: credit };
        });

      return creditsFilterOptions;
    },
  }),
});
