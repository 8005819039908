import { atom, selector } from "recoil";
import { relevantCisIdsQuery } from "../../selectors/hsg/relevantCisIds";
import { tokenQuery } from "../../Token";
import axios from "axios";

export const courseInformationSheetsState = atom({
    key: 'courseInformationSheets',
    default: selector({
        key: 'courseInformationSheets/Default',
        get: async ({ get }) => {
            const token = get(tokenQuery)
            const enrolledSemesters = get(relevantCisIdsQuery)

            async function loadCourses(enrolledSemester) {
                const cisTermId = enrolledSemester.cisTermId

                try {
                    const res = await axios.get(
                        `https://integration.unisg.ch/EventApi/CourseInformationSheets/myLatestPublishedPossiblebyTerm/${cisTermId}`,
                        {
                            headers: {
                                "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                                "X-RequestedLanguage": "EN",
                                "API-Version": "1",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    return { id: cisTermId, data: res.data };
                } catch (err) {
                    try {
                        const res = await axios.get(
                            `https://integration.unisg.ch/EventApi/CourseInformationSheets/myLatestPublishedPossiblebyTerm/${cisTermId}`,
                            {
                                headers: {
                                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                                    "X-RequestedLanguage": "EN",
                                    "API-Version": "1",
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
    
                        return { id: cisTermId, data: res.data };
                    } catch (err) {
                        return console.log(err);
                    }
                }
            }
            const data = await Promise.all(enrolledSemesters.map(enrolledSemester => loadCourses(enrolledSemester))).then((results) => { return results })

            return data
        }
    })
})