import { atom, selector } from "recoil";
// import { coursesSelectedSemesterQuery } from "./coursesSelectedSemester";
import { scorecardGraduationState } from "../hsg/scorecardGraduation";

// export const classificationsFilterOptionsState = atom({
//     key: "classificationFilterOptions",
//     default: selector({
//         key: "classificationsFilterOptions",
//         get: async ({ get }) => {
//             const courses = get(coursesSelectedSemesterQuery);

//             const classificationsFilterOptions = [
//                 ...new Set(courses.map((course) => course.classification)),
//             ]
//                 .sort()
//                 .map((classification) => {
//                     return { label: classification, value: classification };
//                 });

//             return classificationsFilterOptions;
//         },
//     }),
// });

export const classificationsFilterOptionsState = atom({
  key: "classificationsFilterOptions2",
  default: selector({
    key: "classificationsFilterOptions/Default",
    get: async ({ get }) => {
      //console.log("x7 get(scorecardGraduationState).items[0]", get(scorecardGraduationState).items[0])
      //console.log("x7 get(scorecardGraduationState).items[1]", get(scorecardGraduationState).items[1])

      const scorecardItems = get(scorecardGraduationState).items;
      const mainItems = scorecardItems[0]?.items || [];

      let data = mainItems.map((group) => ({
        label: group.description,
        options: flattenScorecard(group),
      }));

      const contextualStudies = scorecardItems[1];
      if (
        contextualStudies &&
        contextualStudies.shortName === "Contextual Studies"
      ) {
        const contextualOptions =
          contextualStudies.items?.flatMap((item) => {
            if (item.shortName === "Skills and Languages") {
              // split up if wrong format from API
              return [
                { value: "Skills", label: "Skills" },
                { value: "Languages", label: "Languages" },
              ];
            }
            return {
              value: item.shortName,
              label: item.shortName,
            };
          }) || [];

        data.push({
          label: "Contextual Studies",
          options: contextualOptions,
        });
      }

      return data;
    },
  }),
});

function flattenScorecard(topScorecard) {
  const temp = (topScorecard.items || []).map((bottomScorecard) => {
    // fix map issue
    if (
      // ["Compulsory Subjects", "Pflichtbereich"].includes(
      //     bottomScorecard.description
      // ) ||
      bottomScorecard.items &&
      (bottomScorecard.items.filter((item) => item.isTitle) || []).length > 0
    ) {
      return flattenScorecard(bottomScorecard);
    } else {
      return {
        value: bottomScorecard.description,
        label: bottomScorecard.description,
      };
    }
  });
  return temp.flat();
}
