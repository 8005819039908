import { selector } from "recoil";
import { scorecardGraduationState } from "../../atoms/hsg/scorecardGraduation";

export const compulsoryStudiesRemapperSelector = selector({
    key: "compulsoryStudiesRemapper",
    get: async ({ get }) => {
        const scorecard = get(scorecardGraduationState).items;

        function remapping(classification) {
            if (
                ["Compulsory Subjects", "Pflichtbereich"].includes(
                    classification.description
                )
            ) {
                return (classification.items || []).map((item) => item.description); // fix map issue (22.08.24)
            } else if (classification.hasOwnProperty("items") && Array.isArray(classification.items)) {  // fix map issue (22.08.24)
                return classification.items.map((item) => remapping(item));
            }
            return []; // Return an empty array if no conditions are met (22.08.24)
        }

        const compulsoryStudies = scorecard && scorecard.length > 0
            ? remapping(scorecard[0])
                .flat(10)
                .filter((item) => item)
            : [];

        return compulsoryStudies;
    },
});
