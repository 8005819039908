export const studienOrdnungAssessmentStandard = {
    creditsDe: null,
    creditsEn: null,
    creditsFulfilledDe: null,
    creditsFulfilledEn: null,
    isProcessing: false,
    items: [
        {
            items: [
                {
                    items: [
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.50",
                                    minCredits: "5.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Business Administration A (BWL A)",
                                    id: 3461,
                                    shortName: "Business Administration A (BWL A)",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.50",
                                    minCredits: "5.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Economics A (VWL A)",
                                    id: 3461,
                                    shortName: "Economics A (VWL A)",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.50",
                                    minCredits: "5.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Law I A (RW I A)",
                                    id: 3461,
                                    shortName: "Law I A (RW I A)",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.00",
                                    minCredits: "5.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Academic Term Paper",
                                    id: 3461,
                                    shortName: "Academic Term Paper",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.50",
                                    minCredits: "5.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Business Administration B (BWL B)",
                                    id: 3461,
                                    shortName: "Business Administration B (BWL B)",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.50",
                                    minCredits: "5.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Economics B (VWL B) ",
                                    id: 3461,
                                    shortName: "Economics B (VWL B) ",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "5.50",
                                    minCredits: "5.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Law I B (RW I B)",
                                    id: 3461,
                                    shortName: "Law I B (RW I B)",
                                },
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.3458.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "38.00",
                            minCredits: "38.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Compulsory Subjects",
                            id: 3458,
                            shortName: "Compulsory Subjects",
                        },
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "3.50",
                                    minCredits: "3.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Mathematics A",
                                    id: 3461,
                                    shortName: "Mathematics A",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "3.50",
                                    minCredits: "3.50",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Mathematics B",
                                    id: 3461,
                                    shortName: "Mathematics B",
                                }
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "7.00",
                            minCredits: "7.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Core Electives",
                            id: 3457,
                            shortName: "Core Electives",
                        },
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3452.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "45.00",
                    minCredits: "45.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: null,
                    description: "Core Studies",
                    id: 3452,
                    shortName: "Core Studies",
                },
                {
                    items: [
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "3.00",
                            minCredits: "3.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Introduction to Academic Writing ",
                            id: 3461,
                            shortName: "Introduction to Academic Writing ",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "2.00",
                            minCredits: "2.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Cultural and Social Sciences A",
                            id: 3461,
                            shortName: "Cultural and Social Sciences A",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "4.00",
                            minCredits: "4.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Language",
                            id: 3461,
                            shortName: "Language",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "4.00",
                            minCredits: "4.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Integrative Project",
                            id: 3461,
                            shortName: "Integrative Project",
                        },
                        {
                            items: [],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3460.3461.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "2.00",
                            minCredits: "2.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Cultural and Social Sciences B",
                            id: 3461,
                            shortName: "Cultural and Social Sciences B",
                        },
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3460.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "15.00",
                    minCredits: "15.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: null,
                    description: "Contextual Studies",
                    id: 3460,
                    shortName: "Contextual Studies",
                },
            ],
            achievementLanguage: null,
            gradeText: "",
            hierarchy: ".3451.",
            isTitle: true,
            mark: null,
            maxCredits: "60.00",
            minCredits: "60.00",
            mncp: "0.00",
            scoreCardMask: 1,
            semester: "",
            studyPlanMainFocus: null,
            sumOfCredits: null,
            description: "Assessment Year",
            id: 3451,
            shortName: "Assessment Year",
        },
    ],
    minCreditsDe: null,
    minCreditsEn: null,
    scoreCardLinks: [],
    showMncp: true,
    id: 0,
};