import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ThumbUpIcon, XIcon } from "@heroicons/react/outline";
import ReactStars from "react-rating-stars-component";
import TextareaAutosize from "react-textarea-autosize";
import ReactTooltip from "react-tooltip";

// Button for AboutModal
export const ReviewButton = ({ toBeRated, postRating }) => {
  const [open, setOpen] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);
  const [openCourses, setOpenCourses] = useState([]);
  const [submittable, setSubmittable] = useState(false);

  // this is used to reset the reviews when the form is submitted
  const [reset, setReset] = useState(false);

  // could obviously be done with a single state, but :shrug:
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");

  const [semester, setSemester] = useState("HS22");
  const [comment, setComment] = useState("");
  const [topic, setTopic] = useState(null);
  const [exam, setExam] = useState(null);
  const [lecture, setLecture] = useState(null);
  // const [professor, setProfessor] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [workload, setWorkload] = useState(null);

  // this object can ultimately be passed to the backend
  // check the handleSubmit function for more details
  const review = {
    courseNumber: courseId,
    courseTitle: courseName,
    semester: semester,
    ratings: {
      topic: topic,
      lecture: lecture,
      materials: materials,
      // professor: professor,
      exam: exam,
      workload: workload,
    },
    comment: comment,
  };

  useEffect(() => {
    // setOpenCourses([
    //   {
    //     courseId: "1,001",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 1"
    //   },
    //   {
    //     courseId: "1,002",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 2",
    //   },
    //   {
    //     courseId: "1,003",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 3",
    //   },
    //   {
    //     courseId: "1,004",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 4",
    //   },
    //   {
    //     courseId: "1,005",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 5",
    //   },
    //   {
    //     courseId: "1,006",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 6",
    //   },
    //   {
    //     courseId: "1,007",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 7",
    //   },
    //   {
    //     courseId: "2,001",
    //     semesterName: "Spring 2022",
    //     courseName: "TestCourse 8"
    //   },
    //   {
    //     courseId: "2,002",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 9",
    //   },
    //   {
    //     courseId: "2,003",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 10",
    //   },
    //   {
    //     courseId: "2,004",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 11",
    //   },
    //   {
    //     courseId: "2,005",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 12",
    //   },
    //   {
    //     courseId: "2,006",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 13",
    //   },
    //   {
    //     courseId: "2,007",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 14",
    //   },
    //   {
    //     courseId: "2,008",
    //     semesterName: "Fall 2021",
    //     courseName: "TestCourse 15",
    //   },
    // ]);
    setOpenCourses(toBeRated);
  }, [toBeRated]);

  function updateCookie() {
    if (openCourses && !openCourses.length > 0) return;
    setOpen(!open);
  }

  useEffect(() => {
    if (open && reset && openCourses && openCourses.length > 0) {
      setReset(false);
    }
  }, [open, reset, openCourses]);

  function resetCourse(selectedCourse) {
    if (openCourses && !openCourses.length > 0) return;
    // remove the course from the array

    // console.log(openCourses, openCourses[openCourses.length - 1]);
    // let currentCourse = openCourses[openCourses.length - 1];
    setCourseId(selectedCourse.courseId);
    setCourseName(selectedCourse.courseName);
    setSemester(selectedCourse.semesterName);
    setExam(null);
    setLecture(null);
    // setProfessor(null);
    setMaterials(null);
    setWorkload(null);
    setTopic(null);
    setComment("");
    setReset(true);
  }

  const handleSubmit = () => {
    postRating(review);
    // console.log("### POSTED Rating: ", review)
    const newOpenCourses = openCourses.filter(
      (course) => course.courseId !== courseId
    );
    setOpenCourses(newOpenCourses);
    setCourseId("");
    if (newOpenCourses && newOpenCourses.length === 0) {
      setOpen(false);
    }
  };

  const selectAllText = (e) => {
    if (doubleClick) {
      e.target.select();
    }
    setDoubleClick(!doubleClick);
  };

  // set timer that sets doubleClick to false after 500ms
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDoubleClick(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [doubleClick]);

  const baseStyle = {
    count: 5,
    size: 24,
    // activeColor: "#007A2D", green
    activeColor: "#ffd700",
    isHalf: true,
  };

  useEffect(() => {
    if (hasNull(review.ratings) === false) {
      setSubmittable(true);
    } else {
      setSubmittable(false);
    }
  }, [review.ratings]);

  function hasNull(target) {
    for (let member in target) {
      if (target[member] == null) return true;
    }
    return false;
  }

  const tooltipTexts = {
    topic: "Were the topics covered what you expected from the course sheet?",
    lecture: "How well was the lecture structured?",
    materials:
      "How well did the provided course materials support your education?",
    // professor: "How well did the professor perform?",
    exam: "Was the exam fair?",
    workload: "Was the workload appropriate for the ECTS?",
  };

  // const cancelButtonRef = useRef(null);
  return (
    <>
      <button
        className="inline-flex items-center justify-center p-2 text-white rounded-md hover:bg-hsg-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:bg-hsg-800 relative"
        onClick={(e) => updateCookie()}
      >
        <ThumbUpIcon className="block w-6 h-6" aria-hidden="true" />
        {openCourses && openCourses.length > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/4 -translate-y-1/4 bg-red-600 rounded-full">
            {openCourses.length}
          </span>
        )}
      </button>
      {open ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            // initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity backdrop-filter backdrop-blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative px-4 pt-5 pb-4 text-center transition-all transform bg-white rounded-lg shadow-xl sm:my-8 w-1/2 overflow-hidden sm:p-6">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 p-1"
                        onClick={() => setOpen(false) & setCourseId("")}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {!reset && courseId ? (
                      <div>
                        <Dialog.Title
                          as="h2"
                          className="mb-4 text-xl font-bold text-gray-900"
                        >
                          <span className="font-medium">{courseName}</span>
                        </Dialog.Title>
                        <div className="mt-3 sm:mt-5">
                          <div className="grid grid-cols-2 gap-x-4 gap-y-4 font-semibold">
                            <div className="bg-gray-100 grid grid-cols-2 rounded-lg items-center">
                              <div
                                data-tip="tooltip"
                                data-for="topic"
                                className="text-lg"
                              >
                                Topic*
                              </div>
                              <ReactStars onChange={setTopic} {...baseStyle} />
                              <ReactTooltip id="topic" type="light">
                                <span>{tooltipTexts.topic}</span>
                              </ReactTooltip>
                            </div>
                            <div className="bg-gray-100 grid grid-cols-2  items-center">
                              <div
                                data-tip="tooltip"
                                data-for="Exam"
                                className="text-lg"
                              >
                                Exam*
                              </div>
                              <ReactStars onChange={setExam} {...baseStyle} />
                              <ReactTooltip id="Exam" type="light">
                                <span>{tooltipTexts.exam}</span>
                              </ReactTooltip>
                            </div>
                            <div className="bg-gray-100 grid grid-cols-2  items-center">
                              <div
                                data-tip="tooltip"
                                data-for="Lecture"
                                className="text-lg"
                              >
                                Lecture*
                              </div>
                              <ReactStars
                                onChange={setLecture}
                                {...baseStyle}
                              />
                              <ReactTooltip id="Lecture" type="light">
                                <span>{tooltipTexts.lecture}</span>
                              </ReactTooltip>
                            </div>
                            {/* <div className="bg-gray-100 grid grid-cols-2  items-center">
                              <div
                                data-tip="tooltip"
                                data-for="Professor"
                                className="text-lg"
                              >
                                Professor*
                              </div>
                              <ReactStars
                                onChange={setProfessor}
                                {...baseStyle}
                              />
                              <ReactTooltip id="Professor" type="light">
                                <span>{tooltipTexts.professor}</span>
                              </ReactTooltip>
                            </div> */}

                            <div className="bg-gray-100 grid grid-cols-2  items-center">
                              <div
                                data-tip="tooltip"
                                data-for="Materials"
                                className="text-lg"
                              >
                                Materials*
                              </div>
                              <ReactStars
                                onChange={setMaterials}
                                {...baseStyle}
                              />
                              <ReactTooltip id="Materials" type="light">
                                <span>{tooltipTexts.materials}</span>
                              </ReactTooltip>
                            </div>
                            <div className="bg-gray-100 grid grid-cols-2  items-center">
                              <div
                                data-tip="tooltip"
                                data-for="Workload"
                                className="text-lg"
                              >
                                Workload*
                              </div>
                              <ReactStars
                                onChange={setWorkload}
                                {...baseStyle}
                              />
                              <ReactTooltip id="Workload" type="light">
                                <span>{tooltipTexts.workload}</span>
                              </ReactTooltip>
                            </div>
                          </div>
                          <div className="pt-4 mb-4">
                            <TextareaAutosize
                              minRows="2"
                              style={{
                                width: "100%",
                                borderStyle: "none",
                                overflow: "auto",
                                borderColor: "transparent",
                                resize: "none",
                                outline: "none",
                                boxShadow: "none",
                                backgroundColor: "#f3f4f6",
                                borderRadius: "8px",
                                fontSize: "1.1rem",
                              }}
                              placeholder="Write a review"
                              onClick={selectAllText}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </div>
                          {!submittable ? (
                            <div
                              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300"
                              style={{
                                fontSize: "1.1rem",
                                fontWeight: "semibold",
                                paddingTop: "0.5rem",
                              }}
                            >
                              Rate all categories
                            </div>
                          ) : (
                            <button
                              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500"
                              onClick={handleSubmit}
                              hidden={false}
                              style={{
                                fontSize: "1.1rem",
                                fontWeight: "semibold",
                                paddingTop: "0.5rem",
                              }}
                            >
                              Submit Review
                            </button>
                          )}
                        </div>
                      </div>
                    ) : !reset && courseId === "" ? (
                      <div>
                        <h1
                          className="
                            text-xl font-bold text-gray-900
                        "
                        >
                          Please select a course to rate:
                        </h1>
                        {/* <div className="h-96 overflow-auto"> */}
                          {
                            Array.from(new Set(openCourses.map((course) => { return course.semesterName }))).map((semester) => (
                              <div className="">
                                <div key={semester} className="pt-4 text-lg font-medium">{semester}</div>
                                <div className="grid grid-cols-2">
                                  {
                                    openCourses.map((course) => (
                                      (course.semesterName === semester && <div
                                        className="rounded-md p-2 flex"
                                        key={course.courseId}
                                      >
                                        <button
                                          className="flex-1 items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500"
                                          onClick={() => {
                                            resetCourse(course);
                                          }}
                                        >
                                          <p className="line-clamp-2">
                                            {course.courseName}
                                          </p>
                                        </button>
                                      </div>)
                                    ))}
                                </div>
                              </div>
                            ))
                          }
                        {/* </div> */}

                      </div>
                    ) : null}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </>
  );
};
