import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { ArrowRightIcon } from "@heroicons/react/solid";

export const SignInButton = () => {
  // Msal Login instance
  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.debug(e);
    });
  };

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-hsg-600 hover:bg-hsg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsg-500"
        onClick={() => handleLogin()}
      >
        Login
        <ArrowRightIcon className="w-5 h-5 ml-3 -mr-1" aria-hidden="true" />
      </button>
    </div>
  );
};
