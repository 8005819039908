import React from "react";
import { FilterCloseButton } from "./FilterCloseButton";

// Container for filters
// State is toggled by filter button in bottomRow/eventListContainer/inputs/filter/FilterButton.jsx which sets the state on Biddit.jsx
export const FilterContainer = ({
  filterToggle,
  setFilterToggle,
  filterContainerFilterData,
}) => {
  return (
    <div
      className={`z-10 pb-8 pr-4 xl:pb-8 absolute w-full h-full transition duration-1000 ease-in-out ${
        filterToggle ? "transform -translate-x-full" : ""
      }`}
    >
      <div className="flex flex-col w-full h-full p-4 rounded-lg xl:rounded-r-lg bg-hsg-800">
        <div className="flex items-start justify-end flex-none text-hsg-100">
          <FilterCloseButton setFilterToggle={setFilterToggle} />
        </div>
        <div className="flex-1 h-full overflow-auto scrollbar-hide">
          {/* Generating titles and drop down for all filters */}
          {filterContainerFilterData.map((filter, i) => {
            return (
              <div className="py-1" key={i}>
                <label
                  htmlFor={filter.title}
                  className="hidden text-sm font-medium text-hsg-100 dark:text-white sm:block"
                >
                  {filter.title}
                </label>
                {/* Filter for big screens */}
                <select
                  id="type"
                  name="type"
                  className="hidden w-full py-1 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md sm:block focus:outline-none "
                  value={filter.value}
                  onChange={filter.handleOption}
                >
                  <option className="" key="" value="">
                    All
                  </option>

                  {filter.values.map((filterOption) => {
                    return (
                      <option key={filterOption} value={filterOption}>
                        {filterOption}
                      </option>
                    );
                  })}
                </select>

                {/* Filter for mobile */}
                <select
                  id="type"
                  name="type"
                  className="block w-full py-1 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md  sm:hidden focus:outline-none"
                  value={filter.value}
                  onChange={filter.handleOption}
                >
                  <option className="text-gray-200" value="" disabled selected>
                    {filter.title}
                  </option>
                  <option className="" key="" value="">
                    All
                  </option>

                  {filter.values.map((filterOption) => {
                    return (
                      <option key={filterOption} value={filterOption}>
                        {filterOption}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
