// Dependencies
import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";

// Components
import { SignInButton } from "./SignInButton";
import { CookieBanner } from "../Cookies/CookieBanner";
import shsg_logo from "../../assets//SHSG_Logo_Circle_100x100mm_RGB_green.png"

// In case you want to integrate a sign-out button, you can use the following:
// import { SignOutButton } from "./SignOutButton";

const LoginStatus = ({ cookieState, setCookieState }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div>
      {isAuthenticated ? (
        <div className="absolute z-50 pl-3 bottom-6">
          {/* <SignOutButton /> */}
        </div>
      ) : (
        <div className="w-screen h-screen backdrop-filter backdrop-blur-sm">
          <div className="absolute transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow w-7/8 top-1/2 lg:w-1/3 left-1/2 lg:top-1/3">
            <div className="p-4 sm:p-6 text-center">
              <img
                alt="SHSG Logo"
                src={shsg_logo}
                className="w-36 h-36 md:w-48 md:h-48 mx-auto mb-8"
              />
              <h3 className="text-lg font-medium leading-6 text-center text-gray-900">
                Welcome to Biddit by <a href="https://shsg.ch" target="_blank" rel="noreferrer" className="text-hsg-700">SHSG</a>.
              </h3>
              <div className="pt-1 text-xs text-gray-500">
                <p>For students, by students.</p>
              </div>
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="self-center justify-self-center"
                >
                  <SignInButton />
                </button>
              </div>
              <div className="mt-5 text-xs text-gray-500 ">
                Disclaimer: Only information provided directly by HSG is legally binding.
              </div>
              <a href="https://courses.unisg.ch" className="text-xs text-hsg-700">HSG Courses</a>
            </div>
          </div>
        </div>
      )}
      <CookieBanner cookieState={cookieState} setCookieState={setCookieState} />
    </div>
  );
};

export default LoginStatus;
