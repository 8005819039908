// Dependencies
import React from "react";

// Input field for search term
export const EventListSearchInput = ({ searchValue, setSearchValue }) => {
	const handleSearchInputChanges = (e) => {
		const cleanValue = e.target.value.replace(/[&/\\#+()$~%'"*?<>{}]/g, "");
		setSearchValue(cleanValue);
	};

	return (
		<input
			type="text"
			name="search"
			id="search"
			className="w-full h-full pl-10 bg-white rounded-lg cursor-text focus:outline-none focus:ring-hsg-800 focus:border-hsg-800"
			placeholder="Search"
			value={searchValue}
			onChange={handleSearchInputChanges}
		/>
	);
};
