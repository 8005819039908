import { atom, selector } from "recoil";
import { relevantCisIdsQuery } from "../../selectors/hsg/relevantCisIds";

export const selectedSemesterState = atom({
  key: "selectedSemester",
  default: selector({
    key: "selectedSemester/Default",
    get: async ({ get }) => {
      const terms = get(relevantCisIdsQuery);
      if (terms[1]) {
        return terms[1];
      } else {
        return terms[0];
      }
    },
  }),
});
