import { ErrorBoundary } from "react-error-boundary";
import { Main } from "../components/Main";
import { PagewideErrorFallback } from "./PagewideErrorFallback";

export function ShieldComponent({ setCookieState }) {
    return (
        <ErrorBoundary fallbackRender={PagewideErrorFallback}>
            <Main />
        </ErrorBoundary>
    );
}
