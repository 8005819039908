import React, { useState } from "react";
import { TutorialComponent } from "../../../utils/tutorial/TutorialComponent";
import Collapsible from "./FocusCollapsible";
import StarCollapsilbe from "./RatingFocusCollapsible";
import { UpDownSwitch } from "./UpDownSwitch";
import ReactTooltip from "react-tooltip";
import { ExternalLinkIcon } from "@heroicons/react/outline";

// Component for course details (Kursmerkblatt like)
export const FocusEvent = ({ focusEvent, toggle, isToggled }) => {
  // Tutorial
  const [open, setOpen] = useState(
    localStorage.getItem("focuseventtutorial") === "false" ? false : true
  );

  function updateCookie() {
    localStorage.setItem("focuseventtutorial", false);
    setOpen(false);
  }


  const tooltipTexts = {
    topic: "Were the topics covered in class what you expected from the course information sheet?",
    lecture: "How well was the lecture structured?",
    materials: "How well did the provided course materials support your education?",
    professor: "How well did the professor perform?",
    exam: "Was the exam fair?",
    workload: "Was the workload appropriate for the ECTS?",
  };

  return (
      <div className="relative h-full p-4 overflow-auto text-gray-800 bg-white rounded-lg shadow-sm lg:flex-col lg:flex lg:p-10 lg:mx-16 scrollbar-hide">
          {/* Tutorial */}
          {open ? (
              <div className="absolute z-10 w-full h-full -m-4 lg:-m-10">
                  <TutorialComponent
                      open={open}
                      setOpen={updateCookie}
                      text={
                          "All the details for the course. Click the name to go to the HSG Website of the course or the arrow to get back to the calendar."
                      }
                  />
              </div>
          ) : (
              <></>
          )}

          {/* Course tital and key info */}
          <header className="mb-1 font-bold lg:text-2xl">
              <div className="flex justify-between">
                  <a
                      href={focusEvent && focusEvent.timeTableLink}
                      target="_blank"
                      rel="noreferrer"
                  >
                      {focusEvent && focusEvent.shortName !== undefined
                          ? focusEvent.shortName
                          : "404 - Sorry, can't find any details.."}
                  </a>
                  <a
                      href={
                          focusEvent &&
                          "https://tools.unisg.ch/handlers/Public/CourseInformationSheet.ashx/semester/FS23/eventnumber/" +
                              focusEvent.courseNumber +
                              ".pdf"
                      }
                      target="_blank"
                      rel="noreferrer"
                  >
                      <ExternalLinkIcon className="h-6 w-6" />
                  </a>
              </div>
          </header>
          {focusEvent && (
              <div className="text-xs font-semibold text-gray-700 lg:text-base">
                  <div className="" label="credits">
                      {focusEvent.ects} ECTS | {focusEvent.classification} |{" "}
                      {focusEvent.courseLanguage && focusEvent.language}
                  </div>
                  <div className="mb-4">
                      {focusEvent.lecturers &&
                          focusEvent.lecturers
                              .map((prof) => {
                                  return prof.firstName + " " + prof.lastName;
                              })
                              .join(" • ")}
                  </div>
              </div>
          )}

          <section className="pb-2">
              <h2 className="text-lg font-bold text-gray-700 ">
                  Exam Information
              </h2>
              <div className="pb-1">
                  {focusEvent && focusEvent.achievementParts !== undefined ? (
                      focusEvent.achievementParts.map((part) => {
                          return (
                              <div>
                                  <div className="flex w-full text-base font-medium  ">
                                      <div className="flex-1 ">
                                          {part.achievementForm.shortName}
                                      </div>
                                      <div className="w-1/5 text-right">
                                          {`${
                                              part.achievementForm.isCentral
                                                  ? "Central "
                                                  : "Decentral "
                                          }`}
                                          ({part.weightage / 100}%)
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                  ) : (
                      <div className="text-base text-gray-700">
                          No Exam Information
                      </div>
                  )}
              </div>
          </section>

          {/* Course Ratings */}
          {focusEvent && "avgRatings" in focusEvent ? (
              <StarCollapsilbe
                  label={`${focusEvent.avgRating} · ${focusEvent.nbOfRatings} ${
                      focusEvent.nbOfRatings === 1 ? "Rating" : "Ratings"
                  }`}
                  CloseOnToggle={isToggled}
              >
                  <div className="grid grid-cols-5 gap-8 text-base">
                      <div className="col-span-2">
                          <dl className="">
                              {Object.keys(focusEvent.avgRatings).filter((category) => category !== 'professor').map(
                                  (category) => (
                                      <dt>
                                          <div className="items-center flex flex-row">
                                              <div
                                                  className="capitalize w-1/3 md:w-1/5 mr-1"
                                                  data-tip="tooltip"
                                                  data-for={category}
                                              >
                                                  {category}
                                              </div>
                                              <ReactTooltip
                                                  id={category}
                                                  type="light"
                                              >
                                                  <span>
                                                      {tooltipTexts[category]}
                                                  </span>
                                              </ReactTooltip>

                                              <div className="bg-gray-300 rounded-full h-3 dark:bg-gray-600 align-middle flex-1 ml-4">
                                                  <div
                                                      className="bg-gray-500 h-3 rounded-full dark:bg-gray-300"
                                                      style={{
                                                          width: `${
                                                              focusEvent
                                                                  .avgRatings[
                                                                  category
                                                              ] * 20
                                                          }%`,
                                                      }}
                                                  />
                                              </div>
                                              <div className="ml-4 w-1/6">
                                                  Ø{" "}
                                                  {
                                                      focusEvent.avgRatings[
                                                          category
                                                      ]
                                                  }
                                              </div>
                                          </div>
                                      </dt>
                                  )
                              )}
                          </dl>
                      </div>
                      {/* RATING COMMENTS */}
                      <div className="col-span-3 h-36 overflow-auto">
                  {focusEvent &&
                    "comments" in focusEvent &&
                    focusEvent.comments.length > 0 ? (
                    <dl className="space-y-2 ">
                      {focusEvent.comments.map((comment) => (
                        <div key={comment.text}>
                          <dt className="">
                            <h4 className="font-bold">{comment.semester}</h4>
                            <p className="">{comment.text}</p>
                          </dt>
                        </div>
                      ))}
                    </dl>
                  ) : (
                    <></>
                  )}
                </div>
                  </div>
              </StarCollapsilbe>
          ) : (
              <></>
          )}

          {/* Course details that can be shown by clicking on collapsible heading */}

          <div className="w-full border-gray-300 pb-2 border-t mt-4" />
          <section
              className="flex flex-col flex-1 overflow-hidden scrollbar-hide"
              label="Course Information"
          >
              <h2 className="flex-none pb-2 text-xl font-bold text-gray-700">
                  Course Information
              </h2>

              <div className="flex-1 overflow-auto text-gray-700 rounded-lg scrollbar-hide">
                  <Collapsible
                      label="Learning Objectives"
                      CloseOnToggle={isToggled}
                  >
                      <div
                          dangerouslySetInnerHTML={{
                              __html:
                                  focusEvent && focusEvent.learningObjectives,
                          }}
                      />
                  </Collapsible>
                  <Collapsible label="Content" CloseOnToggle={isToggled}>
                      <div
                          dangerouslySetInnerHTML={{
                              __html: focusEvent && focusEvent.courseContent,
                          }}
                      />
                  </Collapsible>
                  <Collapsible label="Prerequisites" CloseOnToggle={isToggled}>
                      <div
                          dangerouslySetInnerHTML={{
                              __html:
                                  focusEvent && focusEvent.coursePrerequisites,
                          }}
                      />
                  </Collapsible>

                  <Collapsible label="Structure" CloseOnToggle={isToggled}>
                      <div
                          dangerouslySetInnerHTML={{
                              __html: focusEvent && focusEvent.courseStructure,
                          }}
                      />
                  </Collapsible>
                  <Collapsible label="Literature" CloseOnToggle={isToggled}>
                      <div
                          dangerouslySetInnerHTML={{
                              __html: focusEvent && focusEvent.courseLiterature,
                          }}
                      />
                  </Collapsible>
                  <Collapsible
                      label="Additional Information"
                      CloseOnToggle={isToggled}
                  >
                      <div
                          dangerouslySetInnerHTML={{
                              __html:
                                  focusEvent &&
                                  focusEvent.courseAdditionalInformation,
                          }}
                      />
                  </Collapsible>
                  <Collapsible label="Exam Details" CloseOnToggle={isToggled}>
                      <div
                          dangerouslySetInnerHTML={{
                              __html:
                                  focusEvent && focusEvent.achievementContent,
                          }}
                      />
                  </Collapsible>
              </div>
          </section>
          {focusEvent && (
              <div className="absolute inset-x-0 bottom-0 flex justify-center w-full pb-2 bg-white rounded-lg">
                  <UpDownSwitch toggle={toggle} isToggled={isToggled} />
              </div>
          )}
      </div>
  );
};