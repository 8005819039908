import React from "react";
import { XIcon } from "@heroicons/react/solid";

// Close button for filter
export const FilterCloseButton = ({ setFilterToggle }) => {
  return (
    <div
      className="inline-flex items-center justify-center p-2 rounded-md text-hsg-100 hover:bg-hsg-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:bg-hsg-900"
      onClick={setFilterToggle}
    >
      <span className="sr-only">Open main menu</span>

      <XIcon className="block w-6 h-6" aria-hidden="true" />
    </div>
  );
};
