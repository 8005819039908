import { atom, selector } from "recoil";
import axios from "axios";

import { userStudyPlanMigrationState } from "./userStudyPlanMigration";

export const userStudyPlanState = atom({
    key: "userStudyPlan",
    default: selector({
        key: "userStudyPlan/Default",
        get: async ({ get }) => {
            const migrationState = await get(userStudyPlanMigrationState);
            if (migrationState && Array.isArray(migrationState)) {
                return migrationState[0];
            }
            console.error("Invalid migrationState", migrationState);
            return {}; // Default empty object if migrationState is invalid
        },
    }),
});

export async function AddCourseToStudyPlan(token, cisTermId, courseId) {
    try {
        const res = await axios.post(
            `https://api.shsg.ch/study-plans/${cisTermId}/${courseId}`,
            {},
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return res.data;
    } catch (err) {
        try {
            const res = await axios.post(
                `https://api.shsg.ch/study-plans/${cisTermId}/${courseId}`,
                {},
                {
                    headers: {
                        "X-ApplicationId":
                            "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return res.data;
        } catch (err) {
            return console.log(err);
        }
    }
}

export async function RemoveCourseFromStudyPlan(token, cisTermId, courseId) {
    try {
        const res = await axios.delete(
            `https://api.shsg.ch/study-plans/${cisTermId}/${courseId}`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return res.data;
    } catch (err) {
        try {
            const res = await axios.delete(
                `https://api.shsg.ch/study-plans/${cisTermId}/${courseId}`,
                {
                    headers: {
                        "X-ApplicationId":
                            "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return res.data;
        } catch (err) {
            return console.log(err);
        }
    }
}

export async function AddCoursesToStudyPlan(token, cisTermId, courseIds) {
    try {
        const res = await axios.post(
            `https://api.shsg.ch/study-plans/${cisTermId}`,
            {
                courseIds: courseIds,
            },
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return res.data;
    } catch (err) {
        try {
            const res = await axios.post(
                `https://api.shsg.ch/study-plans/${cisTermId}`,
                {
                    courseIds: courseIds,
                },
                {
                    headers: {
                        "X-ApplicationId":
                            "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return res.data;
        } catch (err) {
            return console.log(err);
        }
    }
}

export async function RemoveCoursesFromStudyPlan(token, semesterId) {
    try {
        const res = await axios.delete(
            `https://api.shsg.ch/study-plans/${semesterId}`,
            {
                headers: {
                    "X-ApplicationId": "820e077d-4c13-45b8-b092-4599d78d45ec",
                    "X-RequestedLanguage": "EN",
                    "API-Version": "1",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return res.data;
    } catch (err) {
        try {
            const res = await axios.delete(
                `https://api.shsg.ch/study-plans/${semesterId}`,
                {
                    headers: {
                        "X-ApplicationId":
                            "820e077d-4c13-45b8-b092-4599d78d45ec",
                        "X-RequestedLanguage": "EN",
                        "API-Version": "1",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return res.data;
        } catch (err) {
            return console.log(err);
        }
    }
}

export const customPlaceholderCourseState = atom({
    key: "customPlaceholderCourse",
    default: {
        courseName: "",
        classification: "",
        ects: 0,
        randId: Math.floor(Math.random() * 100000),
        grade: "",
    },
});

export const placeholderFormValidQuery = selector({
    key: "placeholderFormValid",
    get: ({ get }) => {
        const placeholderFormData = get(customPlaceholderCourseState);

        return (
            placeholderFormData.courseName !== "" &&
            placeholderFormData.classification !== "" &&
            placeholderFormData.ects > 0 &&
            !isNaN(parseFloat(placeholderFormData.grade)) &&
            1.0 <= placeholderFormData.grade <= 6.0 &&
            placeholderFormData.grade % 0.25 === 0
        );
    },
});