import { Filter } from "./filter";
import { Search } from "./search";
import { SelectSemester } from "../../selectSemester";
import React from "react";

export function Inputs() {
  return (
    <div className="min-h-1/3">
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-full h-8 mb-4 text-xs text-center text-gray-600 align-middle bg-gray-200 rounded animate-pulse">
            Loading your semesters..
          </div>
        }
      >
        <SelectSemester />
      </React.Suspense>
      <h3 className="mt-4 mb-2 text-lg font-medium leading-6 text-gray-900 ">
        Search & Filter
      </h3>
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-full h-24 mb-4 text-xs text-center text-gray-600 align-middle bg-gray-200 rounded animate-pulse">
            Loading the filters..
          </div>
        }
      >
        <Filter />
      </React.Suspense>
      <Search />
    </div>
  );
}
