import React from "react";
import { EventListSearchBar } from "./search/SearchBar";
import { EventListFilterButton } from "./filter/FilterButton";

// Component grouping search bar & filter button together
export const EventListInputs = ({
  searchValue,
  setSearchValue,
  setFilterToggle,
  filterActive,
}) => {
  return (
    <div className="flex flex-1 w-full">
      <div className="flex-grow">
        <EventListSearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </div>
      <EventListFilterButton
        setFilterToggle={setFilterToggle}
        filterActive={filterActive}
      />
    </div>
  );
};
