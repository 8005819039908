import { selector } from "recoil";
import { creditsFilterSelectionState } from "../../atoms/general/creditsFilterSelection";
import { lecturersFilterSelectionState } from "../../atoms/general/lecturersFilterSelection";
import { ratingsFilterSelectionState } from "../../atoms/general/ratingsFilterSelection";
import { coursesSelectedSemesterQuery } from "../../atoms/general/coursesSelectedSemester";
import { courseSearchInputState } from "../../atoms/general/courseSearchInput";
import { classificationsFilterSelectionState } from "../../atoms/general/classifiationsFilterSelection";

export const filteredCoursesQuery = selector({
  key: "filteredCourses",
  get: async ({ get }) => {
    const selelctedCourses = get(coursesSelectedSemesterQuery);
    const creditsFilterSelection = get(creditsFilterSelectionState);
    const classificationFilterSelection = get(
      classificationsFilterSelectionState
    );
    const lecturersFilterSelection = get(lecturersFilterSelectionState);
    const ratingsFilterSelection = get(ratingsFilterSelectionState);

    const filterLecturerHsgEntityIds = lecturersFilterSelection.map(
      (lecturer) => lecturer.value.hsgEntityId
    );

    const courseSearchInput = get(courseSearchInputState);
    const courseSearchRegex =
      courseSearchInput.length > 0 && new RegExp(`${courseSearchInput}`, "i");

    const filteredCourses = selelctedCourses.filter((course) => {
      const courseLecturerHsgEntityIds = course.lecturers.map(
        (lecturer) => lecturer.hsgEntityId
      );

      return (
        (creditsFilterSelection.length === 0 ||
          creditsFilterSelection
            .map((selection) => selection.value)
            .includes(course.credits)) &&
        (classificationFilterSelection === null ||
          !classificationFilterSelection.value ||
          classificationFilterSelection.value === course.classification) &&
        (lecturersFilterSelection.length === 0 ||
          courseLecturerHsgEntityIds.some((lecturer) =>
            filterLecturerHsgEntityIds.includes(lecturer)
          )) &&
        (ratingsFilterSelection == null ||
          !ratingsFilterSelection.value ||
          ratingsFilterSelection.value <= course.avgRating) &&
        (!courseSearchRegex || courseSearchRegex.test(course.shortName))
      );
    });

    return filteredCourses;
  },
});
