import Tooltip from "../util/Tooltip";

export const TitleElement = ({
  title = "",
  span = 1,
  children,
  childStyle = "",
  parentStyle = "",
  toolTip,
  onClick,
}) => {
  if (!toolTip) {
    return (
      <div
        className={`col-span-${span} ${parentStyle}`}
        onClick={onClick ? onClick : null}
      >
        {title}
        <div className={`${childStyle}`}>{children}</div>
      </div>
    );
  } else {
    return (
      <div className={`col-span-${span} ${parentStyle}`}>
        <Tooltip message={toolTip}>{title}</Tooltip>
        <div className={`${childStyle}`}>{children}</div>
      </div>
    );
  }
};
