import React from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { coursesNotMigratedState } from "../../newBiddit/recoil/atoms/shsg_studyplan/coursesNotMigrated";
import { migrationAlertModalState } from "../../newBiddit/recoil/atoms/shsg_studyplan/migrationAlertModal";

export const MigrationModal = () => {
    const [open, setOpen] = useRecoilState(migrationAlertModalState);
    const coursesNotMigrated = useRecoilValue(coursesNotMigratedState);

    const cancelButtonRef = useRef(null);
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full text-yellow-600 bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-6 h-6"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900"
                                            >
                                                Course Migration
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    We have migrated your
                                                    selected courses to the most
                                                    up to date course list.
                                                    <p className="mb-2">
                                                        Unfortunately, courses
                                                        for the following
                                                        semesters are not
                                                        available:
                                                    </p>
                                                    {Object.keys(
                                                        coursesNotMigrated
                                                    ).map((semester) => {
                                                        return (
                                                            <div key={semester}>
                                                                <div className="text-sm font-medium leading-6 text-gray-900">
                                                                    {semester}
                                                                </div>
                                                                {coursesNotMigrated[
                                                                    semester
                                                                ].map(
                                                                    (
                                                                        course
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    course
                                                                                }
                                                                                className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"
                                                                            >
                                                                                {
                                                                                    course
                                                                                }
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
