import React from "react";
import Collapsible from "../../../rightCol/focusEvent/FocusCollapsible";
import HowToBiddit from "./HowToBiddit";
import StarCollapsilbe from "../../../rightCol/focusEvent/RatingFocusCollapsible";


// Detailcourse information
export const MobileFocusEvent = ({ focusEvent, toggle, isToggled }) => {
  return (
    <div className="w-screen h-full p-2 overflow-auto scrollbar-hide">
      <div className="w-full h-full p-3 overflow-auto bg-white rounded-lg scrollbar-hide">
        {!focusEvent ? (
          <div className="w-full h-full">
            <HowToBiddit />
          </div>
        ) : (
          <div className="w-full h-full overflow-auto scrollbar-hide">
            <header className="w-full pb-1 text-lg font-semibold md:text-xl">
              <a
                href={focusEvent && focusEvent.timeTableLink}
                target="_blank"
                rel="noreferrer"
              >
                {focusEvent && focusEvent.shortName !== undefined
                  ? focusEvent.shortName
                  : "404 - Sorry, can't find any details.."}
              </a>
            </header>
            {focusEvent && (
              <div className="text-xs font-semibold text-gray-700 lg:text-base">
                <div
                  className=""
                  label="credits"
                >
                  {focusEvent.ects} ECTS | {focusEvent.classification} | {focusEvent.courseLanguage && focusEvent.language}
                </div>
                <div className="mb-4">
                  {focusEvent.lecturers && focusEvent.lecturers.map((prof) => {
                    return prof.firstName + " " + prof.lastName
                  }).join(" • ")}
                </div>
              </div>
            )}

            <section className="w-full text-xs font-medium text-gray-700 md:text-sm">
              <div className="w-full pb-1 text-gray-700">
                {focusEvent && focusEvent.achievementParts !== undefined ? (
                  focusEvent.achievementParts.map((part) => {
                    return (
                      <div>
                        <div className="flex w-full pb-1.5 text-xs md:text-sm">
                          <div className="flex-1 ">
                            {part.achievementForm.shortName}
                          </div>
                          <div className="grid grid-rows-2 text-right w-25">
                            <div>{`${part.achievementForm.isCentral
                              ? "Central"
                              : "Decentral"
                              }`}</div>
                            <div className="font-normal">
                              {part.weightage / 100}%
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-sm text-gray-700 md:text-base">
                    No Exam Information
                  </div>
                )}
              </div>
            </section>

            {/* Course Ratings */}
            {
              focusEvent && "avgRatings" in focusEvent ? (
                <StarCollapsilbe label={`${focusEvent.avgRating} · ${focusEvent.nbOfRatings} ${focusEvent.nbOfRatings === 1 ? 'Rating' : "Ratings"}`} CloseOnToggle={isToggled}>
                  <div className="">
                    <div className="grid grid-cols-2 gap-x-4 gap-y-0.5 pb-3">
                      {Object.keys(focusEvent.avgRatings).map((category) => (
                        <div className="grid grid-cols-3">
                          <div className="col-span-2 capitalize">{category}</div>
                          <div>Ø {focusEvent.avgRatings[category]}</div>
                        </div>
                      ))}
                    </div>
                    {focusEvent &&
                      "comments" in focusEvent &&
                      focusEvent.comments.length > 0 ? (
                      <div className="max-h-36 overflow-auto pb-2">
                        <dl className="space-y-2 ">
                          {focusEvent.comments.map((comment) => (
                            <div key={comment.text}>
                              <dt className="">
                                <h4 className="font-bold">{comment.semester}</h4>
                                <p className="">{comment.text}</p>
                              </dt>
                            </div>
                          ))}
                        </dl>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </StarCollapsilbe>

              ) : (
                <></>
              )
            }

            <div className="w-full border-t border-gray-300" />


            <section
              className="w-full pt-2 overflow-auto scrollbar-hide"
              label="Course Information"
            >
              <h2 className="w-full text-base font-medium md:text-lg">
                Course Information
              </h2>

              <div className="flex-1 w-full overflow-auto font-normal text-gray-700 rounded-lg scrollbar-hide">
                <Collapsible label="Content" CloseOnToggle={isToggled}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: focusEvent && focusEvent.courseContent,
                    }}
                  />
                </Collapsible>
                <Collapsible label="Prerequisites" CloseOnToggle={isToggled}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: focusEvent && focusEvent.coursePrerequisites,
                    }}
                  />
                </Collapsible>

                <Collapsible label="Structure" CloseOnToggle={isToggled}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: focusEvent && focusEvent.courseStructure,
                    }}
                  />
                </Collapsible>
                <Collapsible label="Literature" CloseOnToggle={isToggled}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: focusEvent && focusEvent.courseLiterature,
                    }}
                  />
                </Collapsible>
                <Collapsible
                  label="Additional Information"
                  CloseOnToggle={isToggled}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        focusEvent && focusEvent.courseAdditionalInformation,
                    }}
                  />
                </Collapsible>
                <Collapsible label="Exam Details" CloseOnToggle={isToggled}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: focusEvent && focusEvent.achievementContent,
                    }}
                  />
                </Collapsible>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};