import { selector } from "recoil";
import { cleanedCoursesQuery } from "./cleanedCourses";
import { localSelectedCoursesState } from "../../atoms/general/localSelectedCourses";
import moment from "moment/moment";

export const addedLocalSelectedCoursesQuery = selector({
    key: "addedLocalSelectedCourses",
    get: async ({ get }) => {
        const cleanedCourses = get(cleanedCoursesQuery);
        const localSelectedCourses = get(localSelectedCoursesState);

        let test = Object.keys(cleanedCourses).map((key) => {
            const cisTermId = key;

            const testSemester = cleanedCourses[key].map((course) => {
                // Tagging selected courses
                if (
                    Object.keys(localSelectedCourses).includes(cisTermId) &&
                    localSelectedCourses[cisTermId].includes(course.id)
                ) {
                    return { ...course, selected: true };
                } else {
                    return course;
                }
            });

            const updatedCourses = testSemester.map((courseA) => {
                let overlapping = false;

                if (courseA.selected || courseA.allocated) {
                    testSemester.forEach((courseB) => {
                        if (
                            courseA.id !== courseB.id &&
                            (courseB.selected || courseB.allocated)
                        ) {
                            courseA.calendarEntry.forEach((eventA) => {
                                // const startA = Date.parse(eventA.eventDate)
                                // const endA = new Date(startA + eventA.durationInMinutes * 60000)
                                const startA = moment(eventA.eventDate);
                                const endA = moment(startA).add(
                                    eventA.durationInMinutes,
                                    "minutes"
                                );
                                courseB.calendarEntry.forEach((eventB) => {
                                    // const startB = Date.parse(eventB.eventDate)
                                    // const endB = new Date(startB + eventB.durationInMinutes * 60000)
                                    const startB = moment(eventB.eventDate);
                                    const endB = moment(startB).add(
                                        eventB.durationInMinutes,
                                        "minutes"
                                    );
                                    if (startA <= endB && endA >= startB) {
                                        overlapping = true;
                                    }
                                });
                            });
                        }
                    });
                }
                return { ...courseA, overlapping: overlapping };
            });

            return { id: cisTermId, data: updatedCourses };
        });

        Object.keys(localSelectedCourses).forEach((key) => {
            localSelectedCourses[key].forEach((course) => {
                if (course.includes("PLACEHOLDER")) {
                    const [
                        courseName,
                        classification,
                        ects,
                        placeholderCourseNumber,
                    ] = course.split(";").slice(1);
                    test.forEach((semester) => {
                        if (semester.id === key) {
                            semester.data.unshift({
                                calendarEntry: [],
                                classification: classification,
                                achievementContent: "",
                                achievementLiterature: "",
                                achievementParts: [],
                                comment: null,
                                courseAdditionalInformation: null,
                                courseContent: "",
                                courseLanguage: {
                                    code: "EN",
                                    id: 2,
                                    shortName: "English",
                                },
                                courseLiterature: "",
                                coursePrerequisites: "",
                                courses: [
                                    {
                                        courseLanguage: {
                                            code: "EN",
                                            id: 2,
                                            shortName: "English",
                                        },
                                        courseNumber: placeholderCourseNumber,
                                        eventSkeletonKeyId: 7060,
                                        lecturers: [],
                                        timeTableLink: "",
                                        id: "",
                                        shortName: courseName,
                                    },
                                ],
                                courseStructure: "",
                                credits: ects * 100,
                                lastModification: "",
                                lastModifier: "",
                                learningObjectives: "",
                                majorVersion: 1,
                                minorVersion: 0,
                                publishedDate: "",
                                specialInformation: null,
                                achievementFormStatus: {
                                    isCentral: false,
                                    isDeCentral: true,
                                    isRegulated: false,
                                    description: "Free Decentral",
                                    id: "88d032b7-f64f-486b-8a31-30ba2f41fd76",
                                    shortName: "F/D",
                                },
                                changedFields: null,
                                courseNumber: placeholderCourseNumber,
                                courseResponsible: {
                                    displayName: "",
                                    email: "",
                                    firstName: "Toias",
                                    hsgEntityId: "",
                                    humanTypes: "",
                                    lastName: "",
                                    prefix: null,
                                    profileLink: "",
                                    type: {
                                        isRemovable: false,
                                        isSelectable: true,
                                        description: "",
                                        id: "",
                                        shortName: "Lecturer",
                                    },
                                    id: "3533c405-0054-4bdf-a0d9-f4de630b2e05",
                                },
                                hasPublishedVersion: true,
                                hsgEntityId:
                                    "d74991dd-8cff-4abd-9d96-cc46faba63b1",
                                isFulfilled: true,
                                isNewVersion: false,
                                isRejected: false,
                                publicationLanguageId:
                                    "62200638-7edd-46cb-819f-f9bc1f6646ec",
                                semesterId: key,
                                studyItemId: 6034,
                                workflowState: 5,
                                description: null,
                                id: course,
                                shortName: courseName,
                                eventSkeletonKeyId: 7060,
                                lecturers: [],
                                timeTableLink:
                                    "",
                                selected: true,
                                allocated: false,
                                overlapping: false,
                            });
                        }
                    });
                }
            });
        });

        return test;
    },
});
