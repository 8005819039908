// Dependencies
import React from "react";

// Components
import MobileTop from "./mobileTop/MobileTop";
import MobileBottom from "./mobileBottom/MobileBottom";

// High level component for top and bottom row
const MobileView = ({
	mobileCalendarState,
	setMobileCalendarState,
	ToggleDetails,
	selectedEventDates,
	SelectEvent,
	selectedEvents,
	searchValue,
	setSearchValue,
	filterToggle,
	setFilterToggle,
	filterActive,
	filterContainerFilterData,
	filteredEvents,
	toggle,
	isToggled,
	focusEvent,
	accessToken
}) => {
	return (
		<div className="grid w-screen h-screen grid-rows-5">
			<div className="flex w-screen h-full row-span-3 py-2 ">
				<MobileTop
					mobileCalendarState={mobileCalendarState}
					setMobileCalendarState={setMobileCalendarState}
					selectedEventDates={selectedEventDates}
					SelectEvent={SelectEvent}
					ToggleDetails={ToggleDetails}
					selectedEvents={selectedEvents}
					toggle={toggle}
					isToggled={isToggled}
					focusEvent={focusEvent}
					setFilterToggle={setFilterToggle}
					filterContainerFilterData={filterContainerFilterData}
					filterToggle={filterToggle}
				/>
			</div>

			<div className="row-span-2">
				<div className="w-screen h-full">
					<MobileBottom
						filteredEvents={filteredEvents}
						SelectEvent={SelectEvent}
						ToggleDetails={ToggleDetails}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						setFilterToggle={setFilterToggle}
						filterActive={filterActive}
						accessToken={accessToken}
					/>
				</div>
			</div>
		</div>
	);
};

export default MobileView;
