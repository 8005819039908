import { atom, selector } from "recoil";
import { coursesSelectedSemesterQuery } from "./coursesSelectedSemester";

export const lecturersFilterOptionsState = atom({
  key: "lecturersFilterOptions",
  default: selector({
    key: "lecturersFilterOptions/Default",
    get: async ({ get }) => {
      const courses = get(coursesSelectedSemesterQuery);

      const lecturersFilterOptions = [...new Set(
        courses
          .map((course) => {
            return course.lecturers.map((lecturer) => {
              return lecturer;
            });
          })
          .flat()
          .map(JSON.stringify)
      ),
      ]
        .map(JSON.parse)
        .sort()
        .map((lecturer) => {
          return {
            label: lecturer.firstName + " " + lecturer.lastName,
            value: lecturer,
          };
        });

      return lecturersFilterOptions;
    },
  }),
});
