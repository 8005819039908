export const studienOrdnungAssessmentInfo = {
    creditsDe: null,
    creditsEn: null,
    creditsFulfilledDe: null,
    creditsFulfilledEn: null,
    isProcessing: false,
    items: [
        {
            items: [
                {
                    items: [
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Grundlagen der Informatik",
                                    id: 3461,
                                    shortName: "Grundlagen der Informatik",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Einführung in die Programmierung",
                                    id: 3461,
                                    shortName: "Einführung in die Programmierung",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "3.00",
                                    minCredits: "3.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Computersysteme",
                                    id: 3461,
                                    shortName: "Computersysteme",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Analysis",
                                    id: 3461,
                                    shortName: "Analysis",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Lineare Algebra",
                                    id: 3461,
                                    shortName: "Lineare Algebra",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Diskrete Mathematik",
                                    id: 3461,
                                    shortName: "Diskrete Mathematik",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Methoden der Programmierung",
                                    id: 3461,
                                    shortName: "Methoden der Programmierung",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "8.00",
                                    minCredits: "8.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Algorithmen & Datenstrukturen",
                                    id: 3461,
                                    shortName: "Algorithmen & Datenstrukturen",
                                },
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "6.00",
                                    minCredits: "6.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Rechnernetze",
                                    id: 3461,
                                    shortName: "Rechnernetze",
                                }
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.3458.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "53.00",
                            minCredits: "53.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Pflichtbereich",
                            id: 3458,
                            shortName: "Pflichtbereich",
                        },
                        {
                            items: [
                                {
                                    items: [],
                                    achievementLanguage: null,
                                    gradeText: "",
                                    hierarchy: ".3451.3460.3461.",
                                    isTitle: true,
                                    mark: null,
                                    maxCredits: "7.00",
                                    minCredits: "7.00",
                                    mncp: "0.00",
                                    scoreCardMask: 258,
                                    semester: "",
                                    studyPlanMainFocus: null,
                                    sumOfCredits: null,
                                    description: "Wirtschafts- und Rechtswissenschaften für Informatikstudierende",
                                    id: 3461,
                                    shortName: "Wirtschafts- und Rechtswissenschaften für Informatikstudierende",
                                }
                            ],
                            achievementLanguage: null,
                            gradeText: "",
                            hierarchy: ".3451.3452.3457.",
                            isTitle: true,
                            mark: null,
                            maxCredits: "7.00",
                            minCredits: "7.00",
                            mncp: "0.00",
                            scoreCardMask: 258,
                            semester: "",
                            studyPlanMainFocus: null,
                            sumOfCredits: null,
                            description: "Pflichtwahlbereich",
                            id: 3457,
                            shortName: "Pflichtwahlbereich",
                        },
                    ],
                    achievementLanguage: null,
                    gradeText: "",
                    hierarchy: ".3451.3452.",
                    isTitle: true,
                    mark: null,
                    maxCredits: "60.00",
                    minCredits: "60.00",
                    mncp: "0.00",
                    scoreCardMask: 770,
                    semester: "",
                    studyPlanMainFocus: null,
                    sumOfCredits: null,
                    description: "Fachstudium",
                    id: 3452,
                    shortName: "Fachstudium",
                },
            ],
            achievementLanguage: null,
            gradeText: "",
            hierarchy: ".3451.",
            isTitle: true,
            mark: null,
            maxCredits: "60.00",
            minCredits: "60.00",
            mncp: "0.00",
            scoreCardMask: 1,
            semester: "",
            studyPlanMainFocus: null,
            sumOfCredits: null,
            description: "Assessment Year in Computer Science",
            id: 3451,
            shortName: "Assessment Year in Computer Science",
        },
    ],
    minCreditsDe: null,
    minCreditsEn: null,
    scoreCardLinks: [],
    showMncp: true,
    id: 0,
};