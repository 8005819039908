import { XIcon } from "@heroicons/react/outline";
import React from "react";

export const CookieBanner = ({ cookieState, setCookieState }) => {
  return (
    <div
      className={`fixed bottom-0 w-full flex pb-2 sm:pb-5 z-50 ${
        !cookieState ? "hidden" : "block"
      }`}
    >
      <div className="p-2 bg-white rounded-lg shadow-lg sm:p-3 mx-auto text-xs">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center flex-1">
            <p className="ml-3 font-medium text-gray-500 mr-4">
              <span className="">
                We use cookies for the HSG login, Google Analytics, and HotJar.
              </span>
            </p>
            <a
              href="https://shsg.ch/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-hsg-600 hover:text-hsg-500 mr-4"
            >
              SHSG Privacy Notice<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
          <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-2">
            <button
              type="button"
              className="flex -mr-1 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => setCookieState(false)}
            >
              <XIcon className="w-6 h-6 text-gray-500" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
