// Dependencies
import React from "react";

import ReactTooltip from "react-tooltip";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
// Other
import "./calendar.css";
import "./biddingRounds.json";
import { useRecoilValue } from "recoil";
import { calendarEventsQuery } from "../../../recoil/selectors/general/calendarEvents";

// Implementation of calendar widget
export default function Calendar() {
  const fixedEvents = require("./biddingRounds.json").flat();
  const events = useRecoilValue(calendarEventsQuery);

  const finalEvents = [...events, ...fixedEvents];

  // Information on hovering
  const hoverEvent = (info) => {
    let text2 = info.event.title;
    info.el.setAttribute("data-tip", `${text2}`);
    ReactTooltip.rebuild();
  };

  // Text to be displayed when hovering
  function renderEventContent(eventInfo) {
    return (
      <>
        <p className="truncate">{eventInfo.timeText}</p>
        <p className="font-bold truncate">{eventInfo.event.title}</p>
        <p className="truncate text-red">
          {eventInfo.event._def.extendedProps.room}
        </p>
      </>
    );
  }

  const calendarRef = React.useRef();
  const WeekChange = (value) => {
    let calendarApi = calendarRef.current.getApi();
    value === "next" ? calendarApi.next() : calendarApi.prev();
  };

  // UNCOMMENT FOR CUSTOM BUTTON
  const Today = (value) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  };

  var cal = {
    firstDay: "1",
    dayHeaderFormat: {
      weekday: "short",
      day: "numeric",
      month: "short",
    },
    eventColor: "#006625",
  };

  return (
    <div className="h-full overflow-hidden select-none scrollbar-hide">
      <ReactTooltip />
      <div className="flex w-full h-full">
        <div className="flex flex-col items-center justify-center h-full ease-in-out focus-within mt-7">
          <div className="absolute p-2 text-gray-500 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300 top-20">
            <div onClick={Today}>Today</div>
          </div>
          <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300">
            <ChevronLeftIcon
              aria-hidden="true"
              className="w-10 h-full text-gray-500 align-middle "
              onClick={() => WeekChange("prev")}
            />
          </div>
        </div>

        {/* calendar */}
        <div className="relative flex-1">
          <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin, dayGridPlugin]}
            initialView="timeGridWeek"
            height="100%"
            events={finalEvents}
            firstDay={cal.firstDay}
            slotMinTime="08:00:00"
            slotMaxTime="22:00:00"
            hiddenDays="[0]"
            eventColor="#006625"
            expandRows={true}
            slotEventOverlap={false}
            // eventClick={clickEvent}
            eventContent={renderEventContent}
            eventMouseEnter={hoverEvent}
            allDaySlot={false}
            headerToolbar={false}
            footerToolbar={false}
            slotLabelFormat={cal.eventTimeFormat}
            slotLabelInterval={cal.slotLabelInterval}
            dayHeaderFormat={cal.dayHeaderFormat}
          />
        </div>
        <div className="flex items-center mt-7">
          <div className="p-2 rounded-lg cursor-pointer hover:bg-gray-200 active:bg-gray-300">
            <ChevronRightIcon
              aria-hidden="true"
              className="w-10 text-gray-500 align-middle "
              onClick={() => WeekChange("next")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
