import { selector } from "recoil";
import { coursesSelectedSemesterQuery } from "../../atoms/general/coursesSelectedSemester";
import moment from "moment/moment";

export const calendarEventsQuery = selector({
    key: 'calendarEvents',
    get: async ({ get }) => {
        const courses = get(coursesSelectedSemesterQuery)

        const filteredCourses = courses.filter(course => { return (course.selected || course.allocated) })

        const calendarEvents = filteredCourses.map(course => {
            const courseEvents = course.calendarEntry.map(calendarEntry => {
                // const startDate = new Date(Date.parse(calendarEntry.eventDate))
                // const endDate = new Date(startDate + calendarEntry.durationInMinutes * 60000)
                const startDate = moment(calendarEntry.eventDate)
                const endDate = moment(startDate).add(calendarEntry.durationInMinutes, 'minutes')
                const object = {
                    ...calendarEntry,
                    // courseId: "8fdbdfba-00b7-488f-a2b2-a836150a5011",
                    title: course.shortName,
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    selected: true,
                    color: course.allocated ? "rgb(156 163 175)" : course.overlapping ? "rgba(252, 163, 17, 1)" : "rgba(0,102,37, 1)",
                }

                return object
            })

            return courseEvents
        }).flat()

        return calendarEvents
    }
})


// {
//     courseNumber: "4,826,1.01",
//     durationInMinutes: 225,
//     eventDate: "2023-04-18T16:15:00",
//     room: "23-103",
//     weekDay: "Di.",
//     id: 13387208,
//     courseId: "8fdbdfba-00b7-488f-a2b2-a836150a5011",
//     title: "Französisch B2, Gruppe 1",
//     start: "2023-04-18T16:15:00",
//     end: "2023-04-18T20:00:00",
//     selected: true,
//     color: "rgba(0,102,37, 1)",
//   },